import React from 'react'
import ReactDOM from 'react-dom';
import moment from 'moment'
class BookingDateRangePicker extends React.Component {
  componentDidMount() {
    let ranges = {
        'Last 7 Days': [moment().subtract(6, 'days'), new Date()],
        'Last 30 Days': [moment().subtract(29, 'days'), new Date()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],        
        'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],        
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],        
        'This Year-to-Date': [moment().startOf('year'), moment()]
    }
    ranges[moment().subtract(1, 'years').format('YYYY')] = [moment().subtract(1, 'years').startOf('year'), moment().subtract(1, 'years').endOf('year')]
    ranges[moment().format('YYYY')] = [moment().startOf('year'), moment().endOf('year')]
    ranges[moment().add(1, 'years').format('YYYY')] = [moment().add(1, 'years').startOf('year'), moment().add(1, 'years').endOf('year')]
    ranges[moment().add(2, 'years').format('YYYY')] = [moment().add(2, 'years').startOf('year'), moment().add(2, 'years').endOf('year')]
    ranges[moment().add(3, 'years').format('YYYY')] = [moment().add(3, 'years').startOf('year'), moment().add(3, 'years').endOf('year')]
    const dateRangePickerOptions = {
      clearClass: 'btn btn-default btn-sm',
      showDropdowns: true,
      ranges: ranges
    }
    const dateRangePickerCallback = function(start, end) {
      ReactDOM.findDOMNode(this.refs.datePickerText).innerHTML = start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY')
      this.props.onDateRangeChanged(start, end)
    }.bind(this)
    $(ReactDOM.findDOMNode(this)).daterangepicker(dateRangePickerOptions, dateRangePickerCallback)
  }

  render() {
    const buttonClass = 'btn btn-success btn-sm btn-responsive btn-block date-range'
    return (
      <div>
        <label className="small">EVENT DATE</label><br/>
        <div className="btn-group btn-group-justified">
          <a className={buttonClass}>
            <i className="fa fa-calendar"></i>&nbsp;&nbsp;<span ref="datePickerText">{moment(this.props.startDate).format('MM/DD/YYYY') + ' - ' + moment(this.props.endDate).format('MM/DD/YYYY')}</span> <b className="caret"></b>
          </a>
        </div>
      </div>
    )
  }
}

export default BookingDateRangePicker