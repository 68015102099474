(function() {
  // handle link methods
  $(document).on('click', '#document_link_method_default', function() {
    $('#domain-and-slug').hide();
    $('#default-url').show();
    return $('#custom-url').hide();
  });

  $(document).on('click', '#document_link_method_slug', function() {
    $('#domain-and-slug').show();
    $('#default-url').hide();
    $('#custom-url').show();
    $('#domain').html($('#document_root_domain').val());
    return $('#slug').html($('#document_slug').val());
  });

  $(document).on('change', '#document_root_domain', function() {
    return $('#domain').html($(this).val());
  });

  $(document).on('input propertychange', '#document_slug', function() {
    var okString;
    okString = $(this).val().replace(/[^A-Za-z0-9]+/g, '-');
    $(this).val(okString);
    return $('#slug').html(okString);
  });

}).call(this);
