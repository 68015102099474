(function() {
  $(document).on('keyup', 'body.rooms_budgets .occupied_rooms, body.rooms_budgets .adr, body.nest-rooms_budgets .occupied_rooms, body.nest-rooms_budgets .adr', function() {
    var adr, revenue, rooms, row;
    row = $(this).parents('tr');
    rooms = parseFloat(row.find('.occupied_rooms').val());
    adr = parseFloat(row.find('.adr').val());
    revenue = rooms * adr;
    if ($.isNumeric(revenue)) {
      return row.find('.revenue').val(revenue);
    } else {
      return row.find('.revenue').val(0.00);
    }
  });

}).call(this);
