(function() {
  $(document).on('click', 'body.documents.business_insight_report input[name=templates]', function() {
    var template_ids;
    template_ids = $('input[name=templates]:checked').map(function(_, el) {
      return $(el).val();
    }).get();
    window.pleaseWait();
    return Turbo.visit(`/documents/business_insight_report?templates=${template_ids}`);
  });

  document.addEventListener('turbo:load', function() {
    if ($('body').hasClass('business_insight_report')) {
      return window.handleRowWithSidebarHeight();
    }
  });

}).call(this);
