(function() {
  $(document).on('change', 'body.nest-menu_items #copy_menu_from_client', function() {
    var $this, prompt, selectedText, selectedVal;
    $this = $(this);
    selectedVal = $this.val();
    selectedText = $this.children("option").filter(":selected").text();
    prompt = "Are you sure you want to copy all menu categories and menu items from " + selectedText + "? There is no undo.";
    if (window.confirm(prompt)) {
      $.ajax({
        url: `/nest/clients/${clientSlug}/menu_items/copy_from`,
        data: {
          other: selectedVal
        },
        method: 'POST'
      }).done(function(response) {
        return window.location.reload();
      });
    }
    return $this.val("");
  });

  document.addEventListener('turbo:load', function() {
    return $('#menu_item_photo_id').imagepicker();
  });

}).call(this);
