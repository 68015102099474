(function() {
  document.addEventListener('turbo:load', function() {
    var $body;
    $body = $('body');
    if ($body.hasClass('reporting-sales grc')) {
      $('#report_first_row').on('change', function() {
        var removeOption;
        removeOption = $('#report_first_row option:selected').text();
        return $('#report_second_row').find('option[value=' + removeOption + ']').remove();
      });
      // select format option enables submit
      $('[name="format"]').on('click', function(e) {
        return $('input[type="submit"]').attr('disabled', false);
      });
      if ($body.find('property-nav-tabs').length > 0) {
        $('.property-tab-pane.active .well.group').each(function(index, el) {
          var height;
          height = $(el).height();
          return $(el).find('.row.dividers > div').height(height);
        });
        return $('.property-nav-tab a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
          // e.target // newly activated tab
          // e.relatedTarget // previous active tab
          return $('.property-tab-pane.active .well.group').each(function(index, el) {
            var height;
            height = $(el).height();
            return $(el).find('.row.dividers > div').height(height);
          });
        });
      } else {
        $('.client-tab-pane.active .well.group').each(function(index, el) {
          var height;
          height = $(el).height();
          return $(el).find('.row.dividers > div').height(height);
        });
        return $('.client-nav-tab a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
          // e.target // newly activated tab
          // e.relatedTarget // previous active tab
          return $('.client-tab-pane.active .well.group').each(function(index, el) {
            var height;
            height = $(el).height();
            return $(el).find('.row.dividers > div').height(height);
          });
        });
      }
    }
  });

}).call(this);
