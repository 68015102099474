(function() {
  document.addEventListener('turbo:load', function() {
    var $body;
    $body = $('body');
    if ($body.hasClass('nest-ticket_messages')) {
      return $('table.table-hover tr').on('click', function(event) {
        return window.top.location.href = $(this).data('href');
      });
    }
  });

}).call(this);
