import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['sendSertifiButton', 'esignatureSelected', 'signersLevel1', 'signersLevel2', 'signersLevel3', 'signersCC']
  static values = {
    volumeContractId: Number,
    accountId: Number
  }

  async sendSertifi(event) {
    event.target.disabled = true
    event.preventDefault()
    
    // the target to send to Sertifi is either a collection of booking documents
    const bookingId = window.bookingApp.bookingId
    const bookingDocumentIds = this.esignatureSelectedTargets.map((elem) => {
      if (elem.checked) { 
        return elem.dataset.bookingDocument
      }
    }).filter(id => id)

    // OR a volume contract
    const volumeContractId = this.volumeContractIdValue

    const signersLevel1HasContent = this.signersLevel1Target.value.length > 0
    const signersLevel2HasContent = this.signersLevel2Target.value.length > 0
    const signersLevel3HasContent = this.signersLevel3Target.value.length > 0
    const signersCCHasContent = this.signersCCTarget.value.length > 0

    if (!volumeContractId && bookingDocumentIds.length == 0) {
      alert('Please select at least one document to send for signature.')
      this.sendSertifiButtonTarget.disabled = false
    } else if (!signersLevel1HasContent) {
      alert('First signers are required for e-signature; please complete and try sending again.')
      this.sendSertifiButtonTarget.disabled = false
    } else if (signersLevel3HasContent && !signersLevel2HasContent) {
      alert('Complete second signers before third signers; please correct and try sending again.')
      this.sendSertifiButtonTarget.disabled = false
    } else if (!this.allEmailsValid()) {
      alert('Ensure that all email addresses are in a valid format and that emails are separated by commas only.')
      this.sendSertifiButtonTarget.disabled = false
    } else {
      // all good
      let confirmMessage = `Ready to send to Sertifi.\r\n\r\n`
      confirmMessage += `First Signer(s):\r\n${this.signersLevel1Target.value.split(',').join('\r\n')}`
      if (signersLevel2HasContent) {
        confirmMessage += `\r\n\r\nSecond Signer(s):\r\n${this.signersLevel2Target.value.split(',').join('\r\n')}`
      }
      if (signersLevel3HasContent) {
        confirmMessage += `\r\n\r\nThird Signer(s):\r\n${this.signersLevel3Target.value.split(',').join('\r\n')}`
      }
      if (signersCCHasContent) {
        confirmMessage += `\r\n\r\nCC:\r\n${this.signersCCTarget.value.split(',').join('\r\n')}`
      }
      if (confirm(confirmMessage)) {
        window.pleaseWait()
        const body = {
          volume_contract_id: this.volumeContractIdValue,
          booking_document_ids: bookingDocumentIds,
          booking_id: bookingId,
          level_1_signers: this.signersLevel1Target.value,
          level_2_signers: this.signersLevel2Target.value,
          level_3_signers: this.signersLevel3Target.value,
          cc_signers: this.signersCCTarget.value
        }
        await fetch('/sertifi_folders', { 
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams(body).toString()
        })

        if (this.volumeContractIdValue) {
          Turbo.visit(`/accounts/${this.accountIdValue}?flash=sertifi_sent`)
        } else {
          Turbo.visit(`/bookings/${bookingId}/edit/documents?flash=sertifi_sent`)
        }
      } else {
        this.sendSertifiButtonTarget.disabled = false
      }
    }
  }

  allEmailsValid() {
    return (this.validateEmailString(this.signersLevel1Target.value) &&
            this.validateEmailString(this.signersLevel2Target.value) &&
            this.validateEmailString(this.signersLevel3Target.value) &&
            this.validateEmailString(this.signersCCTarget.value))
  }

  validateEmailString(stringToValidate) {
    if (stringToValidate.length == 0) { 
      return true
    }
    const validEmailRegex = /(.+)@(.+)\.(.{2,})/
    return stringToValidate.split(',').every(emailString => emailString.match(validEmailRegex))
  }

  prefillEmail(event) {
    const email = event.target.dataset.email
    const targetName = event.target.dataset.targetName
    const existingValue = this[`${targetName}Target`].value.trim()
    if (existingValue == '') {
      this[`${targetName}Target`].value = email
    } else {
      let addresses = existingValue.split(',')
      addresses = addresses.map((address) => address.trim())
      addresses.push(email)
      this[`${targetName}Target`].value = addresses.join(',')
    }
  }

  clearEmail(event) {
    const targetName = event.target.dataset.targetName
    this[`${targetName}Target`].value = ''
  }
}