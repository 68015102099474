import Rails from '@rails/ujs';

document.addEventListener('turbo:load', function() {
  var $body;
  return $body = $('body');
});

$(document).on('click', 'body.activities.new .quick-action', function(event) {
  var $textarea, cursorPos, textAfter, textBefore, v;
  event.preventDefault();
  $textarea = $('#activity_notes');
  cursorPos = $textarea.prop('selectionStart');
  v = $textarea.val();
  textBefore = v.substring(0, cursorPos);
  textAfter = v.substring(cursorPos, v.length);
  return $textarea.val(textBefore + $(this).html() + textAfter);
});

$(document).on('change', '.todo-complete', function(event) {
  event.preventDefault();
  return Rails.fire(event.target.closest('form.edit_activity'), 'submit');
});
