import React from 'react'
import BookingsNavbar from './bookings/BookingsNavbar'
import BookingsSidebar from './bookings/BookingsSidebar'
import BookingTable from './bookings/BookingTable'
import moment from 'moment'

class Bookings extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
      ownership: props.initialOwnership,
      users: props.initialUsers,
      properties: props.initialProperties,
      sortBy: 'updated_at',
      order: 'desc',
      searchText: props.initialSearchText,
      statuses: props.initialStatuses,
      allStatuses: props.allStatuses,
      allUsers: props.allUsers,
      allProperties: props.allProperties,
      bookings: [],
      multiprop: props.multiprop,
      pmsIntegrated: props.pmsIntegrated,
      showDeletedUsers: props.showDeletedUsers
    }

    this.onNameClicked = this.onNameClicked.bind(this)
    this.onDatesClicked = this.onDatesClicked.bind(this)
    this.onOwnershipChanged = this.onOwnershipChanged.bind(this)
    this.onPropertySelected = this.onPropertySelected.bind(this)
    this.onStatusSelected = this.onStatusSelected.bind(this)
    this.onUserSelected = this.onUserSelected.bind(this)
    this.onLastUpdatedClicked = this.onLastUpdatedClicked.bind(this)
    this.onSearchFormSubmitted = this.onSearchFormSubmitted.bind(this)
    this.onDateRangeChanged = this.onDateRangeChanged.bind(this)
    this.onSelectSalesManagerAll = this.onSelectSalesManagerAll.bind(this)
    this.onSelectSalesManagerMe = this.onSelectSalesManagerMe.bind(this)
    this.onSelectSalesManagerActive = this.onSelectSalesManagerActive.bind(this)
    this.toggleShowDeletedUsers = this.toggleShowDeletedUsers.bind(this)
    this.fetchBookingData = this.fetchBookingData.bind(this)
  }

  componentDidMount() {
    window.handleRowWithSidebarHeight()
    this.fetchBookingData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.startDate !== this.state.startDate ||
        prevState.endDate !== this.state.endDate ||
        prevState.ownership !== this.state.ownership ||
        prevState.users !== this.state.users ||
        prevState.sortBy !== this.state.sortBy ||
        prevState.order !== this.state.order ||
        prevState.searchText !== this.state.searchText ||
        prevState.properties !== this.state.properties ||
        prevState.statuses !== this.state.statuses) {
      this.fetchBookingData()
    }
  }

  onOwnershipChanged(owner) {
    this.setState({ownership: owner})
  }

  onNameClicked() {
    const order = this.state.order == 'asc' ? 'desc' : 'asc'
    this.setState({sortBy: 'name', order: order})
  }

  onDatesClicked() {
    const order = this.state.order == 'asc' ? 'desc' : 'asc'
    this.setState({sortBy: 'start_date', order: order})
  }

  onLastUpdatedClicked() {
    const order = this.state.order == 'asc' ? 'desc' : 'asc'
    this.setState({sortBy: 'updated_at', order: order})
  }

  onSearchFormSubmitted(query) {
    this.setState({searchText: query})
  }

  onDateRangeChanged(start, end) {
    this.setState({startDate: start, endDate: end})
  }

  onPropertySelected(event) {
    const val = parseInt(event.target.value)
    let newProperties = this.state.properties.slice()
    if (event.target.checked) {
      newProperties.push(val)
    } else {
      newProperties.splice($.inArray(val, newProperties), 1)
    }
    this.setState({properties: newProperties})
  }

  onStatusSelected(event) {
    const val = event.target.value
    let newStatuses = this.state.statuses.slice()
    if (event.target.checked) {
      newStatuses.push(val)
    } else {
      newStatuses.splice($.inArray(val, newStatuses), 1)
    }
    this.setState({statuses: newStatuses})
  }

  onUserSelected(event) {
    const val = parseInt(event.target.value)
    let newUsers = this.state.users.slice()
    if (event.target.checked) {
      newUsers.push(val)
    } else {
      newUsers.splice($.inArray(val, newUsers), 1)
    }
    this.setState({users: newUsers})
  }

  onSelectSalesManagerAll() {
    let newSalesManagers = this.state.allUsers.slice();
    this.setState({ users: newSalesManagers.map((user) => user.id) });
  }

  onSelectSalesManagerMe() {
    this.setState({ users: [this.props.currentUser] });
  }

  onSelectSalesManagerActive() {
    let newSalesManagers = this.state.allUsers.filter((user) => !user.deleted);
    this.setState({ users: newSalesManagers.map((user) => user.id) });
  }

  toggleShowDeletedUsers() {
    this.setState({ showDeletedUsers: !this.state.showDeletedUsers });
  }

  fetchBookingData() {
    $.ajax({
      url: '/bookings.json?ownership=' + this.state.ownership + 
                          '&users=' + this.state.users + 
                          '&properties=' + this.state.properties + 
                          '&statuses=' + this.state.statuses + 
                          '&sort_by=' + this.state.sortBy + 
                          '&order=' + this.state.order +
                          '&query=' + this.state.searchText +
                          '&start_date=' + moment(this.state.startDate).format('YYYY-MM-DD') + 
                          '&end_date=' + moment(this.state.endDate).format('YYYY-MM-DD'),
      success: function(data) {
        this.setState({ bookings: data })
      }.bind(this)
    })
  }

  render() {
    return (
      <div>
        <BookingsNavbar ownership={this.state.ownership} onOwnershipChanged={this.onOwnershipChanged} draftsCount={this.props.draftsCount} />
        <div id="content" className="with-navbar-secondary">
          <div className="container-fluid">
            <div className="row with-sidebar">
              <BookingsSidebar startDate={this.state.startDate} 
                               endDate={this.state.endDate}
                               ownership={this.state.ownership} 
                               properties={this.state.properties}
                               users={this.state.users}
                               statuses={this.state.statuses}
                               allProperties={this.props.allProperties}
                               allStatuses={this.props.allStatuses}
                               allUsers={this.props.allUsers}
                               searchText={this.state.searchText}
                               showDeletedUsers={this.state.showDeletedUsers}
                               onDateRangeChanged={this.onDateRangeChanged}
                               onUserSelected={this.onUserSelected}
                               onStatusSelected={this.onStatusSelected}
                               onPropertySelected={this.onPropertySelected}
                               onSearchFormSubmitted={this.onSearchFormSubmitted}
                               onSelectSalesManagerAll={this.onSelectSalesManagerAll}
                               onSelectSalesManagerMe={this.onSelectSalesManagerMe}
                               onSelectSalesManagerActive={this.onSelectSalesManagerActive}
                               toggleShowDeletedUsers={this.toggleShowDeletedUsers}
                               multiprop={this.state.multiprop} />
              <div className="col-md-10 main">
                <p className="clear"></p>
                <BookingTable data={this.state.bookings} 
                              ownership={this.state.ownership} 
                              onNameClicked={this.onNameClicked} 
                              onDatesClicked={this.onDatesClicked} 
                              onLastUpdatedClicked={this.onLastUpdatedClicked}
                              multiprop={this.state.multiprop} 
                              pmsIntegrated={this.state.pmsIntegrated} />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Bookings