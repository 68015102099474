import flatpickr from 'flatpickr';

$(document).on('click', 'body.nest-clients .combine-clients', function(event) {
  event.preventDefault();
  $('div.client-combo-modal div.modal-body iframe').attr('src', this.href);
  return $('div.client-combo-modal').modal('show');
});

$(document).on('click', 'body.nest-clients .resend-startup-email', function(event) {
  event.preventDefault();
  $('div.startup-email-modal div.modal-body iframe').attr('src', this.href);
  return $('div.startup-email-modal').modal('show');
});

$(document).on('click', 'body.nest-tax_assignments .tax-assign-submit', function(event) {
  event.preventDefault();
  if ($('.mass-assign-tax').val() === '') {
    return alert('Please select a date');
  } else {
    return $('form').submit();
  }
});

$(document).on('click', 'body.nest-batch_mark_beo_completes .mark-beo-submit', function(event) {
  event.preventDefault();
  if ($('#client_beo_check_date').val() === '') {
    return alert('Please select a date');
  } else {
    return $('form').submit();
  }
});

document.addEventListener('turbo:load', function() {
  return flatpickr('#client_beo_check_date', {
    altInput: true,
    altFormat: 'l, F j, Y',
    dateFormat: 'Y-m-d'
  });
});

document.addEventListener('turbo:load', function() {
  return flatpickr('.mass-assign-tax', {
    altInput: true,
    altFormat: 'l, F j, Y',
    dateFormat: 'Y-m-d'
  });
});
