(function() {
  $(document).on('change', 'body.nest-booking_document_templates #copy_merge_doc_template_from_client', function() {
    var $this, prompt, selectedText, selectedVal;
    $this = $(this);
    selectedVal = $this.val();
    selectedText = $this.children("option").filter(":selected").text();
    prompt = "Are you sure you want to copy all merge templates from " + selectedText + "? There is no undo.";
    if (window.confirm(prompt)) {
      $.ajax({
        url: `/nest/clients/${clientSlug}/booking_document_templates/copy_from`,
        data: {
          other: selectedVal
        },
        method: 'POST'
      }).done(function(response) {
        return window.location.reload();
      });
    }
    return $this.val("");
  });

}).call(this);
