(function() {
  $(document).on('click', 'body.permissions .check-all, body.nest-permissions .check-all', function(event) {
    event.preventDefault();
    return $('input[type="checkbox"]').prop('checked', true);
  });

  $(document).on('click', 'body.permissions .uncheck-all, body.nest-permissions .uncheck-all', function(event) {
    event.preventDefault();
    return $('input[type="checkbox"]').prop('checked', false);
  });

}).call(this);
