import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

class DocumentsNavbar extends React.Component {
  constructor() {
    super();
    this.completedClicked = this.completedClicked.bind(this);
    this.draftsClicked = this.draftsClicked.bind(this);
    this.searchClicked = this.searchClicked.bind(this);
  }
  completedClicked(event) {
    this.props.onSearchTypeChanged('sent');
  }
  draftsClicked() {
    this.props.onSearchTypeChanged('drafts');
  }
  searchClicked() {
    this.props.onSearchTypeChanged('search');
  }
  render() {
    if (this.props.draftsCount > 0) {
      var draftsButtonText = (
        <span>
          Drafts&nbsp;
          <span className="badge">{this.props.draftsCount}</span>
        </span>
      );
    } else {
      var draftsButtonText = "Drafts";
    };
    let insightsLi;
    if (window.screen.width >= 540) {
      insightsLi = (
        <li role="presentation">
          <a href="/documents/business_insight_report">
            Insights
            &nbsp;
          </a>
        </li>
      )
    }
    return (
      <div className="navbar navbar-secondary navbar-default navbar-fixed-top" role="navigation">
        <div className="container-fluid">
          <ul className="nav navbar-nav navbar-left">
            <li role="presentation">
              <a ref="completedButton" onClick={this.completedClicked} className={this.props.searchType == 'sent' || this.props.searchType == 'event' || this.props.searchType == 'other' ? 'active' : ''} aria-controls="completed" data-toggle="tab" href="#completed" role="tab">
                Completed
              </a>
            </li>
            <li role="presentation">
              <a ref="draftsButton" onClick={this.draftsClicked} className={this.props.searchType == 'drafts' ? 'active' : ''} aria-controls="drafts" data-toggle="tab" href="#drafts" role="tab">
                {draftsButtonText}
              </a>
            </li>
            <li role="presentation">
              <a ref="searchButton" onClick={this.searchClicked} className={this.props.searchType == 'search' ? 'active' : ''} aria-controls="search" data-toggle="tab" href="#search" role="tab">
                Search
              </a>
            </li>
            {insightsLi}
          </ul>
          <ul className="nav navbar-nav navbar-right">
            <NewDocumentButton templates={this.props.templates} />
          </ul>
        </div>
      </div>
    )
  }
}

class SearchTypePicker extends React.Component {
  constructor() {
    super();
    this.proposalsBySentDateClicked = this.proposalsBySentDateClicked.bind(this);
    this.proposalsByEventDateClicked = this.proposalsByEventDateClicked.bind(this);
    this.otherDocumentsClicked = this.otherDocumentsClicked.bind(this);
    this.searchTypeText = this.searchTypeText.bind(this);
  }
  proposalsBySentDateClicked() {
    this.props.onSearchTypeChanged('sent');
  }
  proposalsByEventDateClicked() {
    this.props.onSearchTypeChanged('event');
  }
  otherDocumentsClicked() {
    this.props.onSearchTypeChanged('other');
  }
  searchTypeText(st) {
    switch (st) {
      case 'sent':
        return 'Proposals by Sent Date';
        break;
      case 'event':
        return 'Proposals by Event Date';
        break;
      case 'other':
        return 'Other Documents';
        break;
      case 'drafts':
        return 'Drafts';
        break;
      case 'search':
        return 'Search';
        break;
    }
  }
  render() {
    var buttonClass = 'btn btn-sm btn-success btn-responsive btn-block dropdown-toggle';
    return (
      <div className="btn-group btn-group-justified">
        <div className="btn-group">
          <a className={buttonClass} data-toggle="dropdown">
            <i className="fa fa-folder-open"></i>&nbsp;&nbsp;
            <span>{this.searchTypeText(this.props.searchType)}</span>&nbsp;
            <b className="caret"></b>
          </a>
          <ul className="dropdown-menu" role="menu">
            <li><a onClick={this.proposalsBySentDateClicked}>Proposals by Sent Date</a></li>
            <li><a onClick={this.proposalsByEventDateClicked}>Proposals by Event Date</a></li>
            <li><a onClick={this.otherDocumentsClicked}>Other Documents</a></li>
          </ul>
        </div>
      </div>
    );
  }
}

class DatePicker extends React.Component {
  componentDidMount() {
    var dateRangePickerOptions = {
      clearClass: 'btn btn-default btn-sm',
      showDropdowns: true,
      ranges: {
        'Last 7 Days': [moment().subtract(6, 'days'), new Date()],
        'Last 30 Days': [moment().subtract(29, 'days'), new Date()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'This Year-to-Date': [moment().startOf('year'), moment()],
        'This Year': [moment().startOf('year'), moment().endOf('year')],
        'Last Year': [moment().subtract(1, 'years').startOf('year'), moment().subtract(1, 'years').endOf('year')]
      }
    };
    var dateRangePickerCallback = function (start, end) {
      ReactDOM.findDOMNode(this.refs.datePickerText).innerHTML = start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY');
      this.props.onDateRangeChanged(start, end);
    }.bind(this);
    $(ReactDOM.findDOMNode(this)).daterangepicker(dateRangePickerOptions, dateRangePickerCallback);
  }
  render() {
    var buttonClass = 'btn btn-success btn-sm btn-responsive date-range btn-block';
    return (
      <div className="btn-group btn-group-justified">
        <div className="btn-group">
          <a className={buttonClass}>
            <i className="fa fa-calendar"></i>&nbsp;&nbsp;<span ref="datePickerText">{moment(this.props.startDate).format('MM/DD/YYYY') + ' - ' + moment(this.props.endDate).format('MM/DD/YYYY')}</span> <b className="caret"></b>
          </a>
        </div>
      </div>
    );
  }
}

class DocumentsSidebar extends React.Component {
  constructor() {
    super();
    this.onSearch = this.onSearch.bind(this);
  }
  onSearch(event) {
    event.preventDefault();
    this.props.onSearch(this.refs.searchQuery.value);
  }
  render() {
    return (
      <div className="col-md-2 sidebar">
        <h6 className="text-uppercase">Filter Documents</h6>
        <form onSubmit={this.onSearch} style={{ display: this.props.searchType == 'search' ? 'block' : 'none' }}>
          <div className="form-group string required search_query">
            <i className="fa fa-search"></i>
            &nbsp;
            <input ref="searchQuery"
              className="string required input-small"
              placeholder="Search&hellip;"
              type="text"
              id="search_query" />
          </div>
        </form>
        <div style={{ display: this.props.searchType == 'sent' || this.props.searchType == 'event' || this.props.searchType == 'other' ? 'block' : 'none' }}>
          <SearchTypePicker searchType={this.props.searchType} onSearchTypeChanged={this.props.onSearchTypeChanged} />
          <DatePicker searchType={this.props.searchType} startDate={this.props.startDate} endDate={this.props.endDate} onDateRangeChanged={this.props.onDateRangeChanged} />
        </div>
      </div>
    )
  }
}

class ActionButton extends React.Component {
  render() {
    var optionsForDrafts = (
      <li>
        <a data-confirm="This draft will be permanently deleted; there is no undo. Are you sure?"
          data-method="delete"
          href={'/documents/' + this.props.documentId} rel="nofollow">
          <i className="fa fa-trash fa-fw"></i>&nbsp;&nbsp;Delete
        </a>
      </li>
    );
    var optionsForSent = [
      (<li key="tracking"><a className="view-tracking" href={'/documents/' + this.props.documentId + '/trackings'}><i className="fa fa-bar-chart fa-fw"></i>&nbsp;&nbsp;Analytics</a></li>)
    ];
    // if (!this.props.presentationOnly) {
    optionsForSent.push(<li key="status"><a className="change-status" href={'/documents/' + this.props.documentId + '/document_status/edit'}><i className="fa fa-comments fa-fw"></i>&nbsp;&nbsp;Notes/Change Status</a></li>);
    // }
    if (this.props.esignature && this.props.signed) {
      optionsForSent.push(<li key="signed"><a className="view-signed" href={'/documents/' + this.props.documentId + '/envelope_download'} target="_blank"><i className="fa fa-file-signature fa-fw"></i>&nbsp;&nbsp;Viewed Signed Contract</a></li>);
    }
    if (this.props.esignature) {
      optionsForSent.push(<li key="esignature"><a className="send-for-signature" data-method="post" href={'/documents/' + this.props.documentId + '/signatures'}><i className="fa fa-pen fa-fw"></i>&nbsp;&nbsp;Send New E-Signature</a></li>);
    }
    return (
      <div className="btn-group">
        <a className="btn btn-default dropdown-toggle" data-toggle='dropdown' href='#'>
          <i className="fa fa-cog fa-fw"></i> <b className="caret"></b>
        </a>
        <ul className="dropdown-menu">
          <li><a href={'/documents/' + this.props.documentId + '/edit'}><i className="fa fa-edit fa-fw"></i>&nbsp;&nbsp;Edit</a></li>
          <li><a className="copy-document" href={'/documents/' + this.props.documentId + '/document_copy/new'}><i className="fa fa-copy fa-fw"></i>&nbsp;&nbsp;Make a Copy</a></li>
          <li><a href={'/documents/' + this.props.documentId + '/preview'}><i className="fa fa-desktop fa-fw"></i>&nbsp;&nbsp;Preview</a></li>
          {this.props.searchType == 'drafts' ? optionsForDrafts : optionsForSent}
        </ul>
      </div>
    );
  }
}

class DocumentTableRow extends React.Component {
  render() {
    var rentalRevenueDisplayed;
    if (this.props.totalRentalRevenue) {
      rentalRevenueDisplayed = <span><strong>Rental:</strong> {this.props.totalRentalRevenue} <br /></span>;
    };
    var fbRevenueDisplayed;
    if (this.props.totalFbRevenue) {
      fbRevenueDisplayed = <span><strong>F&B Min:</strong> {this.props.totalFbRevenue} <br /></span>;
    };
    var cateringRevenueDisplayed;
    if (this.props.totalCateringRevenue) {
      cateringRevenueDisplayed = <span><strong>Catering:</strong> {this.props.totalCateringRevenue} <br /></span>;
    };
    var golfRevenueDisplayed;
    if (this.props.totalGolfRevenue) {
      golfRevenueDisplayed = <span><strong>Golf:</strong> {this.props.totalGolfRevenue} <br /></span>;
    };
    var roomsRevenueDisplayed;
    if (this.props.totalRoomsRevenue) {
      roomsRevenueDisplayed = <span><strong>Rooms:</strong> {this.props.totalRoomsRevenue} <br /></span>;
    };
    var lostReasonDisplayed;
    if (this.props.lostReason && this.props.lostReason.length) {
      lostReasonDisplayed = <div><em className="bg-warning">{this.props.lostReason}</em></div>;
    };
    var inactiveDisplayed;
    if (this.props.inactive) {
      inactiveDisplayed = <div><em className="bg-danger">Inactive</em></div>;
    };
    var lastViewDisplayed;
    if (this.props.lastViewedAt && this.props.status) {
      var lastViewedFormatted = moment(this.props.lastViewedAt).format('M/D/YYYY h:mm:ss a');
      lastViewDisplayed = <div><em className="bg-success"><i className="fa fa-eye"></i> Last Viewed {lastViewedFormatted}</em></div>;
    } else if (this.props.status) {
      lastViewDisplayed = <div><em className="bg-warning"><i className="fa fa-eye-slash"></i> Not Yet Viewed</em></div>;
    };
    var signedDisplayed;
    if (this.props.esignature) {
      if (this.props.signedAt && this.props.status) {
        var signedFormatted = moment(this.props.signedAt).format('M/D/YYYY h:mm:ss a');
        signedDisplayed = <div><em className="bg-success"><i className="fa fa-check-circle-fa"></i> Signed {signedFormatted}</em></div>;
      } else if (this.props.status) {
        signedDisplayed = <div><em className="bg-warning"><i className="fa fa-minus-circle"></i> Not Signed</em></div>;
      };
    }
    return (
      <tr>
        <td>
          <ActionButton documentId={this.props.documentId}
            searchType={this.props.searchType}
            esignature={this.props.esignature}
            signed={this.props.signedAt && this.props.status}
            signedToken={this.props.signedToken}
            presentationOnly={this.props.presentationOnly} />
        </td>
        <td>
          <strong>{this.props.customerName}</strong><br />
          {this.props.customerContactName}<br />
          <a href={'mailto:' + this.props.customerContactEmail}>{this.props.customerContactEmail}</a>
          {lastViewDisplayed}
          {signedDisplayed}
          {lostReasonDisplayed}
          {inactiveDisplayed}
        </td>
        <td><a href={'/documents/' + this.props.documentId + '/edit/general'}>{this.props.title}</a></td>
        <td>{this.props.availableDates}</td>
        {!this.props.presentationOnly &&
          <td>{this.props.totalRooms}</td>
        }
        {!this.props.presentationOnly &&
          <td>
            {roomsRevenueDisplayed}
            {rentalRevenueDisplayed}
            {fbRevenueDisplayed}
            {cateringRevenueDisplayed}
            {golfRevenueDisplayed}
          </td>
        }
      </tr>
    )
  }
}

class DocumentTable extends React.Component {
  render() {
    var tableRows = this.props.data.map(function (row) {
      return (
        <DocumentTableRow key={row.id}
          searchType={this.props.searchType}
          documentId={row.id}
          status={row.status}
          title={row.title}
          customerName={row.account_or_customer_name}
          customerContactName={row.contact_or_customer_contact_name}
          customerContactEmail={row.contact_email_or_customer_contact_email}
          availableDates={row.available_dates}
          lastUpdated={row.updated_at}
          lastViewedAt={row.last_viewed_at}
          signedAt={row.signed_at}
          signedSignerId={row.signed_signer_id}
          signedToken={row.signed_token}
          totalRooms={row.total_rooms}
          totalRoomsRevenue={row.total_rooms_revenue_formatted}
          totalRentalRevenue={row.total_rental_revenue_formatted}
          totalFbRevenue={row.total_fb_revenue_formatted}
          totalCateringRevenue={row.total_catering_revenue_formatted}
          totalGolfRevenue={row.total_golf_revenue_formatted}
          lostReason={row.lost_reason}
          inactive={row.inactive}
          esignature={this.props.esignature}
          presentationOnly={this.props.presentationOnly} />
      );
    }.bind(this));
    return (
      <table className="table table-list table-responsive">
        <thead>
          <tr>
            <th style={{ width: '8%' }}>Actions</th>
            <th style={{ width: '25%' }}>Customer</th>
            <th style={{ width: '28%' }}>Title</th>
            <th style={{ width: '13%' }}>Dates</th>
            {!this.props.presentationOnly &&
              <th style={{ width: '13%' }}>Total Guest Rooms</th>
            }
            {!this.props.presentationOnly &&
              <th style={{ width: '13%' }}>Revenue</th>
            }
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </table>
    )
  }
}

class NewDocumentButton extends React.Component {
  render() {
    var templateOptions = this.props.templates.map(function (template) {
      return (
        <li key={template.id}>
          <a href={'/documents/new?template_id=' + template.id}>
            {template.name}
          </a>
        </li>
      )
    });
    return (
      <div className="scroll-dropdown navbar-btn">
        <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown">
          <i className="fa fa-plus"></i>
          &nbsp;New&nbsp;
          <b className="caret"></b>
        </button>
        <ul className="dropdown-menu" role="menu">
          {templateOptions}
        </ul>
      </div>
    )
  }
}

class ProposalpathMessageContent extends React.Component {
  render() {
    if (this.props.proposalpathMessage != null) {
      return <div className="alert alert-info text-center" style={{ padding: '8px' }}>
        <div style={{color: '#44617a', fontSize: '13px', fontWeight: 'bold'}}
          dangerouslySetInnerHTML={{ __html: this.props.proposalpathMessage }}>
        </div>
      </div>
    } else {
      return null;
    }
  }
}
class DocumentContent extends React.Component {
  render() {
    if (this.props.loading) {
      return <i className="fa fa-spin fa-4x fa-refresh"></i>;
    } else if (this.props.groups.length == 0) {
      return <div className="well">No documents.</div>;
    } else {
      var documentList = this.props.groups.map(function (group) {
        return (
          <div key={group.status}>
            <br className="clear" />
            <h5 className="page-header">
              {group.status} <span className="badge badge-info">{group.count}</span>
            </h5>
            <DocumentTable data={group.documents}
              searchType={this.props.searchType}
              esignature={this.props.esignature}
              presentationOnly={this.props.presentationOnly} />
          </div>
        )
      }.bind(this));
    };
    return <div>{documentList}</div>;
  }
}

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: {
        startDate: props.initialStartDate,
        endDate: props.initialEndDate,
        searchType: props.initialSearchType,
        searchQuery: props.initialSearchQuery
      },
      groups: []
    };
    this.onSearchTypeChanged = this.onSearchTypeChanged.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onDateRangeChanged = this.onDateRangeChanged.bind(this);
    this.fetchDocumentData = this.fetchDocumentData.bind(this);
  }
  componentDidMount() {
    window.handleRowWithSidebarHeight();
    this.fetchDocumentData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.filter !== this.state.filter) {
      this.fetchDocumentData();
    }
  }
  onSearchTypeChanged(type) {
    this.setState({
      filter: {
        searchType: type,
        searchQuery: this.state.filter.searchQuery,
        startDate: this.state.filter.startDate,
        endDate: this.state.filter.endDate
      }
    });
  }
  onSearch(query) {
    this.setState({
      filter: {
        searchType: 'search',
        searchQuery: query,
        startDate: this.state.filter.startDate,
        endDate: this.state.filter.endDate
      }
    });
  }
  onDateRangeChanged(start, end) {
    this.setState({
      filter: {
        searchType: this.state.filter.searchType,
        searchQuery: this.state.filter.searchQuery,
        startDate: start,
        endDate: end
      }
    });
  }
  fetchDocumentData() {
    this.setState({ loading: true });
    $.ajax({
      url: '/documents.json?search_type=' + this.state.filter.searchType +
        '&search_term=' + this.state.filter.searchQuery +
        '&start_date=' + moment(this.state.filter.startDate).format('YYYY-MM-DD') +
        '&end_date=' + moment(this.state.filter.endDate).format('YYYY-MM-DD'),
      success: function (data) {
        this.setState({ loading: false, groups: data.groups })
      }.bind(this)
    });
  }
  render() {
    return (
      <div>
        <DocumentsNavbar draftsCount={this.props.draftsCount}
          searchType={this.state.filter.searchType}
          onSearchTypeChanged={this.onSearchTypeChanged}
          templates={this.props.templates} />
        <div id="content" className="with-navbar-secondary">
          <div className="container-fluid">
            <div className="row with-sidebar">
              <DocumentsSidebar startDate={this.state.filter.startDate}
                endDate={this.state.filter.endDate}
                searchType={this.state.filter.searchType}
                searchQuery={this.state.filter.searchQuery}
                onDateRangeChanged={this.onDateRangeChanged}
                onSearchTypeChanged={this.onSearchTypeChanged}
                onSearch={this.onSearch} />
              <div className="col-md-10 main">
                <p className="clear"></p>                
                <ProposalpathMessageContent proposalpathMessage={this.props.proposalpathMessage} />                
                <DocumentContent loading={this.state.loading}
                  searchType={this.state.filter.searchType}
                  groups={this.state.groups}
                  esignature={this.props.esignature}
                  presentationOnly={this.props.presentationOnly} />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Documents;
