(function() {
  $(document).on('change', '#menu_item_menu_category_ids', function(event) {
    return $.getJSON(`/menu_categories/${$(this).val()}.json`, function(data) {
      $('#menu_item_expense_category').val(data.expense_category);
      $('#menu_item_revenue_category').val(data.revenue_category);
      $('#menu_item_apply_food_tax').prop('checked', data.apply_food_tax);
      $('#menu_item_apply_beverage_tax').prop('checked', data.apply_beverage_tax);
      $('#menu_item_apply_sales_tax').prop('checked', data.apply_sales_tax);
      $('#menu_item_apply_federal_sales_tax').prop('checked', data.apply_federal_sales_tax);
      $('#menu_item_apply_service_charge').prop('checked', data.apply_service_charge);
      $('#menu_item_apply_service_charge_2').prop('checked', data.apply_service_charge_2);
      $('#menu_item_apply_gratuities').prop('checked', data.apply_gratuities);
      $('#menu_item_apply_admin_fee').prop('checked', data.apply_admin_fee);
      $('#menu_item_expense_category').trigger('change');
      return $('#menu_item_revenue_category').trigger('change');
    });
  });

  // edit: locale navigation
  $(document).on('click', 'ul.locales-for-menus-nav li a', function(event) {
    var $this, locale;
    $this = $(this);
    locale = $this.attr('rel');
    window.menuItemLocaleId = locale;
    event.preventDefault();
    $('ul.locales-for-menus-nav li a').removeClass('current');
    $this.addClass('current');
    $('.locale').addClass('hidden');
    return $('.locale-' + locale).removeClass('hidden');
  });

}).call(this);
