import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["postSubmit"]

  connect() {
    if (this.postSubmitTarget.dataset.reload === "true") {
      $(`#${this.element.id}`).modal('show')
      const notice = this.postSubmitTarget.dataset.success
      if (notice.length > 0) {
        this.hide_modal()
        const noticeDom= document.querySelector("#flashes")
        const success = document.createElement('div')
        success.innerHTML = '<div id="alertdiv" class="alert alert-success">' +
            '<a class="close" data-dismiss="alert">×</a>' +
            '<span>' + notice + '</span>' +
          '</div>'
        noticeDom.insertBefore(success, noticeDom.firstChild);
        setTimeout(function() {
          $("#alertdiv").remove();
        }, 5000);
      }
    }
  }

  disconnect() {
    this.hide_modal()
  }

  hide_modal() {
    $(`#${this.element.id}`).modal('hide')
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }
}
