import moment from 'moment'

let fabricCanvases = {}
$(function() {
  if ($('body').hasClass('floor_plans')) {
    let tt = null
    let currentDateOrProperty = null
    let meetingNotes = false
    let jumpButtons
    const floorPlansScale = .55
    const byProperty = getParameterByName('by_property') == 'true'

    const renderFloorPlans = function() {
      let categoryLinks = []
      let markup = ''
      let className = "list-group-item go-to-meeting-category"
      if (!currentDateOrProperty) {
        className += " active"
      }
      markup += "<a href='javascript:;' class='" + className + "' data-category=''>Meeting Space Overview</a>"
      categoryLinks.push(markup)

      if (byProperty) {
        meetingProperties.forEach(function (meetingProperty) {
          let markup = ''
          const meetingPropertyId = meetingProperty[0]
          const meetingPropertyName = meetingProperty[1]
          if (currentDateOrProperty === meetingPropertyId) {
            markup += "<a href='javascript:;' class='list-group-item active go-to-meeting-category' data-category='" + meetingPropertyId + "'>"
            markup += meetingPropertyName
            markup += "</a>"
          } else {
            markup += "<a href='javascript:;' class='list-group-item go-to-meeting-category' data-category='" + meetingPropertyId + "'>"
            markup += meetingPropertyName
            markup += "</a>"
          }
          categoryLinks.push(markup)
        })
      } else {
        meetingDates.forEach(function(meetingDate) {
          let markup = ''
          const meetingDateFormatted = moment(meetingDate).format('dddd, MMMM D')
          if (currentDateOrProperty === meetingDate) {
            markup += "<a href='javascript:;' class='list-group-item active go-to-meeting-category' data-category='" + meetingDate + "'>"
            markup += meetingDateFormatted
            markup += "</a>"
          } else {
            markup += "<a href='javascript:;' class='list-group-item go-to-meeting-category' data-category='" + meetingDate + "'>"
            markup += meetingDateFormatted
            markup += "</a>"
          }
          categoryLinks.push(markup)
        })
      }
      $('.floor-plan-days').html(categoryLinks.join(''))

      let url = "/floor_plans.json?document_id=" + documentId
      if (byProperty) {
        url += "&by_property=true"
      }
      if (currentDateOrProperty) {
        url += "&category=" + currentDateOrProperty
      }
      $.get(url, function(data) {
        $('#canvases').empty()
        jumpButtons = '<ol class="breadcrumb" style="margin-bottom:10px"><li><strong>On this page:</strong></li>'
        fabricCanvases = {}
        meetingNotes = data.meeting_notes

        if (data.meetings.length > 0 && currentDateOrProperty) {
          $('#meeting-space-table').show()
          createMeetingSpace(data.meetings)
        } else {
          $('#meeting-space-table').hide()
          $('#meeting-space-table tbody').empty()
        }
        if (data.diagrams.length > 0) {
          data.diagrams.forEach(function(diagram) {
            const diagramDomId = "diagram-canvas-" + diagram.diagram_id
            if (!$('#' + diagramDomId).length) {
              $('#canvases').append("<div id='panel-" + diagram.diagram_id + "' class='panel panel-default'><div class='panel-heading'><h4 class='panel-title'>" + diagram.floor_plan + "</h4></div><div class='panel-body'><canvas id='" + diagramDomId + "' width='715' height='715'></canvas></div>")
              let c = new fabric.Canvas(diagramDomId)
              fabricCanvases[diagramDomId] = c
              c.selection = false
              c.hoverCursor = 'pointer'
              if (diagram.url != '') { // just leave it alone if no mapped events
                c.setBackgroundImage(diagram.url, c.renderAll.bind(c), {
                  originX: 'left',
                  originY: 'top',
                  scaleX: floorPlansScale,
                  scaleY: floorPlansScale,
                  crossOrigin: 'anonymous'
                })
              }
              jumpButtons += '<li><a class="jump" href="#panel-' + diagram.diagram_id + '">' + diagram.floor_plan + '</a></li>'
            }
            drawDiagramCanvas(fabricCanvases[diagramDomId], diagram)
          })
        }
        jumpButtons += '</ol>'
        $('#canvases').prepend(jumpButtons)
        $('.breadcrumb').toggle(data.diagrams.length > 1)
      })
    }

    const drawCircleOnFloorPlan = function(canvas, diagram) {
      const options = {
        radius: diagram.radius * floorPlansScale,
        fill: 'rgba(255, 0, 0, .6)',
        left: diagram.left * floorPlansScale,
        top: diagram.top * floorPlansScale,
        hasControls: false,
        hasBorders: false,
        lockMovementX: true,
        lockMovementY: true,
        hoverCursor: 'pointer'
      }
      let circle = new fabric.Circle(options)
      diagram.meetings.forEach(function(meeting) {
        if (meeting.function_type !== undefined) {
          circle.set('fill', meeting.function_type.color)
          if (!meeting.function_type.color.match(/rgba/)) {
            circle.set('opacity', .6)
          }
        }
      })
      canvas.add(circle)
      prepareTooltipForDiagram(canvas, circle, diagram)
    }

    const drawRectangleOnFloorPlan = function(canvas, diagram) {
      const options = {
        width: diagram.width * floorPlansScale,
        height: diagram.height * floorPlansScale,
        angle: diagram.angle,
        fill: 'rgba(255, 0, 0, .6)',
        left: diagram.left * floorPlansScale,
        top: diagram.top * floorPlansScale,
        hasControls: false,
        hasBorders: false,
        lockMovementX: true,
        lockMovementY: true,
        hoverCursor: 'pointer'
      }
      let rect = new fabric.Rect(options)
      diagram.meetings.forEach(function(meeting) {
        if (meeting.function_type !== undefined) {
          rect.set('fill', meeting.function_type.color)
          if (!meeting.function_type.color.match(/rgba/)) {
            rect.set('opacity', .6)
          }
        }
      })
      canvas.add(rect)
      prepareTooltipForDiagram(canvas, rect, diagram)
    }

    const drawPolygonOnFloorPlan = function(canvas, diagram) {
      const points = JSON.parse(diagram.points)
      let scaledPoints = []
      points.forEach(function(point) {
        scaledPoints.push({ x: point.x * floorPlansScale, y: point.y * floorPlansScale })
      })
      let polygon = new fabric.Polygon(scaledPoints, { strokeWidth: 0,
          fill: 'rgba(255, 0, 0, .6)',
          opacity: 1,
          hasBorders: false,
          hasControls: true,
          top: diagram.top * floorPlansScale,
          left: diagram.left * floorPlansScale,
          selectable: true})
      diagram.meetings.forEach(function(meeting) {
        if (meeting.function_type !== undefined) {
          polygon.set('fill', meeting.function_type.color)
          if (!meeting.function_type.color.match(/rgba/)) {
            polygon.set('opacity', .6)
          }
        }
      })
      canvas.add(polygon)
      prepareTooltipForDiagram(canvas, polygon, diagram)
    }

    const prepareTooltipForDiagram = function(canvas, shape, diagram) {
      shape.on("mouseover", function(e) {
        const ttOptions = {
                      backgroundColor: 'rgba(0, 0, 0, .6)',
                      fill: '#fff',
                      editable: false,
                      hasBorders: false,
                      hasControls: false,
                      hasRotatingPoint: false,
                      fontSize: 12,
                      fontFamily: 'Helvetica Neue, Arial, sans-serif',
                      fontWeight: '400',
                      padding: 0,
                      textAlign: 'center'
                    }
        
        let markup = diagram.name
        let eventTypes = []

        if (diagram.meetings.length > 0) {
          markup += " – "
          diagram.meetings.forEach(function(meeting) {
            if (eventTypes.indexOf(meeting.function) == -1) {
              eventTypes.push([meeting.function, meeting.notes].filter(Boolean).join(' – '));
            }
          })
          markup += eventTypes.join(', ')
        }

        tt = new fabric.Text(markup, ttOptions)

        const wouldBeTop = this.top - tt.height - 10
        if (wouldBeTop < 0) {
          tt.set('bottom', this.bottom)
        } else {
          tt.set('top', wouldBeTop)
        }

        const wouldBeLeft = this.left - 37
        if (wouldBeLeft < 0) {
          tt.set('right', this.right)
        } else {
          tt.set('left', wouldBeLeft)
        }

        canvas.add(tt)
        canvas.renderAll()
      })
      shape.on("mouseout", function(e) {
        canvas.renderAll()
        canvas.remove(tt)
      })
      shape.on("mouseup", function(e) {
        const meetingRoomLightbox = '#meeting-room-lightbox-' + diagram.meeting_room_id
        if ($(meetingRoomLightbox).length) {
          $.fancybox.open({
            src: meetingRoomLightbox,
            type: 'inline'
          })
        }
      })
    }
    
    const drawDiagramCanvas = function(canvas, diagram) {
      if (diagram.points) {
        drawPolygonOnFloorPlan(canvas, diagram)
      } else if (diagram.radius) {
        drawCircleOnFloorPlan(canvas, diagram)
      } else {
        drawRectangleOnFloorPlan(canvas, diagram)
      }
    }

    const createMeetingSpace = function(meetings) {
      const $tbody = $('#meeting-space-table tbody')
      let legend = ''
      let displayTime = false
      $tbody.empty()
      meetings.forEach(function(meeting, index) {
        if (meeting.time != ' – ') {
          displayTime = true
        }
        if (meeting.function_type !== undefined) {
          const functionTypeColor = meeting.function_type && meeting.function_type.color
          legend = "<span class='function-type-legend' style='width:20pxheight:10pxmargin-right:5pxdisplay:inline-blockbackground-color:" + functionTypeColor + "'></span>"
        } else {
          legend = ''
        }
        let markup = "<tr class='" + (index%2 == 0 ? 'Even' : 'Odd') + "'>"
        let meetingRoomTextOrLink
        if (meeting.content_present) {
          meetingRoomTextOrLink = "<a href='javascript:' class='meeting-room-info' data-fancybox data-src='#meeting-room-lightbox-" + meeting.meeting_room_id + "'>" + meeting.room + "</a>"
        } else {
          meetingRoomTextOrLink = meeting.room
        }
        markup += "<td class='time-column'>" + meeting.time + "</td>"
        markup += "<td>" + legend + meeting.function + "</td>"
        markup += "<td>" + meetingRoomTextOrLink + "</td>"
        markup += "<td>" + meeting.number_setup + "</td>"
        if (meetingNotes) {
          markup += "<td>" + meeting.notes + "</td>"
        }
        markup += "</tr>"
        $tbody.append(markup)
      })

      if (displayTime) {
        $('.time-column').show()
      } else {
        $('.time-column').hide()
      }
    }

    $(document).on('click', '.go-to-meeting-category', function(event) {
      event.preventDefault()
      currentDateOrProperty = $(this).data('category')
      if (currentDateOrProperty === '') {
        currentDateOrProperty = null
      }
      renderFloorPlans()
    })

    // smooth scroll the jump button
    $(document).on('click', '.jump', function(event) {
      event.preventDefault()
      const hash = this.hash
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function () {
        window.location.hash = hash
      })
    })

    $(document).on('click', '.print-floor-plan', function(event) {
      event.preventDefault()
      for (let canvasDomId in fabricCanvases) {
        const c = fabricCanvases[canvasDomId]
        const markup = "<input type='hidden' name='floor_plan[image_urls][]' value='" + c.toDataURL({ format: 'png' }) + "'></input>"
        $('form').append(markup)
      }
      $('form').submit()
    })

    renderFloorPlans()
  }
})