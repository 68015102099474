(function() {
  // RFP forms don't use Turbo
  document.addEventListener('DOMContentLoaded', function() {
    var dateOptions;
    dateOptions = {
      changeMonth: true,
      changeYear: true,
      minDate: 0
    };
    $('#lead_wedding_date').datepicker($.extend({}, dateOptions, {
      onSelect: function(selectedDate) {
        $('#lead_start_date').val(selectedDate);
        $('#lead_end_date').val(selectedDate);
        return $('#lead_end_date').datepicker('option', 'minDate', selectedDate);
      }
    }));
    $('#lead_start_date').datepicker($.extend({}, dateOptions, {
      onSelect: function(selectedDate) {
        $('#lead_end_date').val(selectedDate);
        return $('#lead_end_date').datepicker('option', 'minDate', selectedDate);
      }
    }));
    $('#lead_end_date').datepicker($.extend({}, dateOptions));
    $('.lead_start_date i.fa-calendar').on('click', function(event) {
      return $('#lead_start_date').datepicker('show');
    });
    $('.lead_end_date i.fa-calendar').on('click', function(event) {
      return $('#lead_end_date').datepicker('show');
    });
    $('.lead_wedding_date i.fa-calendar').on('click', function(event) {
      return $('#lead_wedding_date').datepicker('show');
    });
    $('.select-all').on('click', function(event) {
      event.preventDefault();
      return $('.select-property').prop('checked', true);
    });
    return $('#lead_lead_source_id').on('change', function(event) {
      var selectedOption;
      selectedOption = $(this).find("option:selected").text();
      if (selectedOption.toLowerCase() === 'other') {
        return $('.please-specify').show();
      } else {
        $('#lead_lead_source_notes').val('');
        return $('.please-specify').hide();
      }
    });
  });

}).call(this);
