(function() {
  $(document).on('click', 'body.volume_contract_templates a.insert-merge-field, body.nest-volume_contract_templates a.insert-merge-field', function(event) {
    var tag;
    event.preventDefault();
    tag = $(this).html();
    if (!tag.startsWith('[[')) {
      tag = `&lt;&lt;${tag}&gt;&gt;`;
    }
    return tinymce.activeEditor.execCommand('mceInsertContent', false, tag);
  });

  $(document).on('change', 'body.nest-volume_contract_templates #copy_volume_doc_template_from_client', function() {
    var $this, prompt, selectedText, selectedVal;
    $this = $(this);
    selectedVal = $this.val();
    selectedText = $this.children("option").filter(":selected").text();
    prompt = "Are you sure you want to copy all volume contract merge templates from " + selectedText + "? There is no undo.";
    if (window.confirm(prompt)) {
      $.ajax({
        url: `/nest/clients/${clientSlug}/volume_contract_templates/copy_from`,
        data: {
          other: selectedVal
        },
        method: 'POST'
      }).done(function(response) {
        return window.location.reload();
      });
    }
    return $this.val("");
  });

  document.addEventListener('turbo:load', function() {
    if ($('body').hasClass('volume_contract_templates') || $('body').hasClass('nest-volume_contract_templates')) {
      return window.initTinyMCEFull();
    }
  });

}).call(this);
