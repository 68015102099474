import Bookings from './Bookings'
import Documents from './Documents'
import Grc from './Grc'

const ReactRailsUJS = require("react_ujs")

ReactRailsUJS.getConstructor = (name) => {
  switch (name) {
    case 'Bookings':
      return Bookings
    case 'Documents':
      return Documents
    case 'Grc':
      return Grc
    default:
      return null
  }
}

ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount, false)
ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount, false)
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount, false)
