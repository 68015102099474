(function() {
  document.addEventListener('turbo:load', function() {
    var poll;
    poll = function() {
      return $.post(`/nest/conversions/${window.homeDirectory}`, "_method=put", function() {
        var $output;
        $output = $('#output');
        return $output.scrollTop($output[0].scrollHeight - $output.height());
      });
    };
    if ($('body').hasClass('nest-conversions create')) {
      return window.setInterval(poll, 5000);
    }
  });

}).call(this);
