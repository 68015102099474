(function() {
  document.addEventListener('turbo:load', function() {
    var $body;
    $body = $('body');
    if ($body.hasClass('menu_sections') || $body.hasClass('nest-menu_sections')) {
      return $('#menu_section_menu_category_ids').select2({
        width: 'resolve',
        placeholder: "Please select",
        closeOnSelect: false
      });
    }
  });

}).call(this);
