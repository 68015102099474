import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['deletedUsersToggle', 'userCheckbox', 'signatureStatusCheckbox',
    'filterForm', 'searchInput', 'clearSearchButton', 'propertyInput', 'signableCheckbox']

  connect() {
    $('#multi-prop-select').on('select2:select', this.update.bind(this))
    $('#multi-prop-select').on('select2:clear', this.update.bind(this))
  }

  clearSearch() {
    this.searchInputTarget.value = '';
    this.clearSearchButtonTarget.classList.add('hidden');
    this.search();
  }

  search(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if (this.searchInputTarget.value.length === 0) {
        this.clearSearchButtonTarget.classList.add('hidden')
        this.update()
      } else if (this.searchInputTarget.value.length >= 3) {
        this.clearSearchButtonTarget.classList.remove('hidden')
        this.update()
      } else {
        this.clearSearchButtonTarget.classList.remove('hidden')
      }
    }, 500)
  }

  selectUserAll() {
    this.userCheckboxTargets.forEach(e => e.checked = true);
    this.update();
  }

  selectUserMe() {
    this.userCheckboxTargets.forEach((e) => {
      if (!e.classList.contains("user-me")) {
        e.checked = false
      }
    });
    this.update();
  }

  selectUserActive() {
    this.userCheckboxTargets.forEach(e => e.checked = false);
    this.userCheckboxTargets.filter(c => !c.classList.contains('user-deleted'))
      .forEach(e => e.checked = true);
    this.update();
  }

  toggleDeletedUsers() {
    document.querySelectorAll('.checkbox.deleted').forEach(e => {
      e.classList.toggle('hidden');
      this.deletedUsersToggleTarget.textContent = e.classList.contains('hidden') ? '+ Deleted' : '- Deleted';
    });
  }

  statusChanged() {
    if (this.noneChecked(this.signatureStatusCheckboxTargets)) {
      this.signatureStatusCheckboxTargets.find(e => e.value === "out_for_signature").checked = true;
    }
    this.update()
  }

  signableChanged() {
    if (this.noneChecked(this.signableCheckboxTargets)) {
      this.signableCheckboxTargets.find(e => e.value === "None").checked = true;
    }
    this.update()
  }

  update() {
    window.pleaseWait();
    this.searchInput = document.createElement("input")
    this.searchInput.setAttribute("type", "hidden")
    this.searchInput.setAttribute("value", this.searchInputTarget.value)
    this.searchInput.setAttribute("name", "query")
    this.propertyInput = document.createElement("input")
    this.propertyInput.setAttribute("type", "hidden")
    this.propertyInput.setAttribute("value", this.propertyInputTarget.value)
    this.propertyInput.setAttribute("name", "property")
    this.filterFormTarget.appendChild(this.searchInput)
    this.filterFormTarget.appendChild(this.propertyInput)
    this.filterFormTarget.requestSubmit()
  }

  unblockUI() {
    if (this.searchInput) {
      this.filterFormTarget.removeChild(this.searchInput)
      this.searchInput = undefined
      this.filterFormTarget.removeChild(this.propertyInput)
      this.propertyInput = undefined
    }
    window.unblockUI();
  }

  // private

  noneChecked(checkboxes) {
    return typeof(checkboxes.find((elem) => elem.checked)) === 'undefined'
  }
}
