import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["removeActivity", "saveActivities"]

  remove(e) {
    e.preventDefault()
    const link = e.target.closest('a')
    const removeID = link.dataset.id
    const toSubmit = this.removeActivityTargets.find((form) => {
      return form.dataset.id === removeID
    })
    if (toSubmit !== undefined) {
      e.target.closest('tr').remove()
      toSubmit.closest('form').requestSubmit()
    }
  }

  uncache() {
    this.saveActivitiesTarget.requestSubmit()
  }

  ignore_cache() {
    const ignoreCache = document.createElement('input')
    ignoreCache.setAttribute('type', 'hidden')
    ignoreCache.setAttribute('name', 'booking[ignore_cache]')
    ignoreCache.setAttribute('value', true)
    this.saveActivitiesTarget.appendChild(ignoreCache)
    this.saveActivitiesTarget.requestSubmit()
  }
}
