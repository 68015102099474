import { Controller } from '@hotwired/stimulus'
import throttle from 'lodash/throttle'

export default class extends Controller {
  static targets = ['activityType', 'contact', 'company', 'search']

  connect() {
    // ghetto but it works
    // select2 needs jQuery to raise the event
    $('#activity_type_filter,#activity_contact_filter,#activity_company_filter').on('select2:select', function() {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event)
    })

    this.listenForSearch()
  }

  listenForSearch() {
    this.searchTarget.addEventListener('keyup', throttle(this.filter, 2000).bind(this))
    this.searchTarget.addEventListener('keydown', event => {
      if (event.keyCode == 13) {
        event.preventDefault()
        return false
      } else if (event.keyCode == 27) {
        this.clearSearch()
      }
    })
  }

  clearSearch() {
    this.searchTarget.value = ''
    this.filter()
  }

  filter(event) {
    const params = { 
      activity_type: this.activityTypeTarget.value,
      contact_id: this.contactTarget.value,
      company: this.companyTarget.value,
      search: this.searchTarget.value
    }
    Turbo.visit(
      `/accounts/${accountId}/activities?${$.param(params)}`, 
      { frame: 'account-activities' }
    )
  }

}