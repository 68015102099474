(function() {
  document.addEventListener('turbo:load', function() {
    var handleRadioChange, htmlFormat, pdfFormat, pdfOptionElement, xlsxFormat;
    $('.reporting-client-property-select').on('change', function() {
      if ($(this).val().includes('')) {
        $('.reporting-client-property-select option').prop('selected', true);
        return $('.reporting-client-property-select option[value=""]').prop('selected', false);
      }
    });
    $('.reporting-property-select').on('change', function() {
      if ($(this).val().includes('')) {
        $('.reporting-property-select option').prop('selected', true);
        return $('.reporting-property-select option[value=""]').prop('selected', false);
      }
    });
    if ($('.reporting-property-select option:selected').length === 0) {
      $($('.reporting-property-select option')[0]).prop('selected', true);
    }
    if ($('.reporting-user-select option:selected').length === 0) {
      $($('.reporting-user-select option')[0]).prop('selected', true);
    }
    if ($('.reporting-template-select option:selected').length === 0) {
      $($('.reporting-template-select option')[0]).prop('selected', true);
    }
    if ($('.reporting-source-select option:selected').length === 0) {
      $($('.reporting-source-select option')[0]).prop('selected', true);
    }
    if ($('.reporting-market-segment-select option:selected').length === 0) {
      $($('.reporting-market-segment-select option')[0]).prop('selected', true);
    }
    if ($('.reporting-meeting-room-select option:selected').length === 0) {
      $($('.reporting-meeting-room-select option')[0]).prop('selected', true);
    }
    if ($('.reporting-function-type-select option:selected').length === 0) {
      $($('.reporting-function-type-select option')[0]).prop('selected', true);
    }
    
    // Sales Activity Report - show PDF orientation option only when PDF format is selected 
    if (document.querySelector('form[action="/reporting/reports/sales_activity"]')) {
      pdfFormat = document.getElementById("format_pdf");
      htmlFormat = document.getElementById("format_html");
      xlsxFormat = document.getElementById("format_xlsx");
      pdfOptionElement = document.getElementById("pdfOrientationOption");
      handleRadioChange = function() {
        if (pdfFormat.checked) {
          return pdfOptionElement.style.display = "block";
        } else {
          return pdfOptionElement.style.display = "none";
        }
      };
      pdfFormat.addEventListener("change", handleRadioChange);
      htmlFormat.addEventListener("change", handleRadioChange);
      return xlsxFormat.addEventListener("change", handleRadioChange);
    }
  });

}).call(this);
