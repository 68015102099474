(function() {
  document.addEventListener('turbo:load', function() {
    $('a.select-all-media').on('click', function(event) {
      event.preventDefault();
      return $('input.media-selected').prop('checked', true);
    });
    $('a.unselect-all-media').on('click', function(event) {
      event.preventDefault();
      return $('input.media-selected').prop('checked', false);
    });
    return $('a.record').on('click', function(event) {
      var element, i, j, len, len1, mediaIds, ref, ref1;
      event.preventDefault();
      if ($('input.media-selected:checked').length === 0) {
        alert('Please select one or more media to record.');
        return false;
      }
      mediaIds = [];
      ref = $('input.media-selected:checked');
      for (i = 0, len = ref.length; i < len; i++) {
        element = ref[i];
        mediaIds.push($(element).data('media-id'));
      }
      ref1 = $('a.dial-number');
      for (j = 0, len1 = ref1.length; j < len1; j++) {
        element = ref1[j];
        element.href = updateQueryStringParameter(element.href, 'media', mediaIds);
      }
      return $('#record-modal').modal();
    });
  });

}).call(this);
