import Dropzone from 'dropzone'
Dropzone.autoDiscover = false

document.addEventListener('turbo:load', () => {
  const bodyClass = document.querySelector('body').classList
  if ((bodyClass.contains('assets') || 
       bodyClass.contains('photos') || 
       bodyClass.contains('links') || 
       bodyClass.contains('videos')) && bodyClass.contains('new')) {
    const assetsDropzone = new Dropzone('#assets-dropzone', {
      uploadMultiple: true,
      maxFilesize: 500,
      timeout: 300000,
      dictDefaultMessage: 'Drop files here (or click) to upload'
    })
    assetsDropzone.on('error', (file, errorMessage, xhr) => {
      console.dir(errorMessage)
    })
    assetsDropzone.on('queuecomplete', () => {
      Turbo.visit(`/clients/${currentClientSlug}/${model}`)
    })
  }

  $('body.photo_sets #photo_set_asset_ids').imagepicker({
    initialized: () => {
      window.setTimeout(() => {
        $('.image_picker_image').tooltip()
      }, 500)
    }
  })
})

// # import moxie from 'plupload/js/moxie'
// # import plupload from 'plupload/js/plupload.full.min'
// # import pluploadQueue from 'plupload/js/jquery.plupload.queue/jquery.plupload.queue'

// # document.addEventListener 'turbo:load', ->

// #   $body = $('body')

// #   $('body.photo_sets #photo_set_asset_ids').imagepicker
// #     initialized: () ->
// #       window.setTimeout ( ->
// #         $('.image_picker_image').tooltip()
// #       ), 500

// #   if currentClientSlug? &&
// #      ($body.hasClass('assets') || 
// #      $body.hasClass('photos') ||
// #      $body.hasClass('links') ||
// #      $body.hasClass('videos') ||
// #      $body.hasClass('merge_documents'))
// #     assetUploadUrl = "/clients/#{currentClientSlug}/#{model}?currentUser=#{currentUser}"

// #     $('#asset-uploader').pluploadQueue
// #       url: assetUploadUrl
// #       flash_swf_url: '/flash/Moxie.swf'
// #       silverlight_xap_url: '/flash/Moxie.xap'

// #     @assetUploader = $('#asset-uploader').pluploadQueue()
// #     if @assetUploader
// #       @assetUploader.bind 'UploadComplete', ->
// #         Turbo.visit assetUploadUrl
// #       @assetUploader.bind 'FilesAdded', ->
// #         this.setOption 'multipart_params',
// #           sets: $('input[type=checkbox]:checked').map(-> $(this).val()).get().join(',')
// #           type: model

// # $(document).on 'click', 'body.links a.set-default', (event) ->
// #   event.preventDefault()
// #   $.post(this.href, '_method=put', null, 'script')
