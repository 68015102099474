(function() {
  $(document).on('change', 'select#activity_goal_activity_type_id', function(event) {
    var title_fill;
    event.preventDefault();
    title_fill = $('#activity_goal_activity_type_id option:selected').text();
    return $('#activity_goal_title').val(title_fill);
  });

  $(document).on('change', 'select#user_goal_activity_type_id', function(event) {
    var title_fill;
    event.preventDefault();
    title_fill = $('#user_goal_activity_type_id option:selected').text();
    return $('#user_goal_title').val(title_fill);
  });

}).call(this);
