(function() {
  document.addEventListener('turbo:load', function() {
    var dateRangePickerCallback, dateRangePickerOptions, i, j, len, len1, months, ranges, ref, ref1;
    if ($('body').hasClass('reporting-dashboards')) {
      ranges = {
        'This Month': [moment().startOf('month'), moment().endOf('month')]
      };
      ref = [6, 5, 4, 3, 2, 1];
      for (i = 0, len = ref.length; i < len; i++) {
        months = ref[i];
        ranges[moment().add(months, 'months').format('MMMM YYYY')] = [moment().add(months, 'months').startOf('month'), moment().add(months, 'months').endOf('month')];
      }
      ref1 = [1, 2, 3, 4, 5, 6];
      for (j = 0, len1 = ref1.length; j < len1; j++) {
        months = ref1[j];
        ranges[moment().subtract(months, 'months').format('MMMM YYYY')] = [moment().subtract(months, 'months').startOf('month'), moment().subtract(months, 'months').endOf('month')];
      }
      dateRangePickerOptions = {
        clearClass: 'btn btn-default btn-sm',
        showDropdowns: true,
        ranges: ranges
      };
      dateRangePickerCallback = function(start, end) {
        var endDate, startDate;
        $('.date-range-monthly span').html(start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'));
        startDate = start.format('YYYY-MM-DD');
        endDate = end.format('YYYY-MM-DD');
        $('#booking_start_date').val(startDate);
        $('#booking_end_date').val(endDate);
        return $('#bookings_form').submit();
      };
      return $('.date-range-monthly').daterangepicker(dateRangePickerOptions, dateRangePickerCallback);
    }
  });

  $(document).on('click', 'body.reporting-dashboards .select-all ', function(e) {
    return $('.row.properties input[type="checkbox"]').prop('checked', true);
  });

  $(document).on('click', 'body.reporting-dashboards .select-none ', function(e) {
    return $('.row.properties input[type="checkbox"]').prop('checked', false);
  });

}).call(this);
