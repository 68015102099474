import React from 'react'

class GrcPropertyPicker extends React.Component {
  constructor() {
    super()
    this.propertySelected = this.propertySelected.bind(this)
  }
  propertySelected(event) {
    event.preventDefault()
    this.props.propertySelected(event.target.value)
  }
  render() {
    return (
      <select className="nochosen form-control" 
              onChange={this.propertySelected}
              defaultValue={this.props.property} 
              style={{width:'200px', float: 'left', marginRight: '5px'}}>
        {this.props.allProperties.map(function(property) {
          return <option key={property.id} value={property.id}>{property.name}</option>
        })}   
      </select>
    )
  }
}

export default GrcPropertyPicker