(function() {
  $(document).on('click', '.show-original-email', function() {
    return $('.original-email').toggle();
  });

  document.addEventListener('turbo:load', function() {
    var $body, doSearch, timer;
    $body = $('body');
    if ($body.hasClass('user_emails')) {
      timer = 0;
      doSearch = function() {
        if ($('#search_query').val().length) {
          return $.get('/user_emails?q=' + $('#search_query').val(), null, null, 'script');
        }
      };
      return $('#search_query').on('input propertychange', function(event) {
        timer && clearTimeout(timer);
        return timer = setTimeout(doSearch, 1000);
      });
    }
  });

}).call(this);
