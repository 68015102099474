import Rails from '@rails/ujs'
import { Controller } from '@hotwired/stimulus'
import throttle from 'lodash/throttle'

export default class extends Controller {
  static targets = ['deletedUsersToggle', 
                    'userCheckbox',
                    'tagCheckbox',
                    'accountStatusCheckbox',
                    'accountStatusesPanel',
                    'sidebar',
                    'filterForm',
                    'searchForm',
                    'searchInput',
                    'clearSearchButton',
                    'accountsList']

  connect() {
    this.currentUser = this.data.get('currentUser')
    this.searchInputTarget.addEventListener('keyup', throttle(this.listenForSearch, 3000).bind(this))
    this.searchInputTarget.addEventListener('keydown', event => {
      if (event.keyCode == 13) {
        event.preventDefault()
        return false
      } else if (event.keyCode == 27) {
        this.clearSearch()
      }
    })
    window.handleRowWithSidebarHeight()
  }

  unblockUI() {
    window.unblockUI()
  }

  listenForSearch(event) {
    this.query = event.target.value
    if (this.query.length == 0) {
      this.clearSearchButtonTarget.classList.add('hidden')
      this.fireSearchForm()
    } else if (this.query.length >= 3) {
      this.clearSearchButtonTarget.classList.remove('hidden')
      this.fireSearchForm()
    } else {
      this.clearSearchButtonTarget.classList.remove('hidden')
    }
  }

  clearSearch() {
    this.searchInputTarget.value = ''
    this.clearSearchButtonTarget.classList.add('hidden')
    this.fireSearchForm()
  }

  fireSearchForm() {
    window.pleaseWait()
    this.searchFormTarget.requestSubmit()
  }

  tagsUpdated() {
    const checked = this.tagCheckboxTargets.filter((element) => element.checked)
    console.log(checked)
    if (checked.length) {
      this.update()
    } else {
      this.clearTags()
    }
  }

  clearTags() {
    this.tagCheckboxTargets.forEach((element) => element.checked = false)
    const input = document.createElement("input")
    input.type = 'hidden'
    input.name = 'clear_tags'
    input.value = 'true'
    this.filterFormTarget.appendChild(input)
    this.update()
  }

  selectUserAll() {
    this.userCheckboxTargets.forEach((element) => element.checked = true)
    this.update()
  }

  selectUserMe() {
    this.userCheckboxTargets.forEach((element) => element.checked = false)
    document.querySelectorAll('input.user-me').forEach((element) => element.checked = true)
    this.update()
  }

  selectUserActive() {
    this.userCheckboxTargets.forEach((element) => element.checked = false)
    this.userCheckboxTargets.filter(checkbox => !checkbox.classList.contains('user-deleted'))
                            .forEach((element) => element.checked = true)
    this.update()
  }

  selectPropertyAll() {
    this.propertyCheckboxTargets.forEach((element) => element.checked = true)
    this.update()
  }

  selectPropertyNone() {
    this.propertyCheckboxTargets.forEach((element) => element.checked = false)
    this.update()
  }

  toggleDeletedUsers() {
    document.querySelectorAll('.checkbox.deleted').forEach((element) => {
      if (element.classList.contains('hidden')) {
        element.classList.remove('hidden')
        this.deletedUsersToggleTarget.innerHTML = '- Deleted'
      } else {
        element.classList.add('hidden')
        this.deletedUsersToggleTarget.innerHTML = '+ Deleted'
      }
    })
  }

  update() {
    window.pleaseWait()
    Rails.fire(this.filterFormTarget, 'submit')
  }
}