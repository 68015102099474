import moment from 'moment';

document.addEventListener('turbo:load', function() {
  var $pickupDate, $pickupDateLink, dateOptions;
  if ($('body').hasClass('pickups') || $('body').hasClass('daily_trackings')) {
    dateOptions = {
      changeMonth: true,
      changeYear: true,
      dateFormat: 'yy-mm-dd'
    };
    $pickupDate = $('#pickup_date');
    $pickupDateLink = $('#pickup_date_link');
    $pickupDateLink.on('click', function(event) {
      event.preventDefault();
      return $pickupDate.datepicker('show');
    });
    $pickupDate.datepicker($.extend({}, dateOptions, {
      onSelect: function(selectedDate) {
        return window.top.location.href = `/pickups?date=${moment(selectedDate).format('YYYY-MM-DD')}`;
      }
    }));
    return $('#daily_tracking_account_id').select2({
      ajax: {
        url: '/accounts-search.json',
        dataType: 'json',
        delay: 250,
        cache: true,
        data: function(params) {
          return {
            query: params.term
          };
        },
        processResults: function(data) {
          return {
            results: data
          };
        }
      },
      minimumInputLength: 2,
      escapeMarkup: function(markup) {
        return markup;
      },
      templateResult: function(account) {
        if (account.name) {
          return `<p><strong>${account.name}</strong><br/>${account.city_state}</p>`;
        }
      }
    });
  }
});
