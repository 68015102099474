import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = ["filterForm", "deactivated", "propertyName"]

  connect() {
    // Select2 and BootstrapToggle libraries depend on jQuery selectors.
    setTimeout(()=> {
      $("#products").select2({placeholder: "Select products...", allowClear: true})
      $('#products').on('change', () => this.submit())
      const bootstrapMpToggle = $('#multi_property')
      bootstrapMpToggle.change((e) => this.toggle_multi_property(e))
      bootstrapMpToggle.bootstrapToggle()
      const bootstrapCopiesToggle = $('#include_copies')
      bootstrapCopiesToggle.change(() => this.submit())
      bootstrapCopiesToggle.bootstrapToggle()
    })
  }

  search(e) {
    if (e.key === "Enter") { this.submit() }
  }

  show_active(e) {
    e.preventDefault()
    this.deactivatedTarget.value = ""
    this.submit()
  }

  show_inactive(e) {
    e.preventDefault()
    this.deactivatedTarget.value = "1"
    this.submit()
  }

  toggle_multi_property(e) {
    if (e.target.checked) {
      setTimeout(()=> {
        this.propertyNameTarget.classList.remove('hidden')
      }, 500)
    } else {
      this.propertyNameTarget.value = ""
      setTimeout(()=> {
        this.propertyNameTarget.classList.add('hidden')
      }, 500)
    }
    this.submit()
  }

  submit() {
    window.pleaseWait()
    this.filterFormTarget.requestSubmit()
  }

  unblockUI() {
    window.unblockUI();
  }
}
