import Rails from '@rails/ujs'
require('fabric')

window.diagramApp = {
  backgroundImage: '',
  canvas: null,
  clientId: null,
  diagramId: null,
  scaling: { x: 1.0, y: 1.0 },
  visibility: true,

  manageWrapperSize() {
    return $('.floor-plan-wrapper').css('height', $(window).height() - $('.navbar-top').height() - 70)
  },

  toggleVisible() {
    const visible = !this.visibility
    for (let obj of Array.from(this.canvas.getObjects())) {
      if (obj.meetingRoomId) {
        obj.set('fill', `rgba(255, 0, 0, ${visible ? .8 : 0})`)
        obj.selectable = visible
      }
    }
    this.canvas.discardActiveObject().renderAll()
    return this.visibility = visible
  },

  isolate(meetingRoomId) {
    let isolatedObject = null
    this.visibility = false
    this.toggleVisible()
    if (meetingRoomId !== '') {
      for (let obj of Array.from(window.diagramApp.canvas.getObjects())) {
        if (obj.meetingRoomId) {
          if (obj.meetingRoomId.toString() !== meetingRoomId.toString()) {
            obj.set('fill', "rgba(255, 0, 0, 0)")
            obj.selectable = false
          } else {
            isolatedObject = obj
          }
        }
      }
      this.canvas.discardActiveObject().renderAll()
    }
    // have to do it this way bringToFront() inside the loop is unstable
    if (isolatedObject) {
      return isolatedObject.bringToFront()
    }
  },

  drawCircle(circle) {
    const options = {
      radius: circle.radius,
      fill: 'rgba(255, 0, 0, .8)',
      left: circle.left,
      top: circle.top,
      hasRotatingPoint: false,
      lockUniScaling: true
    }
    circle = new fabric.Circle(options)
    circle.diagramMeetingRoomId = circle.id
    circle.meetingRoomId = circle.meeting_room_id
    circle.on('selected', function() {
      const url = `/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms/${this.diagramMeetingRoomId}/edit`
      return $.get(url, null, 'script')
    })
    return circle.on('modified', function() {
      const url = `/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms/${this.diagramMeetingRoomId}.json`
      const params = { 
        _method: 'patch',
        radius: this.getRadiusX(),
        top: this.top,
        left: this.left
      }
      return $.post(url, params)
    })
  },

  newCircle(radius) {
    const options = {
      radius,
      left: 50,
      top: 50
    }
    return $.post(`/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms.json`, options, function(data) {
      const circle = window.diagramApp.drawCircle(options)
      circle.diagramMeetingRoomId = data.id
      return window.diagramApp.canvas.add(circle)
    })
  },

  drawRect(rect) {
    const options = {
      fill: 'rgba(255, 0, 0, .8)',
      width: rect.width,
      height: rect.height,
      left: rect.left,
      top: rect.top,
      angle: rect.angle
    }
    rect = new fabric.Rect(options)
    rect.diagramMeetingRoomId = rect.id
    rect.meetingRoomId = rect.meeting_room_id
    rect.on('selected', function() {
      const url = `/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms/${this.diagramMeetingRoomId}/edit`
      return $.get(url, null, 'script')
    })
    return rect.on('modified', function() {
      const url = `/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms/${this.diagramMeetingRoomId}.json`
      const params = { 
        _method: 'patch',
        width: this.width * this.scaleX,
        height: this.height * this.scaleY,
        top: this.top,
        left: this.left,
        angle: this.angle
      }
      return $.post(url, params)
    })
  },

  newRect(width) {
    const options = {
      width,
      height: width,
      left: 50,
      top: 50,
      angle: 0
    }
    return $.post(`/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms.json`, options, function(data) {
      const rect = window.diagramApp.drawRect(options)
      rect.diagramMeetingRoomId = data.id
      return window.diagramApp.canvas.add(rect)
    })
  },

  duplicateSelected() {
    const c = window.diagramApp.canvas
    const selected = c.getActiveObject()
    if (selected) {
      let options
      if (selected.points) {
        // TODO polygon
      } else if (selected.radius) {
        options = {
          radius: selected.getRadiusX(),
          left: selected.left + 20,
          top: selected.top - 20
        }
        return $.post(`/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms.json`, options, function(data) {
          const circle = window.diagramApp.drawCircle(options)
          circle.diagramMeetingRoomId = data.id
          return window.diagramApp.canvas.add(circle)
        })
      } else {
        options = {
          width: selected.width * selected.scaleX,
          height: selected.height * selected.scaleY,
          left: selected.left + 20,
          top: selected.top - 20,
          angle: selected.angle
        }
        return $.post(`/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms.json`, options, function(data) {
          const rect = window.diagramApp.drawRect(options)
          rect.diagramMeetingRoomId = data.id
          return window.diagramApp.canvas.add(rect)
        })
      }
    }
  },

  deleteSelected() {
    const c = window.diagramApp.canvas
    const selected = c.getActiveObject()
    if (selected) {
      c.remove(selected)
      const url = `/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms/${selected.diagramMeetingRoomId}`
      const params =  { _method: 'delete' }
      return $.post(url, params, 'script')
    }
  }
}

// new shapes
$(document).on('click', 'body.nest-diagram_meeting_rooms .new-circle-5', () => window.diagramApp.newCircle(5))
$(document).on('click', 'body.nest-diagram_meeting_rooms .new-circle-10', () => window.diagramApp.newCircle(10))
$(document).on('click', 'body.nest-diagram_meeting_rooms .new-circle-20', () => window.diagramApp.newCircle(20))
$(document).on('click', 'body.nest-diagram_meeting_rooms .new-rect-20', () => window.diagramApp.newRect(20))
$(document).on('click', 'body.nest-diagram_meeting_rooms .new-polygon', () => window.polygonDrawing.startPolygon())

// duplicate shape
$(document).on('click', 'body.nest-diagram_meeting_rooms .copy-diagram-meeting-room', function(event) {
  event.preventDefault()
  return window.diagramApp.duplicateSelected()
})

// delete shape
$(document).on('click', 'body.nest-diagram_meeting_rooms .delete-diagram-meeting-room', function(event) {
  event.preventDefault()
  return window.diagramApp.deleteSelected()
})

// isolate shape
$(document).on('change', 'body.nest-diagram_meeting_rooms #isolate_meeting_room', function(event) {
  return window.diagramApp.isolate($(this).val())
})

// toggle visibility of shapes
$(document).on('click', 'body.nest-diagram_meeting_rooms .toggle-visible-diagram-meeting-room', function(event) {
  event.preventDefault()
  $('#isolate_meeting_room').val("") // no isolate mode
  return window.diagramApp.toggleVisible()
})

// capture backspace/delete key for shape delete
$(document).on('keydown', function(e) {
  if ($('body').hasClass('nest-diagram_meeting_rooms')) {
    if ((e.which === 8) || (e.which === 46)) {
      return window.diagramApp.deleteSelected()
    } else if ((e.which === 68) || (e.which === 69)) {
      return window.diagramApp.duplicateSelected()
    } else if (e.which === 80) {
      return window.polygonDrawing.startPolygon()
    }
  }
})

// submit form on select change
$(document).on('change', 'body.nest-diagram_meeting_rooms #diagram_meeting_room_meeting_room_id', function() {
  const selected = window.diagramApp.canvas.getActiveObject()
  selected.meetingRoomId = $(this).val()
  return Rails.fire(document.querySelector('#dmr-form'), 'submit')
})

// resize the window, change the well height
$(window).resize(() => window.diagramApp.manageWrapperSize())

document.addEventListener('turbo:load', function() {
  if ($('body').hasClass('nest-diagram_meeting_rooms')) {
    window.diagramApp.manageWrapperSize()

    window.diagramApp.canvas = new fabric.Canvas('diagram-canvas')
    const c = window.diagramApp.canvas
    c.selection = false // no group selection
    c.setBackgroundImage(window.diagramApp.backgroundImage, c.renderAll.bind(c), {
      originX: 'left',
      originY: 'top',
      scaleX: window.diagramApp.scaling.x,
      scaleY: window.diagramApp.scaling.x
    }
    )
    return $.get(`/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms.json`, data => (() => {
      const result = []
      for (let existingShape of Array.from(data)) {
        var shape
        if (existingShape.points) { // polygon
          shape = window.polygonDrawing.drawPolygon(existingShape)
          window.lastPolygon = shape
        } else if (existingShape.radius) { // circle
          shape = window.diagramApp.drawCircle(existingShape)
          window.diagramApp.canvas.add(shape)
        } else { // rectangle
          shape = window.diagramApp.drawRect(existingShape)
          window.diagramApp.canvas.add(shape)
        }
        shape.diagramMeetingRoomId = existingShape.id
        result.push(shape.meetingRoomId = existingShape.meeting_room_id)
      }
      return result
    })())
  }
})
