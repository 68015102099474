(function() {
  document.addEventListener('turbo:load', function() {
    return $(document).on('change', '#rfp_form_field_name', function() {
      var $this;
      $this = $(this);
      return $.get('/rfp_form_fields.json', function(data) {
        var field, i;
        field = ((function() {
          var j, len, results;
          results = [];
          for (j = 0, len = data.length; j < len; j++) {
            i = data[j];
            if (i.name === $this.val()) {
              results.push(i);
            }
          }
          return results;
        })())[0];
        $('#rfp_form_field_label').val(field.label);
        return $('#rfp_form_field_hint').val(field.hint);
      });
    });
  });

}).call(this);
