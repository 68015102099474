$(document).on('turbo:load', () => {
  if ($('body').hasClass('document-preview')) {
    if (/iPhone|iPod|iPad/.test(navigator.userAgent)) {
      $('#iframe').css({
        width: $(this).attr('width'),
        height: $(this).attr('height'),
        overflow: 'auto',
        '-webkit-overflow-scrolling': 'touch'
      });
    }
  }
})