(function() {
  window.polygonDrawing = {
    polygonMode: false,
    pointArray: [],
    lineArray: [],
    activeLine: null,
    activeShape: false,
    canvas: null,
    abortPolygon: function() {
      var pd;
      $(document).off('keyup');
      pd = window.polygonDrawing;
      if (pd.polygonMode) {
        $.each(pd.pointArray, function(index, point) {
          return pd.canvas.remove(point);
        });
        $.each(pd.lineArray, function(index, line) {
          return pd.canvas.remove(line);
        });
        pd.canvas.remove(pd.activeShape).remove(pd.activeLine);
      }
      pd.activeLine = null;
      pd.activeShape = null;
      pd.polygonMode = false;
      return pd.canvas.selection = false;
    },
    startPolygon: function() {
      var pd;
      pd = window.polygonDrawing;
      pd.canvas = window.diagramApp.canvas;
      window.cursor = 'crosshair';
      pd.polygonMode = true;
      $(document).on('keyup', (event) => {
        pd = window.polygonDrawing;
        if (pd.polygonMode) {
          if (event.which === 27) {
            pd.abortPolygon();
          }
          if (event.which === 13) {
            return pd.finishPolygon();
          }
        }
      });
      pd.canvas.on('mouse:down', (options) => {
        pd = window.polygonDrawing;
        if (pd.polygonMode) {
          return pd.addPointToPolygon(options);
        }
      });
      return pd.canvas.on('mouse:move', (options) => {
        pd = window.polygonDrawing;
        if (pd.polygonMode && pd.activeLine && pd.activeLine.class === "line") {
          return pd.drawRubberBandLine(options);
        }
      });
    },
    drawCircleAtPoint: function(options) {
      var circle, pd;
      pd = window.polygonDrawing;
      circle = new fabric.Circle({
        objectCaching: false,
        radius: 5,
        fill: '#ffffff',
        stroke: '#333333',
        strokeWidth: 0.5,
        left: options.e.layerX / pd.canvas.getZoom(),
        top: options.e.layerY / pd.canvas.getZoom(),
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: 'center',
        originY: 'center'
      });
      if (pd.pointArray.length === 0) {
        circle.set({
          fill: 'rgba(255, 0, 0, .8)'
        });
      }
      return circle;
    },
    drawLineToPoint: function(options) {
      var line, pd, points;
      pd = window.polygonDrawing;
      points = [options.e.layerX / pd.canvas.getZoom(), options.e.layerY / pd.canvas.getZoom(), options.e.layerX / pd.canvas.getZoom(), options.e.layerY / pd.canvas.getZoom()];
      line = new fabric.Line(points, {
        strokeWidth: 2,
        fill: '#999999',
        stroke: '#ff0000',
        class: 'line',
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasBorders: false,
        // hasControls: false
        evented: false,
        objectCaching: false
      });
      return line;
    },
    updatePolygonDefinition: function(options) {
      var pd, points, polyPoint, polygon, pos;
      pd = window.polygonDrawing;
      if (pd.activeShape) {
        pos = pd.canvas.getPointer(options.e);
        points = pd.activeShape.get("points");
        points.push({
          x: pos.x,
          y: pos.y
        });
        polygon = new fabric.Polygon(points, {
          stroke: '#ff0000',
          strokeWidth: 1,
          fill: '#cccccc',
          opacity: 0.3,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          objectCaching: false
        });
        pd.canvas.remove(pd.activeShape);
        pd.canvas.add(polygon);
        pd.activeShape = polygon;
        pd.canvas.renderAll();
      } else {
        polyPoint = [
          {
            x: options.e.layerX / pd.canvas.getZoom(),
            y: options.e.layerY / pd.canvas.getZoom()
          }
        ];
        polygon = new fabric.Polygon(polyPoint, {
          stroke: '#333333',
          strokeWidth: 1,
          fill: '#cccccc',
          opacity: 0.3,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          objectCaching: false
        });
        pd.activeShape = polygon;
        pd.canvas.add(polygon);
      }
      return polygon;
    },
    addPoint: function(options) {
      var circle, line, pd, polygon;
      pd = window.polygonDrawing;
      circle = pd.drawCircleAtPoint(options);
      line = pd.drawLineToPoint(options);
      polygon = pd.updatePolygonDefinition(options);
      pd.activeLine = line;
      pd.pointArray.push(circle);
      pd.lineArray.push(line);
      pd.canvas.add(line);
      pd.canvas.add(circle);
      return pd.canvas.selection = false;
    },
    drawPolygon: function(data) {
      var pd, polygon;
      pd = window.polygonDrawing;
      pd.canvas = window.diagramApp.canvas;
      polygon = new fabric.Polygon(JSON.parse(data.points), {
        strokeWidth: 0,
        fill: 'rgba(255, 0, 0, .8)',
        opacity: 1,
        hasBorders: false,
        hasControls: true,
        top: data.top,
        left: data.left,
        selectable: true
      });
      polygon.diagramMeetingRoomId = data.id;
      pd.canvas.add(polygon);
      polygon.on('selected', function() {
        var url;
        url = `/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms/${this.diagramMeetingRoomId}/edit`;
        return $.get(url, null, 'script');
      });
      return polygon.on('modified', function() {
        var params, url;
        console.log(this);
        url = `/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms/${this.diagramMeetingRoomId}.json`;
        params = {
          _method: 'patch',
          top: this.top,
          left: this.left,
          points: this.points
        };
        return $.post(url, params);
      });
    },
    finishPolygon: function() {
      var pd, points, polygon, url;
      pd = window.polygonDrawing;
      points = [];
      $.each(pd.pointArray, function(index, point) {
        points.push({
          x: point.left,
          y: point.top
        });
        return pd.canvas.remove(point);
      });
      $.each(pd.lineArray, function(index, line) {
        return pd.canvas.remove(line);
      });
      pd.canvas.remove(pd.activeShape).remove(pd.activeLine);
      polygon = new fabric.Polygon(points, {
        strokeWidth: 0,
        fill: 'rgba(255, 0, 0, .8)',
        opacity: 1,
        hasBorders: false,
        selectable: true,
        hasControls: true
      });
      pd.canvas.add(polygon);
      pd.activeLine = null;
      pd.activeShape = false;
      pd.polygonMode = false;
      pd.pointArray = [];
      pd.lineArray = [];
      pd.canvas.selection = false;
      polygon.on('selected', function() {
        var url;
        url = `/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms/${this.diagramMeetingRoomId}/edit`;
        return $.get(url, null, 'script');
      });
      url = `/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms.json`;
      return $.post(url, {
        top: polygon.top,
        left: polygon.left,
        points: points
      }, function(data) {
        console.log("Received: ", data);
        polygon.diagramMeetingRoomId = data.id;
        pd.canvas.setActiveObject(polygon);
        url = `/nest/clients/${window.diagramApp.clientId}/diagrams/${window.diagramApp.diagramId}/diagram_meeting_rooms/${polygon.diagramMeetingRoomId}/edit`;
        return $.get(url, null, 'script');
      });
    },
    drawRubberBandLine: function(options) {
      var pd, pointer, points;
      pd = window.polygonDrawing;
      pointer = pd.canvas.getPointer(options.e);
      pd.activeLine.set({
        x2: pointer.x,
        y2: pointer.y
      });
      points = pd.activeShape.get("points");
      points[pd.pointArray.length] = {
        x: pointer.x,
        y: pointer.y
      };
      pd.activeShape.set({
        points: points
      });
      return pd.canvas.renderAll();
    },
    addPointToPolygon: function(options) {
      var pd;
      pd = window.polygonDrawing;
      if (options.target && options.target.id === pd.pointArray[0].id) {
        return pd.finishPolygon();
      } else {
        return pd.addPoint(options);
      }
    }
  };

}).call(this);
