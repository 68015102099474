import moment from 'moment'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

window.grcDaysApp = { 
  currentDate: null,
  dateSelected: false,
  tabSelected: '',
  cellEls: []
}

$(document).on('click', '#calendarView .nav-tabs li a', function(event) {
  return window.grcDaysApp.tabSelected = $(this).attr('href')
})

document.addEventListener('turbo:load', function() {
  if ($('body').hasClass('grc_days')) {  
    // fullcalendar
    const calendarEl = document.querySelector('.grc-days-calendar')
    window.grcDaysApp.calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      initialDate: window.grcDaysApp.currentDate,
      selectable: true,
      unselectAuto: false,
      eventTimeFormat: window.calendarTimeFormatEvent,
      select(info) {
        if (window.grcDaysApp.dateSelected) {
          return window.grcDaysApp.dateSelected = false
        } else {
          window.top.location.href = `/grc_days?date=${info.startStr}${window.grcDaysApp.tabSelected}`
          return window.grcDaysApp.dateSelected = true
        }
      },
      dayCellDidMount: (info) => {
        window.grcDaysApp.cellEls.push(info)
      },
      datesSet: async (info) => {
        const view = info.view
        const start = view.activeStart
        const end = view.activeEnd

        window.grcDaysApp.cellEls = window.grcDaysApp
                                          .cellEls
                                          .filter(cell => cell.date >= start && cell.date <= end)
        const response = await fetch(`/grc_allocations.json?start=${moment(start).format('YYYY-MM-DD')}&end=${moment(end).format('YYYY-MM-DD')}`)
        const data = await response.json()
        for (let date in data) {
          // allocations
          if (data[date].quantity && data[date].quantity > 0) {
            const cell = window.grcDaysApp.cellEls.find(cell => moment(cell.date).format('YYYY-MM-DD') === date)
            if (cell) {
              cell.el.classList.add('allocated')
              cell.el.querySelector('.fc-daygrid-day-bg').innerHTML = `<span class=\"allocation\">${data[date].quantity}</span>`
            }
          }
          // hot date
          if (data[date].hotDate) {
            const cell = window.grcDaysApp.cellEls.find(cell => moment(cell.date).format('YYYY-MM-DD') === date)
            if (cell) {
              cell.el.classList.add('hot')
            }
          }
          // cold date
          if (data[date].coldDate) {
            const cell = window.grcDaysApp.cellEls.find(cell => moment(cell.date).format('YYYY-MM-DD') === date)
            if (cell) {
              cell.el.classList.add('cold')
            }
          }
        }

        // selected date
        let urlParams = new URLSearchParams(window.location.search);
        const cell_selected = window.grcDaysApp.cellEls.find(cell => moment(cell.date).format('YYYY-MM-DD') === urlParams.get('date'))
        if (cell_selected) {
          cell_selected.el.classList.add('fc-selected')
        }
      }
    })
    window.grcDaysApp.calendar.render()
    
    // sticky tab
    $(`#calendarView .nav-tabs li a[href='${window.location.hash}']`).tab('show')

    // date range picker
    const grcDaysdateRangePickerOptions = {
      clearClass: 'btn btn-default btn-sm',
      showDropdowns: true,
      ranges: {
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'This Year': [moment().startOf('year'), moment().endOf('year')]
      }
    }
    
    const grcDaysdateRangePickerCallback = (start, end) => {
      const text = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')
      $('.grc-days-date-range span').html(text)
      const startDate = start.format('YYYY-MM-DD')
      const endDate = end.format('YYYY-MM-DD')
      $('#grc_day_start_date').val(startDate)
      return $('#grc_day_end_date').val(endDate)
    }

    return $('.grc-days-date-range').daterangepicker(grcDaysdateRangePickerOptions, grcDaysdateRangePickerCallback)
    
  }
})
