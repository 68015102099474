import React from 'react'
import moment from 'moment'
import { Table, Column } from 'fixed-data-table-2'
import { GrcBookingCurrencyCell, GrcBookingDateCell, GrcBookingNameCell, GrcBookingPmsCell, GrcBookingQuantityCell, GrcBookingTextCell } from './GrcCell'

class GrcDataTable extends React.Component {
  constructor() {
    super();
    this.state = { 
      dateRange: [],
      month: null,
      tableWidth: $(window).width() - 70,
      tableHeight: $(window).height() - 170
    };
    this.getRange = this.getRange.bind(this);
    this.renderQuantityCell = this.renderQuantityCell.bind(this);
    this.rowNameGetter = this.rowNameGetter.bind(this);
  }

  getRange(month) {
    const start = moment(month).startOf('month');
    const end = moment(month).endOf('month');
    let range = [];
    for (let m = moment(start); m.isBefore(end); m.add(1, 'days')) {
      range.push(moment(m));
    }
    return range;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ dateRange: this.getRange(nextProps.month) });
  }

  componentDidMount() {
    $(window).resize(function() {
      this.setState({ 
        tableWidth: $(window).width() - 70,
        tableHeight: $(window).height() - 170
      })
    }.bind(this));
  }

  formatGrcDate(date, hotDates, coldDates) {
    const d = date.format('YYYY-MM-DD');
    if (d in hotDates) {
      return (
        <a href="#" onClick={(e) => e.preventDefault()} className="hotDate" data-container="body" data-toggle="popover" data-placement="bottom" data-trigger="focus" data-content={hotDates[d].join(', ')}>
          {date.format('ddd')}
          <br />
          {date.format('MM/DD')}
        </a>
      );
    }
    else if (d in coldDates) {
      return (
        <a href="#" onClick={(e) => e.preventDefault()} className="coldDate" data-container="body" data-toggle="popover" data-placement="bottom" data-trigger="focus" data-content={coldDates[d].join(', ')}>
          {date.format('ddd')}
          <br />
          {date.format('MM/DD')}
        </a>
      );
    }
    else {
      return (
        <span>
          {date.format('ddd')}
          <br/>
          {date.format('MM/DD')}
        </span>
      );
    }
  }

  rowNameGetter(index) {
    const row = this.props.bookings[index];
    if (row.id === undefined) {
      return 'even'
    };
    return ((index / this.props.view.length) % 2) >= 1 ? 'odd' : 'even';
  }

  renderQuantityCell(date) {
    return (
      <Column key={date.format('YYYY-MM-DD')}
              header={() => this.formatGrcDate(date, this.props.hotDates, this.props.coldDates)} 
              align="center"
              cell={<GrcBookingQuantityCell bookings={this.props.bookings} field={date.format('YYYY-MM-DD')} />} 
              width={40} />
    );
  }

  render() {
    return (
      <Table
        rowsCount={this.props.bookings.length}
        rowHeight={30}
        headerHeight={32}
        width={this.state.tableWidth}
        height={this.state.tableHeight}
        rowClassNameGetter={this.rowNameGetter}>
        <Column
          header="View"
          cell={<GrcBookingTextCell bookings={this.props.bookings} field="view" />}
          width={45}
          fixed={true}
          allowCellsRecycling={true}
        />
        <Column
          header="Name"
          cell={<GrcBookingNameCell bookings={this.props.bookings} />}
          width={250}
          fixed={true}
          allowCellsRecycling={true}
        />
        <Column
          header="Status"
          cell={<GrcBookingTextCell bookings={this.props.bookings} field="status" />}
          width={70}
          fixed={true}
          allowCellsRecycling={true}
        />
        {this.props.pmsIntegrated &&
          <Column
            header="PMS"
            cell={<GrcBookingPmsCell bookings={this.props.bookings} />}
            width={40}
            fixed={true}
            allowCellsRecycling={true}
          />
        }
        <Column
          header="Booked"
          cell={<GrcBookingDateCell bookings={this.props.bookings} field="booked" />}
          width={75}
          fixed={true}
          allowCellsRecycling={true}
        />
        <Column
          header="Cut-Off"
          cell={<GrcBookingDateCell bookings={this.props.bookings} field="cutoff" />}
          width={75}
          fixed={true}
          allowCellsRecycling={true}
        />
        <Column
          header="By"
          cell={<GrcBookingTextCell bookings={this.props.bookings} field="user_name" />}
          width={75}
          fixed={true}
          allowCellsRecycling={true}
        />
        <Column
          header="Revenue"
          cell={<GrcBookingCurrencyCell bookings={this.props.bookings} field="rooms_revenue" />}
          width={90}
          fixed={true}
          allowCellsRecycling={true}
        />
        <Column
          header="ADR"
          cell={<GrcBookingCurrencyCell bookings={this.props.bookings} field="adr" />}
          width={55}
          fixed={true}
          allowCellsRecycling={true}
        />
        <Column
          header="Nights"
          cell={<GrcBookingTextCell bookings={this.props.bookings} field="nights" />}
          width={50}
          fixed={true}
          allowCellsRecycling={true}
        />
        {this.state.dateRange.map(this.renderQuantityCell)}
      </Table>
    )
  }
}

export default GrcDataTable