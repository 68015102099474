(function() {
  document.addEventListener('turbo:load', function() {
    if ($('#document_status').val() !== 'lost') {
      $('div.document_lost_reason').hide();
    }
    return $(document).on('change', '#document_status', function(event) {
      var $lost, status;
      status = $(this).val();
      $lost = $('div.document_lost_reason');
      if (status === 'lost') {
        return $lost.show();
      } else {
        return $lost.hide();
      }
    });
  });

}).call(this);
