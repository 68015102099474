import React from 'react'
class BookingsNavbar extends React.Component {
  constructor() {
    super()
    this.completedClicked = this.completedClicked.bind(this)
    this.draftsClicked = this.draftsClicked.bind(this)
    this.searchClicked = this.searchClicked.bind(this)
  }

  completedClicked() {
    this.props.onOwnershipChanged('all')
  }

  draftsClicked() {
    this.props.onOwnershipChanged('drafts')
  }

  searchClicked() {
    this.props.onOwnershipChanged('search')
  }

  render() {
    let draftsButtonText = "Drafts"
    if (this.props.draftsCount > 0) {
      draftsButtonText = (
        <span>
          Drafts&nbsp;
          <span className="badge">{this.props.draftsCount}</span>
        </span>
      )
    }
    return (
      <div className="navbar navbar-secondary navbar-default navbar-fixed-top" role="navigation">
        <div className="container-fluid">
          <ul className="nav navbar-nav navbar-left">
            <li role="presentation">
              <a ref="allButton" onClick={this.completedClicked} className={this.props.ownership == 'all' ? 'active' : ''} aria-controls="all-bookings" data-toggle="tab" href="#all" role="tab">
                Completed
              </a>
            </li>
            <li role="presentation">
              <a ref="draftsButton" onClick={this.draftsClicked} className={this.props.ownership == 'drafts' ? 'active' : ''} aria-controls="drafts" data-toggle="tab" href="#drafts" role="tab">
                {draftsButtonText}
              </a>
            </li>
            <li role="presentation">
              <a ref="searchButton" onClick={this.searchClicked} className={this.props.ownership == 'search' ? 'active' : ''} aria-controls="all-bookings" data-toggle="tab" href="#all" role="tab">
                Search
              </a>
            </li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
            <li role="presentation">
              <div className="navbar-btn">
                <a className="btn btn-success" href="/bookings/new">
                  <i className="fa fa-plus"></i>
                  &nbsp;New Booking
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default BookingsNavbar