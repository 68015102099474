import flatpickr from 'flatpickr';

document.addEventListener('turbo:load', function() {
  var DueByDatePicker, classList, endDatePicker, startDatePicker;
  classList = document.querySelector('body').classList;
  if (classList.contains('volume_contracts')) {
    if (classList.contains('new') || classList.contains('edit')) {
      endDatePicker = flatpickr('#volume_contract_end_date', {
        altInput: true,
        altFormat: 'l, F j, Y',
        dateFormat: 'Y-m-d',
        minDate: document.querySelector('#volume_contract_start_date').value
      });
      startDatePicker = flatpickr('#volume_contract_start_date', {
        altInput: true,
        altFormat: 'l, F j, Y',
        dateFormat: 'Y-m-d',
        onChange: function(selectedDates, selectedDate) {
          endDatePicker.setDate(selectedDate);
          return endDatePicker.set('minDate', selectedDate);
        }
      });
      DueByDatePicker = flatpickr('#volume_contract_due_by_date', {
        altInput: true,
        altFormat: 'l, F j, Y',
        dateFormat: 'Y-m-d'
      });
      flatpickr('#volume_contract_contracted_on', {
        altInput: true,
        altFormat: 'l, F j, Y',
        dateFormat: 'Y-m-d'
      });
    }
    return window.initTinyMCEFull();
  }
});
