import { Controller } from '@hotwired/stimulus'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

export default class extends Controller {
  static targets = ['calendar']

  connect() {
    this.calendar = new Calendar(this.calendarTarget, {
      navLinks: false,
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialView: 'timeGridWeek',
      timeZone: window.timeZone,
      height: 700,
      editable: false,
      selectable: false,
      headerToolbar: {
        start: 'title',
        center: 'prev,next today',
        end: 'timeGridDay,timeGridWeek'
      },
      buttonText: {
        today:    'Today',
        month:    'Month',
        week:     'Week',
        day:      'Day'
      },
      events: {
        url: `/events.json?user=${currentUser}`
      },
      eventClick: (info) => {
        console.log(info)
        if (info.event.id) {
          if (info.event.extendedProps.booking) {
            window.top.location.href = `/bookings/${info.event.id}/edit/general`
          } else if (info.event.extendedProps.appointment) {
            window.top.location.href = `/activities/${info.event.id}`
          } else {
            window.top.location.href = `/events/${info.event.id}/edit`
          }
        }
      },
      dateClick: (info) => {
        const dateString = info.dateStr
        if (dateString.includes('T')) {
          window.top.location.href = `/activities/new?dt=${dateString}`
        }
      }
    })
    this.calendar.render()
  }
}