import React from 'react'
import moment from 'moment'
import GrcPropertyPicker from './grc/GrcPropertyPicker'
import GrcMonthPicker from './grc/GrcMonthPicker'
import GrcLegend from './grc/GrcLegend'
import GrcGrossNetSwitch from './grc/GrcGrossNetSwitch'
import GrcDataTable from './grc/GrcDataTable'

class Grc extends React.PureComponent {
  constructor(props) {
    super()
    this.state = {
      allProperties: props.allProperties,
      property: props.initialProperty,
      month: props.initialMonth,
      bookings: [],
      hotDates: [],
      coldDates: [],
      rooms: [],
      view: props.initialView,
      pmsIntegrated: props.pmsIntegrated
    }
    this.handleRequestResponse = this.handleRequestResponse.bind(this)
    this.changeMonth = this.changeMonth.bind(this)
    this.monthChanged = this.monthChanged.bind(this)
    this.propertySelected = this.propertySelected.bind(this)
    this.grossNetSwitched = this.grossNetSwitched.bind(this)
  }

  componentDidMount() {
    this.handleRequestResponse()
  }

  monthChanged(selectedDate) {
    this.setState({
      month: moment(selectedDate).format('YYYY-MM')
    }, this.handleRequestResponse)
  }

  changeMonth(quantity) {
    this.setState({
      month: moment(this.state.month).add(quantity, 'months').format('YYYY-MM')
    }, this.handleRequestResponse)
  }

  grossNetSwitched(view) {
    let newView = this.state.view.slice()
    const index = newView.indexOf(view)
    if (index > -1) {
      newView.splice(index, 1)
    } else {
      newView.push(view)
    }
    this.setState({
      view: newView
    }, this.handleRequestResponse)
  }

  propertySelected(property) {
    this.setState({
      property: parseInt(property)
    }, this.handleRequestResponse)
  }

  handleRequestResponse() {
    let url = `/booking_rooms.json?view=${this.state.view}&month=${this.state.month}&property=${this.state.property}`
    window.pleaseWait()
    $.getJSON(url, function(data) {
      this.setState({ bookings: data.bookings, hotDates: data.hot_dates, coldDates: data.cold_dates })
      $.unblockUI()
      $('.hotDate').popover()
      $('.coldDate').popover()
    }.bind(this))
  }

  render() {
    return (
      <div>
        <div className="row grc-nav">
          <div className="col-sm-12">
            {this.state.property && <GrcPropertyPicker allProperties={this.state.allProperties}
                               property={this.state.property}
                               propertySelected={this.propertySelected} />}
            <GrcMonthPicker month={this.state.month} 
                            changeMonth={this.changeMonth}
                            monthChanged={this.monthChanged} />
            <GrcLegend
              hotDates={this.state.hotDates}
              coldDates={this.state.coldDates} />
            <div className="pull-right">
              <GrcGrossNetSwitch grossNetSwitched={this.grossNetSwitched} view={this.state.view} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 table-container">
            <GrcDataTable bookings={this.state.bookings} 
                          hotDates={this.state.hotDates}
                          coldDates={this.state.coldDates}
                          month={this.state.month}
                          view={this.state.view}
                          pmsIntegrated={this.state.pmsIntegrated} />
          </div>
        </div>
      </div>
    )
  }
}

export default Grc