import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
require('../../../src/jquery.monthpicker')
class GrcMonthPicker extends React.Component {
  componentDidMount() {
    const $this = $(ReactDOM.findDOMNode(this.refs.monthPicker))
    $this.MonthPicker({
      MonthFormat: 'MM yy',
      OnAfterChooseMonth: (selectedDate) => {
        this.props.monthChanged(selectedDate)
      }
    })
  }
  render() {
    return (
      <div className="pull-left">
        <div className="form-inline">
          <input ref="monthPicker"
                 type="text" 
                 placeholder="Select a month..." 
                 value={moment(this.props.month).format('MMMM YYYY')}
                 readOnly={true}
                 className="form-control" />
          &nbsp;
          <button type="button" onClick={() => this.props.changeMonth(-1)} className="btn btn-sm btn-primary">
            <i className="fa fa-arrow-left"></i>
          </button>
          &nbsp;
          <button type="button" onClick={() => this.props.changeMonth(1)} className="btn btn-sm btn-primary">
            <i className="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
    )
  }
}

export default GrcMonthPicker