(function() {
  document.addEventListener('turbo:load', function() {
    return $('body.meeting_rooms .asset-picker, body.nest-meeting_rooms .asset-picker').imagepicker({
      initialized: function() {
        return window.setTimeout((function() {
          return $('.image_picker_image').tooltip();
        }), 500);
      },
      clicked: function(select, event) {
        var dom_id, value;
        event.preventDefault();
        value = select.option.val();
        dom_id = ".asset-picker option[value=" + value + "]";
        if (event.target.parentElement.className.includes('selected')) {
          $(dom_id).prop('selected', true);
        } else {
          $(dom_id).prop("selected", false);
        }
        return $(".asset-picker").each(function() {
          return $(this).data('picker').sync_picker_with_select();
        });
      }
    });
  });

}).call(this);
