import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = ["pmsSelect", "pmsInputs", "pmsIdInput"]

  static values = {inputsBase: String}

  connect() {
    $(this.pmsSelectTarget).on('change', function(e) {
      e.preventDefault()
      if (this.controlChange) {
        this.controlChange = false
      } else {
        this.togglePmsInputs()
      }
    }.bind(this))
    this.loadedPmsValue = this.pmsSelectTarget.dataset.cachedValue
    this.pmsValue = this.loadedPmsValue
    this.pmsId = this.pmsIdInputTarget.value
  }

  togglePmsInputs() {
    if (this.pmsSelectTarget.value === this.pmsValue) {
      return
    }

    const prevPms = this.pmsValue
    this.pmsValue = this.pmsSelectTarget.value
    if (typeof(this.loadedPmsValue) !== "undefined" && this.loadedPmsValue !== "") {
      if (this.pmsValue === this.loadedPmsValue) {
        this.pmsIdInputTarget.value = this.pmsId
        if (typeof(this.removePms) !== "undefined") {
          this.element.removeChild(this.element.lastChild)
        }
        this.removePms = undefined
      } else if (this.pmsValue === "") {
        if (confirm("Clearing the PMS interface will result in the removal of all " +
          "current PMS values upon updating the client.")) {
          this.removePms = document.createElement("input")
          this.removePms.type = "hidden"
          this.removePms.name = "client[pms_attributes][_destroy]"
          this.removePms.value = "1"
          this.element.appendChild(this.removePms)
          this.pmsIdInputTarget.value = this.pmsId
        } else {
          this.restore_value(prevPms)
          return
        }
      } else {
        if (confirm("You have selected a different PMS from the current interface. " +
          "This will result in the removal of the previous/current PMS values.")) {
          this.pmsIdInputTarget.value = ""
        } else {
          this.restore_value(prevPms)
          return
        }
      }
    }
    this.pmsInputsTarget.src = `${this.inputsBaseValue}&type=${this.pmsSelectTarget.value}`
  }

  restore_value(prevValue) {
    this.pmsValue = this.loadedPmsValue
    this.controlChange = true
    $(this.pmsSelectTarget).val(prevValue).trigger('change')
  }

  retry_remote_fetch(e) {
    this.pmsInputsTarget.src = e.target.dataset.url
  }
}
