import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['quantity', 
    'expect', 
    'rate', 
    'moveFromRoomTypeId', 
    'moveFromRoomTypeTitle']

  move(event) {
    event.preventDefault()
    this.moveFromRoomTypeIdTarget.value = event.params.roomTypeId
    this.moveFromRoomTypeTitleTarget.innerText = event.params.roomTypeDescription
    $('#move-room-type-modal').modal('show')
  }

  copyAcross(event) {
    event.preventDefault()
    const roomTypeId = event.params.roomTypeId
    const quantities = this.quantityTargets.filter((target) => { return target.dataset.roomTypeId == roomTypeId })
    const expects = this.expectTargets.filter((target) => { return target.dataset.roomTypeId == roomTypeId })
    const rates = this.rateTargets.filter((target) => { return target.dataset.roomTypeId == roomTypeId })
    
    const firstQuantityIndex = quantities.findIndex((target) => { 
      return target.value != ''
    })

    if (firstQuantityIndex > -1) {
      quantities.forEach((target, index) => {
        if (index !== quantities.length - 1 && index > firstQuantityIndex) {
          target.value = quantities[firstQuantityIndex].value
        }
      })
      expects.forEach((target, index) => {
        if (index !== expects.length - 1 && index > firstQuantityIndex) {
          target.value = expects[firstQuantityIndex].value
        }
      })
      rates.forEach((target, index) => {
        if (index !== rates.length - 1) {
          target.value = rates[0].value
        }
      })
      window.bookingApp.handleSavedState(false)
    }
  }

  clear(event) {
    event.preventDefault()
    const roomTypeId = event.params.roomTypeId
    const quantities = this.quantityTargets.filter((target) => { return target.dataset.roomTypeId == roomTypeId })
    const expects = this.expectTargets.filter((target) => { return target.dataset.roomTypeId == roomTypeId })
    const rates = this.rateTargets.filter((target) => { return target.dataset.roomTypeId == roomTypeId })
    quantities.forEach((target) => { target.value = '' })
    expects.forEach((target) => { target.value = '' })
    rates.forEach((target) => { target.value = '' })
    window.bookingApp.handleSavedState(false)
  }
}