import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static targets = ["statusSelect", "confirmLost", "reasonSelect"];

  connect() {
    const nativeEvent = function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    }
    $(this.statusSelectTarget).on('select2:select', nativeEvent);
    $(this.reasonSelectTarget).on('change', function(e) {
      this.save_reason(e)
    }.bind(this))
  }

  // handle booking status change and modal for auto traces
  status_change(e) {
    let confirmed, formValue
    this.originalValue = window.bookingApp.status;
    formValue = this.statusSelectTarget.value;
    if (formValue !== this.originalValue) {
      if (formValue === 'lost' && (this.originalValue === 'prospect' || this.originalValue === 'tentative' || this.originalValue === 'definite')) {
        $('div#lost-business-reason').modal('show')
      } else {
        this.save_booking()
      }
    }
  }

  cancel_lost() {
    this.statusSelectTarget.value = this.originalValue
    this.statusSelectTarget.dispatchEvent(new Event('change'))
    $('div#lost-business-reason').modal('hide')
  }

  save_reason(e) {
    this.lost_business_reason_id = parseInt(e.target.value)
    if (Number.isNaN(this.lost_business_reason_id)) {
      this.confirmLostTarget.classList.add('disabled')
    } else {
      this.confirmLostTarget.classList.remove('disabled')
    }
  }

  save_booking() {
    window.bookingApp.saveBooking('?old_status=' + this.originalValue);
  }
}
