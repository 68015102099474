import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['optionalInput']

  toggleDependent(e) {
    const dependent = e.currentTarget.dataset.dependent
    if (dependent) {
      this.optionalInputTargets.forEach(function(el) {
        if (el.dataset.name === dependent) {
          if (e.currentTarget.value == '') {
            el.classList.add('d-none')
          } else {
            el.classList.remove('d-none')
          }
        }
      })
    }
  }

}