import moment from 'moment';
import Bloodhound from 'bloodhound-js';
import Hogan from 'hogan.js';
import Rails from '@rails/ujs';
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/code';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/noneditable';

window.closeModal = () => $('.modal').modal('hide');

window.hideAlerts = () => $('div.alert-success').fadeOut('slow');

window.sortableFixHelper = function(e, ui) {
  ui.children().each(function() {
    return $(this).width($(this).width());
  });
  return ui;
};

window.number_with_delimiter = function(float, opts) {
  if (opts == null) { opts = {}; }
  let _separator  = opts.separator != null ? opts.separator : '.';
  const _delimiter  = opts.delimiter != null ? opts.delimiter : ',';

  if (float === null) {
    float = 0;
  }
  const number  = float.toString().split(".");
  let integer = number[0];
  const decimal = number[1] != null ? number[1] : '';

  // Remove separator if no decimal
  if (!decimal) { _separator = ''; }

  const rgx = /(\d+)(\d{3})/;
  if (_delimiter) { while (rgx.test(integer)) { integer = integer .replace(rgx, "$1" + _delimiter + "$2"); } }

  return `${integer}${_separator}${decimal}`;
};

window.padCurrency = function(decimal) {
  let number = parseFloat(decimal);
  if (isNaN(number)) {
    number = 0;
  }
  return number.toFixed(2);
};

window.number_to_currency = function(float, opts) {
  if (opts == null) { opts = {}; }
  const _precision  = opts.precision != null ? opts.precision : 2;
  const _unit       = opts.unit != null ? opts.unit : '$';
  let _separator  = opts.separator != null ? opts.separator : '.';
  const _delimiter  = opts.delimiter != null ? opts.delimiter : ',';

  if (float === null) {
    float = 0;
  }
  const number  = float.toString().split('.');
  let integer = number[0];
  let decimal = number[1];

  // Pad to _precision
  decimal = parseFloat(`0.${decimal}`).toFixed(_precision);
  decimal = decimal.toString().split('.');
  decimal = decimal[1] != null ? decimal[1] : '';

  // Remove separator if no decimal
  if (!decimal) { _separator = ''; }

  // Non-number values return zero precision
  if (isNaN(integer)) { _separator = (decimal = ''); }

  integer = number_with_delimiter(integer, {delimiter: _delimiter});

  return `${integer}${_separator}${decimal}`;
};

window.getParameterByName = function(name) {
  const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return (match && decodeURIComponent(match[1].replace(/\+/g, ' '))) || '';
};

String.prototype.titleCase = function() {
  return this.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

String.prototype.humanize = function() {
  return this.toString().toLowerCase().replace(/[_-]/g, ' ').replace(/(?:^|\s)\S/g, a => a.toUpperCase());
};

String.prototype.truncate = function(length) {
  if (this.length <= length) {
    return this;
  }
  const ellipsis = '...';
  return (this.slice(0, length) + ellipsis).toString();
};

window.manageModals = () => $('.modal').on('show', () => $('body').addClass('modal-open')).on('hidden', () => $('body').removeClass('modal-open'));

window.formatDate = d => moment(d).format('MMMM D, YYYY');

window.handleRowWithSidebarHeight = function() {
  const minHeight = $(window).height() - $('.navbar-top').height() - $('.navbar-secondary').height();
  return $('.row.with-sidebar').css('min-height', minHeight);
};

window.select2Options = {
  width: 'resolve',
  minimumResultsForSearch: 20,
  placeholder: "Please select",
  allowClear: true
};

window.select2OptionsWithoutClear = {
  width: 'resolve',
  minimumResultsForSearch: 20,
  placeholder: "Please select"
};

window.handleSelects = function() {
  $('select').not('.nochosen,.flatpickr-monthDropdown-months').select2(window.select2Options);
  return $('.market-segment-single').select2({
    maximumSelectionLength: 1,
    placeholder: "Please select"
  });
};

window.handleTooltips = () => $('a.with-tooltip').tooltip();

window.handleNavbarTopMore = () => $('.navbar-top-more-popover').popover({
  placement: 'bottom',
  html: true,
  container: 'body',
  content() {
    return $('.navbar-top-more-content').html();
  },
  template: '<div class="popover popover-navbar" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
});

window.handleNavbarTopMobileUserSwitcher = () => $('.navbar-top-mobile-user-switcher').popover({
  placement: 'bottom',
  html: true,
  container: 'body',
  content() {
    return $('.user-switcher-mobile-content').html();
  },
  template: '<div class="popover popover-navbar" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
});

window.handleTodoDatetime = function() {
  $('.todo-datetime-label')
    .popover({
      trigger: 'manual',
      placement: 'right',
      html: true,
      container: 'body'}).on('shown.bs.popover', function() {
      $('#todo-datetime-datepicker').datepicker({
        changeMonth: true,
        changeYear: true,
        dateFormat: 'yy-mm-dd',
        showOn: 'both',
        minDate: moment().format('YYYY-MM-DD'),
        defaultDate: $(this).data('when'),
        onSelect(selectedDate) {
          $('#todo-datetime-datepicker').datepicker('destroy');
          $(this).popover('destroy');
          $('.popover').remove();
          return $.post(`/activities/${$(this).data('id')}.js`, { '_method': 'patch', activity: { when: selectedDate }, redraw: true });
        }});
      return $(this).popover('reposition');
  });
  return $(document).on('click', '.todo-datetime-label', function() {
    $('.todo-datetime-label').not(this).popover('hide');
    $('#todo-datetime-datepicker').datepicker('destroy');
    $('#todo-datetime-datepicker').remove();
    const po = $(this).data('bs.popover');
    po.options.content = `<div id='todo-datetime-datepicker' data-id='${$(this).data('id')}'></div>`;
    po.options.sanitize = false;
    return $(this).popover('show');
  });
};

window.handleSearchEverything = function() {
  if (typeof Bloodhound !== 'undefined' && Bloodhound !== null) {
    const results = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/everything-search.json?q=%QUERY',
        wildcard: '%QUERY'
      }
    });
    results.initialize();
    const template = Hogan.compile('<p><span style="color:#aaa">{{typename}}</span><br><strong>{{name}}</strong><br>{{subtitle}}</p>');
    const empty = Hogan.compile('<h6>No results found.</h6>');
    $('#search-everything').typeahead({ minLength: 3, hint: false }, {
      name: 'name',
      source: results.ttAdapter(),
      limit: 100,
      displayKey: 'name',
      templates: {
        suggestion: template.render.bind(template),
        empty: empty.render.bind(empty)
      }
    }
    );
    return $('#search-everything').on('typeahead:selected', function(event, datum) {
      if (datum.typename === 'Account') {
        return Turbo.visit(`/accounts/${datum.id}`);
      } else if (datum.typename.startsWith('Booking')) {
        return Turbo.visit(`/bookings/${datum.id}/edit/general`);
      } else if (datum.typename === 'Contact') {
        return Turbo.visit(`/accounts/${datum.account_id}`);
      }
    });
  }
};

window.tinyMCEBaseOptions = {
  convert_urls: false,
  relative_urls: false,
  content_css: "/stylesheets/tinymce.css",
  invalid_styles: 'height',
  selector: "textarea.tinymce",
  toolbar: ["bold italic underline forecolor backcolor | bullist numlist | alignleft aligncenter alignright | table | link unlink | image media | code | undo redo"],
  statusbar: false,
  menubar: false,
  forced_root_block: false,
  browser_spellcheck: true,
  contextmenu: 'image imagetools table',
};

window.tinyMCECommunityOptions = Object.assign({}, window.tinyMCEBaseOptions,
  {
    skin: false,
    paste_data_images: false,
    plugins: "autoresize,table,lists,link,paste,code,image,media"
  }
);

window.tinyMCECloudOptions = Object.assign({}, window.tinyMCEBaseOptions,
  {
    plugins: "autoresize,table,lists,link,powerpaste,code,image,media",
    powerpaste_allow_local_images: true
  }
);

window.tinyMCEOptions = ((typeof tinyCloudEnabled !== 'undefined' && tinyCloudEnabled !== null) && tinyCloudEnabled) ? tinyMCECloudOptions : tinyMCECommunityOptions;
window.tinyMCEOptions.indent = false

window.tinyMCEFullPlugins = function() {
  let plugins = 'noneditable,advlist,autolink,lists,link,image,charmap,print,preview,searchreplace,visualblocks,code,fullscreen,pagebreak,insertdatetime,media,table,code';
  if ((typeof tinyCloudEnabled !== 'undefined' && tinyCloudEnabled !== null) && tinyCloudEnabled) {
    plugins += ',powerpaste';
  } else {
    plugins += ',paste';
  }
  return plugins;
};

window.tinymceFullCss = (typeof window.tinymceFullCss === undefined) ? '/stylesheets/tinymce.css' : window.tinymceFullCss

window.tinyMCEFullOptions = Object.assign({}, window.tinyMCEOptions,
  {
    content_css: ((window.suppressPrintCss != null) && window.suppressPrintCss) ? '/stylesheets/tinymce.css' : window.tinymceFullCss,
    toolbar: ["insert | undo redo | formatselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pagebreak removeformat | table | searchreplace | link unlink | image | code"],
    pagebreak_separator: '<div class="alwaysbreak"></div>',
    noneditable_noneditable_class: 'noneditable',
    plugins: window.tinyMCEFullPlugins()
  }
);

window.tinyMCELightOptions = Object.assign({}, window.tinyMCEOptions,
  {
    content_css: ((window.suppressPrintCss != null) && window.suppressPrintCss) ? '/stylesheets/tinymce.css' : window.tinymceFullCss,
    toolbar: ["insert | undo redo | bold italic underline | alignleft aligncenter alignright alignjustify"],
    pagebreak_separator: '<div class="alwaysbreak"></div>',
    noneditable_noneditable_class: 'noneditable'
  }
);

window.initTinyMCE = function() {
  tinymce.remove();
  return tinymce.init(window.tinyMCEOptions);
};

window.initTinyMCEFull = function() {
  tinymce.remove();
  return tinymce.init(window.tinyMCEFullOptions);
};

window.initTinyMCELight = function() {
  tinymce.remove();
  return tinymce.init(window.tinyMCELightOptions);
};

window.clearbitBlockUI = () => $.blockUI({
  message: null,
  fadeIn: 100,
  fadeOut: 100,
  overlayCSS: {
    backgroundColor: '#000',
    opacity: .2,
    cursor: 'wait'
  }
});

window.pleaseWait = () => $.blockUI({
  message: null,
  fadeIn: 100,
  fadeOut: 100,
  overlayCSS: {
    backgroundColor: '#000',
    opacity: .2,
    cursor: 'wait'
  }
});

window.unblockUI = () => $.unblockUI();

$(document).on('page:receive', () => tinymce.remove());

$(document).on('change', '#property', function(event) {
  return this.form.submit();
});

// Select2 and jQuery 3.6+ don't play nice
$(document).on('select2:open', (e) => {
  const selectId = e.target.id
  $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
    key,
    value,
  ) {
    value.focus();
  })
});

document.addEventListener('turbo:load', function() {
  window.handleSelects()
  window.handleSearchEverything();
  window.handleTooltips();
  window.handleNavbarTopMore();
  window.handleNavbarTopMobileUserSwitcher();
  window.handleTodoDatetime();
  window.setTimeout(hideAlerts, 4000);
  manageModals();
  $(window).resize(() => window.handleRowWithSidebarHeight());
  $.ajaxSetup({
    headers: {
      'X-CSRF-Token': Rails.csrfToken()
    }
  });
  return window.$ = jQuery;
});
