import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    $('.booking_booking_agent').on('select2:select', this.update.bind(this))
    $('.booking_booking_agent').on('select2:clear', this.update.bind(this))
  }

  update() {
    window.bookingApp.saveBooking(`/bookings/${window.bookingApp.bookingId}/edit/${window.bookingApp.step}`)
  }
}