(function() {
  $(document).on('change', '.beo-page-quick-select', function(event) {
    var target;
    target = `/bookings/${bookingId}/booking_documents/${bookingDocumentId}/booking_document_pages/${$(this).val()}/edit`;
    return Turbo.visit(target);
  });

  document.addEventListener('turbo:load', function() {
    if ($('body').hasClass('booking_documents') || $('body').hasClass('booking_document_pages')) {
      return window.initTinyMCEFull();
    }
  });

}).call(this);
