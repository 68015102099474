import React from 'react'

const BookingsFilterPanel = (props) => {
  const panelTitle = props.title.replace(/\s+/g, '')
  return (
    <div className={props.collection.length > 0 ? "panel panel-success" : "panel panel-default"}>
      <div className="panel-heading">
        <h4 className="panel-title">
          <a role="button" data-toggle="collapse" data-parent="#accordion" href={"#" + panelTitle}>
            {props.title}
          </a>
          <div className="pull-right badge">
            {props.collection.length}
          </div>
        </h4>
      </div>
      <div id={panelTitle} className="panel-collapse collapse" role="tabpanel">
        <div className="panel-body">
          {props.checkboxes}
        </div>
      </div>
    </div>
  )
}

export default BookingsFilterPanel