import ClipboardJS from 'clipboard';

document.addEventListener('turbo:load', function() {
  var $body, clipboard;
  $body = $('body');
  if ($body.hasClass('users') && $body.hasClass('edit')) {
    clipboard = new ClipboardJS('.copy-ical-to-clipboard');
    clipboard.on('success', function(event) {
      var $clipButton, cb;
      $clipButton = $(event.trigger);
      $clipButton.tooltip({
        title: 'Copied!'
      });
      $clipButton.tooltip('show');
      cb = function(elem) {
        return elem.tooltip('destroy');
      };
      return setTimeout((function() {
        return cb($clipButton);
      }), 1500);
    });
    clipboard = new ClipboardJS('.copy-email-to-clipboard');
    return clipboard.on('success', function(event) {
      var $clipButton, cb;
      $clipButton = $(event.trigger);
      $clipButton.tooltip({
        title: 'Copied!'
      });
      $clipButton.tooltip('show');
      cb = function(elem) {
        return elem.tooltip('destroy');
      };
      return setTimeout((function() {
        return cb($clipButton);
      }), 1500);
    });
  }
});

$(document).on('click', 'body.users.edit .copy-ical-to-clipboard, body.users.edit .copy-email-to-clipboard', function(event) {
  return event.preventDefault();
});
