import moment from 'moment';

import plupload from 'plupload';

window.signatureApp = {
  submitSignatureForm: function() {
    return $('#new_search').submit(); //.debounce(500)
  },
  signatureForm: {
    validate: function(form) {
      var signatureFileRows, signerRows;
      signatureFileRows = $(form).find('#signature-files tbody tr:visible');
      signerRows = $(form).find('#signers tbody tr:visible');
      return (signatureFileRows.length > 0 && signatureFileRows.toArray().every(this.validateSignatureFileRow)) && (signerRows.length > 0 && signerRows.toArray().every(this.validateSignerRow));
    },
    validateSignatureFileRow: function(el, index, array) {
      return ($(el).find('.signature-file-name-value').first().text() && $(el).find('.signature-file-name-value').first().text() !== '') && ($(el).find('.signature_file_title_input').first().val() !== '');
    },
    validateSignerRow: function(el, index, array) {
      var signerEmailAddress, signerName;
      signerEmailAddress = $(el).find('.signer_email_input').first().val();
      signerName = $(el).find('.signer_name_input').first().val();
      return (signerName !== '') && (signerEmailAddress !== '') && signerEmailAddress.match(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i);
    }
  },
  signatureFilesUploadStep: {
    pluploadSignatureFileRow: function(index, fileId, fileName, title) {
      return this.signatureFileRow(index, fileId, fileName, title);
    },
    ccAuthFormSignatureFileRow: function(fileCount, fileName, title) {
      return this.signatureFileRow(fileCount, '', fileName, title, true);
    },
    signatureFileRow: function(index, fileId, fileName, title, isCCAuthForm) {
      var arrowIcon, ccAuthFormBooleanInput, ccAuthFormFieldName, destroyBooleanInput, destroyFieldName, fileNameSpan, pluploadFileIdInput, positionFieldName, positionInput, positionSpan, removeIcon, removeSignatureFileLink, rowSelector, titleFieldName, titleInput, tr;
      rowSelector = '';
      if (fileId !== '') {
        rowSelector = 'id="pluploadFile' + fileId + '"';
      } else if (isCCAuthForm) {
        rowSelector = 'class="cc_auth_form_signature_file"';
      }
      arrowIcon = '<i class="fa fa-arrows"></i>';
      if (isCCAuthForm) {
        positionFieldName = 'signature[signature_files_attributes][' + index + '][position]';
      } else {
        positionFieldName = 'position';
      }
      positionInput = '<input type="hidden" name="' + positionFieldName + '" value="' + index + 1 + '" class="signature-file-position-field">';
      positionSpan = '<span class="signature-file-position-value"></span>';
      if (isCCAuthForm) {
        titleFieldName = 'signature[signature_files_attributes][' + index + '][title]';
      } else {
        titleFieldName = 'title';
      }
      titleInput = '<input type="text" name="' + titleFieldName + '" value="' + title + '" class="signature_file_title_input">';
      fileNameSpan = '<span class="signature-file-name-value">' + fileName + '</span>';
      pluploadFileIdInput = '<input type="hidden" name="pluploadFileId" value="' + fileId + '">';
      ccAuthFormFieldName = 'signature[signature_files_attributes][' + index + '][cc_auth_form]';
      ccAuthFormBooleanInput = '<input type="hidden" name="' + ccAuthFormFieldName + '" value="true">';
      // noSignaturePageFieldName = 'signature[signature_files_attributes][' + index + '][no_signature_page]'
      // noSignaturePageBooleanInput = '<input type="hidden" name="' + noSignaturePageFieldName + '" value="false">'
      destroyFieldName = 'signature[signature_files_attributes][' + index + '][_destroy]';
      destroyBooleanInput = '<input type="hidden" name="' + destroyFieldName + '" value="false" class="destroy">';
      removeIcon = '<i class="fa fa-trash"></i>';
      removeSignatureFileLink = '<a class="remove-signature-file" href="#">' + removeIcon + '</a>';
      tr = '';
      tr += '<tr ' + rowSelector + '>';
      tr += '<td>' + arrowIcon + '</td>';
      tr += '<td class="text-center">' + positionInput + positionSpan + '</td>';
      tr += '<td>' + titleInput + '</td>';
      tr += '<td>' + fileNameSpan + '</td>';
      tr += '<td class="text-center">';
      if (fileId !== '') {
        tr += pluploadFileIdInput;
      }
      if (isCCAuthForm) {
        tr += ccAuthFormBooleanInput;
        tr += destroyBooleanInput;
      }
      // tr +=     noSignaturePageBooleanInput
      tr += removeSignatureFileLink;
      tr += '</td>';
      tr += '</tr>';
      return tr;
    },
    handleUpload: function() {
      var signatureFileUploadForm, signatureFileUploadMethod, signatureFileUploadURL, signatureFileUploader;
      signatureFileUploadForm = $('#signature-file-uploader').closest('form');
      signatureFileUploadURL = signatureFileUploadForm.attr('action');
      signatureFileUploadMethod = signatureFileUploadForm.attr('method');
      signatureFileUploader = new plupload.Uploader({
        runtimes: 'html5',
        browse_button: 'attachment-add-files',
        url: signatureFileUploadURL,
        filters: {
          mime_types: [
            {
              title: "PDF files",
              extensions: "pdf"
            },
            {
              title: "Image files",
              extensions: "jpg,gif,png"
            },
            {
              title: "Microsoft Word",
              extensions: "doc,docx"
            }
          ]
        }
      });
      signatureFileUploader.bind('PostInit', function() {
        $('#filelist').html("");
        signatureFileUploadForm.find('.error-message').hide();
        return signatureFileUploadForm.find('input[type="submit"]').on('click', function(e) {
          var form, signatureFileRows, signerRows;
          e.preventDefault();
          form = $(this).closest('form');
          if (window.signatureApp.signatureForm.validate(form)) {
            return $.ajax({
              url: signatureFileUploadURL,
              method: signatureFileUploadMethod,
              dataType: 'JSON',
              data: signatureFileUploadForm.serialize(),
              success: function(data) {
                var newSignatureFileUploadURL;
                newSignatureFileUploadURL = "/signatures/" + data.signature.id + "/signature_files";
                signatureFileUploader.setOption('url', newSignatureFileUploadURL);
                signatureFileUploader.bind('UploadComplete', function() {
                  $('#upload-attachments-button').show();
                  $('#upload-attachments-progress').hide();
                  return window.top.location.href = "/signatures/" + data.signature.id + "/edit";
                });
                return signatureFileUploader.start();
              },
              error: function(data) {
                // TODO: handle this
                return console.log(data.responseText);
              }
            });
          } else {
            signatureFileRows = $(form).find('#signature-files tbody tr');
            signerRows = $(form).find('#signers tbody tr');
            if (signatureFileRows.length === 0) {
              $(form).find('#signature-files .error-message').show();
            }
            signatureFileRows.each((index, elem) => {
              if ($(elem).find('.signature_file_title_input').first().val() === '') {
                $(elem).find('.signature_file_title_input').first().addClass('has-error');
                return $(form).find('#signature-files .error-message').show();
              } else {
                return $(elem).find('.signature_file_title_input').first().removeClass('has-error');
              }
            });
            signerRows.each((index, elem) => {
              var signerEmailAddress;
              if ($(elem).find('.signer_name_input').first().val() === '') {
                $(elem).find('.signer_name_input').first().addClass('has-error');
                $(form).find('#signers .error-message').show();
              } else {
                $(elem).find('.signer_name_input').first().removeClass('has-error');
              }
              signerEmailAddress = $(elem).find('.signer_email_input').first().val();
              if (signerEmailAddress === '' || !signerEmailAddress.match(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i)) {
                $(elem).find('.signer_email_input').first().addClass('has-error');
                return $(form).find('#signers .error-message').show();
              } else {
                return $(elem).find('.signer_email_input').first().removeClass('has-error');
              }
            });
            return setTimeout($.unblockUI, 2000);
          }
        });
      });
      signatureFileUploader.bind('FilesAdded', function(up, files) {
        var fileCount;
        $('.send-link').addClass('disabled');
        fileCount = $('.signature-file-position-value:visible').length;
        $.each(files, function(i, file) {
          var fileName, index, title, tr;
          index = fileCount + i;
          fileName = file.name;
          title = file.name;
          tr = window.signatureApp.signatureFilesUploadStep.pluploadSignatureFileRow(index, file.id, fileName, title);
          return $('#signature-files table tbody').append(tr);
        });
        if ($('#signature-files tbody tr').length > 0) {
          $('#signature-files table').show();
          $('#signature-files-blank').hide();
        } else {
          $('#signature-files-blank').show();
        }
        // TODO: unnecessary
        $('.remove-signature-file').on('click', function(e) {
          var file, fileId;
          e.preventDefault();
          fileId = $(this).siblings('input[name="pluploadFileId"]').val();
          file = signatureFileUploader.getFile(fileId);
          signatureFileUploader.removeFile(file);
          $(this).closest('tr').remove();
          if ($('#signature-files tbody tr').length < 1) {
            $('#signature-files table').hide();
            $('#signature-files-blank').show();
          }
          $('#signature-files table tbody tr:visible').find('.signature-file-position-value').each((index, elem) => {
            return $(elem).text(index + 1);
          });
          return $('.send-link').addClass('disabled');
        });
        
        // TODO: DRY up
        return $('#signature-files table tbody tr:visible').find('.signature-file-position-value').each((index, elem) => {
          return $(elem).text(index + 1);
        });
      });
      signatureFileUploader.bind('BeforeUpload', function(up, file) {
        var position, title, trSelector;
        signatureFileUploadForm.find('input[type="submit"]').addClass('disabled');
        $('#upload-attachments-button').hide();
        $('#upload-attachments-progress').show();
        trSelector = 'tr#pluploadFile' + file.id;
        position = $(trSelector).find('.signature-file-position-field').val();
        title = $(trSelector).find('input[name="title"]').val();
        return signatureFileUploader.setOption('multipart_params', {
          signature_file: {
            position: position,
            title: title
          }
        });
      });
      signatureFileUploader.bind('Error', function(up, error) {
        return console.log(error.message);
      });
      return signatureFileUploader.init();
    }
  }
};

document.addEventListener('turbo:load', function() {
  var $body, dateRangePickerCallback, dateRangePickerOptions, end_date, ranges, search_end_date, search_start_date, start_date;
  $body = $('body');
  // Signatures#index
  if ($body.hasClass('signatures index')) {
    window.handleRowWithSidebarHeight();
    ranges = {
      'This Week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      'Last Week': [moment().subtract(1, 'week').startOf('isoWeek'), moment().subtract(1, 'week').endOf('isoWeek')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'This Year': [moment().startOf('year'), moment().endOf('month')]
    };
    // set starting values
    search_start_date = $('#search_start_date').val();
    search_end_date = $('#search_end_date').val();
    if (search_start_date && search_end_date) {
      start_date = moment(search_start_date, 'YYYY-MM-DD');
      end_date = moment(search_end_date, 'YYYY-MM-DD');
    }
    dateRangePickerOptions = {
      clearClass: 'btn btn-default btn-sm',
      showDropdowns: true,
      startDate: start_date,
      endDate: end_date,
      ranges: ranges
    };
    dateRangePickerCallback = function(start, end) {
      var endDate, startDate;
      $('.date-range-monthly span').html(start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY'));
      startDate = start.format('YYYY-MM-DD');
      endDate = end.format('YYYY-MM-DD');
      $('#search_start_date').val(startDate);
      $('#search_end_date').val(endDate);
      return window.signatureApp.submitSignatureForm();
    };
    $('.date-range-monthly').daterangepicker(dateRangePickerOptions, dateRangePickerCallback);
    // debounce-submit the search form
    $('#new_search').change(function() {
      return window.signatureApp.submitSignatureForm();
    });
  }
  // Signatures#new || Signatures#edit
  if ($body.hasClass('signatures new') || $body.hasClass('signatures edit')) {
    window.signatureApp.signatureFilesUploadStep.handleUpload();
    // top form submission 
    $(document).on('click', '.next-link', function(event) {
      return window.pleaseWait();
    });
    // links: add signer
    $(document).on('click', 'a#add-signer', function(event) {
      var regexp, time;
      event.preventDefault();
      time = new Date().getTime();
      regexp = new RegExp($(this).data('id'), 'g');
      $('#signers table tbody').append($(this).data('fields').replace(regexp, time));
      $('#signers table tbody tr:visible').find('.signer-position-field').each((index, elem) => {
        $(elem).val(index + 1);
        return $(elem).siblings('.signer-position-value').text(index + 1);
      });
      return $('.send-link').addClass('disabled');
    });
    // links: remove signer
    $(document).on('click', 'a.remove-signer', function(event) {
      var $this;
      event.preventDefault();
      $this = $(this);
      $this.parent().find('input.destroy').val('1');
      $this.closest('tr').hide();
      $('#signers table tbody tr:visible').find('.signer-position-field').each((index, elem) => {
        $(elem).val(index + 1);
        return $(elem).siblings('.signer-position-value').text(index + 1);
      });
      return $('.send-link').addClass('disabled');
    });
    // links: remove signature_file
    $(document).on('click', 'a.remove-signature-file', function(event) {
      var $this;
      event.preventDefault();
      $this = $(this);
      $this.parent().find('input.destroy').val('true');
      $this.closest('tr').hide();
      $('#signature-files table tbody tr:visible').find('.signature-file-position-field').each((index, elem) => {
        $(elem).val(index + 1);
        return $(elem).siblings('.signature-file-position-value').text(index + 1);
      });
      $('.send-link').addClass('disabled');
      if ($('#signature-files tbody tr:visible').length === 0) {
        $('#signature-files table').hide();
        $('#signature-files-blank').show();
      }
      if ($('tr.cc_auth_form_signature_file:visible').length === 0) {
        $('#signature_cc_auth_form').prop('checked', false);
        return $('#cc_auth_form_checkbox').show();
      }
    });
    // links: add cc_auth_form
    if ($('tr.cc_auth_form_signature_file:visible').length > 0) {
      $('#cc_auth_form_checkbox').hide();
    }
    $(document).on('click', '#signature_cc_auth_form', function(event) {
      var $this, fileCount, fileName, title, tr;
      $this = $(this);
      if ($this.is(':checked')) {
        $('#cc_auth_form_checkbox').hide();
        title = $(this).data('title');
        fileName = $(this).data('filename');
        fileCount = $('.signature-file-position-value:visible').length;
        if ($('tr.cc_auth_form_signature_file').length === 0) {
          tr = window.signatureApp.signatureFilesUploadStep.ccAuthFormSignatureFileRow(fileCount, fileName, title);
          $('#signature-files table tbody').append(tr);
        }
        if ($('#signature-files tbody tr').length > 0) {
          $('#signature-files table').show();
          $('#signature-files-blank').hide();
        } else {
          $('#signature-files-blank').show();
        }
        $('#signature-files table tbody tr:visible').find('.signature-file-position-field').each((index, elem) => {
          $(elem).val(index + 1);
          return $(elem).siblings('.signature-file-position-value').text(index + 1);
        });
        return $('.send-link').addClass('disabled');
      }
    });
    $('.signatures .sortable.sortable-signature-files').sortable({
      revert: true,
      stop: function(event, ui) {
        $(this).find('tr:visible .signature-file-position-field').each((index, elem) => {
          $(elem).val(index + 1);
          return $(elem).siblings('.signature-file-position-value').text(index + 1);
        });
        return $('.send-link').addClass('disabled');
      }
    });
    $('.signatures .sortable.sortable-signers').sortable({
      revert: true,
      stop: function(event, ui) {
        $(this).find('tr:visible .signer-position-field').each((index, elem) => {
          $(elem).val(index + 1);
          return $(elem).siblings('.signer-position-value').text(index + 1);
        });
        return $('.send-link').addClass('disabled');
      }
    });
    $(document).on('change', 'form.simple_form input', function() {
      return $('.send-link').addClass('disabled');
    });
    if ($('#signature-files tbody tr').length < 1) {
      $('#signature-files table').hide();
    } else {
      $('#signature-files-blank').hide();
    }
    if (addCCAuthFormOnNew) {
      $('#signature_cc_auth_form').click();
      $('.next-link').click();
    }
    return window.initTinyMCE();
  }
});
