import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = ["menuItemOptions", "numberOfOptionsRequired"]
  
  toggle_menu_item_options() {
    if (this.menuItemOptionsTarget.classList.contains("hidden") && parseInt(this.numberOfOptionsRequiredTarget.value) > 0) {
      this.menuItemOptionsTarget.classList.remove("hidden")
    } else {
      this.menuItemOptionsTarget.classList.add("hidden")
    }
  }
}