(function() {
  $(document).on('click', 'body.booking_document_templates a.insert-merge-field, body.nest-booking_document_templates a.insert-merge-field', function(event) {
    var tag;
    event.preventDefault();
    tag = $(this).html();
    if (!tag.startsWith('[[')) {
      tag = `&lt;&lt;${tag}&gt;&gt;`;
    }
    return tinymce.activeEditor.execCommand('mceInsertContent', false, tag);
  });

  $(document).on('change', 'body.booking_document_templates.new #booking_document_template_document_type, body.nest-booking_document_templates.new #booking_document_template_document_type', function(event) {
    if ($(this).val() === 'beo') {
      $.get('/beo_template.html', function(data) {
        return tinymce.get('booking_document_template_body').execCommand('mceSetContent', false, data);
      });
    }
    if ($(this).val() === 'combined_beo') {
      $.get('/beo_template_combined.html', function(data) {
        return tinymce.get('booking_document_template_body').execCommand('mceSetContent', false, data);
      });
    }
    if ($(this).val() === 'reader_board') {
      return $.get('/reader_board_template.html', function(data) {
        return tinymce.get('booking_document_template_body').execCommand('mceSetContent', false, data);
      });
    }
  });

  document.addEventListener('turbo:load', function() {
    if ($('body').hasClass('booking_document_templates')) {
      window.initTinyMCEFull();
    }
    if ($('body').hasClass('nest-booking_document_templates')) {
      return window.initTinyMCEFull();
    }
  });

}).call(this);
