import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['childMemberCheckbox']

  selectAll(event) {
    event.preventDefault()
    this.childMemberCheckboxTargets.map(checkbox => checkbox.checked = true)
  }
}

