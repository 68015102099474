import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submitButton']

  unblockSubmit() {
    this.submitButtonTarget.removeAttribute('disabled')
    this.submitButtonTarget.value = 'Upload'
  }

}