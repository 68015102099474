import moment from 'moment';

window.DocumentCopyApp = {
  eventLength: null
};

document.addEventListener('turbo:load', function() {
  var endDatePicker, startDatePicker;
  if (document.querySelector('body').classList.contains('document_copies')) {
    endDatePicker = flatpickr('#document_end_date', {
      altInput: true,
      altFormat: 'l, F j, Y',
      dateFormat: 'Y-m-d'
    });
    return startDatePicker = flatpickr('#document_start_date', {
      altInput: true,
      altFormat: 'l, F j, Y',
      dateFormat: 'Y-m-d',
      onChange: function(selectedDates, selectedDate) {
        var newEndDate, newStartDate;
        newStartDate = moment(selectedDate);
        newEndDate = newStartDate.add(window.DocumentCopyApp.eventLength, 'days');
        return endDatePicker.setDate(moment(newEndDate).format('YYYY-MM-DD'));
      }
    });
  }
});
