import React, { Fragment } from 'react'

const GrcLegend = (props) => {
  return (
    <Fragment>
      <span className="pull-left" style={{marginLeft: '20px', paddingTop: '8px'}}>
        {Object.keys(props.hotDates).length > 0 && 
          <span className="hotDate">Indicates Hot Date</span>
        }
      </span>
      <span className="pull-left" style={{marginLeft: '20px', paddingTop: '8px'}}>
        {Object.keys(props.coldDates).length > 0 && 
          <span className="coldDate">Indicates Cold Date</span>
        }
      </span>
    </Fragment>
  )
}

export default GrcLegend