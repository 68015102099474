(function() {
  var onContactClearbitSuccess;

  onContactClearbitSuccess = function(data) {
    var addr_data, linkedin_address;
    $.unblockUI();
    if (data && data.person) {
      $('#contact_first_name').val(data.person.name.givenName);
      $('#contact_last_name').val(data.person.name.familyName);
      if (data.company.location) {
        addr_data = data.company.location.split(', ');
        if (addr_data.length === 4) {
          $('#contact_address').val(addr_data[0]);
          $('#contact_city').val(addr_data[1]);
          $('#contact_state').val(addr_data[2].split(' ')[0]);
          $('#contact_zip').val(addr_data[2].split(' ')[1]);
        } else if (addr_data.length === 5) {
          $('#contact_address').val(addr_data[0]);
          $('#contact_address2').val(addr_data[1]);
          $('#contact_city').val(addr_data[3]);
          $('#contact_state').val(addr_data[4].split(' ')[0]);
          $('#contact_zip').val(addr_data[4].split(' ')[1]);
        }
      }
      if (data.person) {
        $('#contact_first_name').val(data.person.name.givenName);
        $('#contact_last_name').val(data.person.name.familyName);
        if (data.person.employment) {
          $('#contact_title').val(data.person.employment.title);
        }
        if (data.person.avatar) {
          $('#contact_avatar_url').val(data.person.avatar);
          $('.avatar-placeholder').html(`<img src=\"${data.person.avatar}\" style=\"height: 75px\">`).show();
        }
        $('#contact_twitter').val(data.person.twitter.handle);
        if (data.person.linkedin.handle) {
          linkedin_address = `http://linkedin.com/${data.person.linkedin.handle}`;
          return $('#contact_linkedin_account').val(linkedin_address);
        }
      }
    }
  };

  // $(document).on 'blur', 'body.contacts.new #contact_email', (event) ->
  //   if $(this).val().length > 0
  //     clearbitBlockUI()
  //     $.post '/clearbit_calls', { q: $(this).val(), format: 'json' }, onContactClearbitSuccess
  $(document).on('click', 'body.contacts.edit .sync-social', function(event) {
    var $email;
    event.preventDefault();
    $email = $('#contact_email');
    if ($email.val().length > 0) {
      clearbitBlockUI();
      return $.post('/clearbit_calls', {
        q: $email.val(),
        format: 'json'
      }, onContactClearbitSuccess);
    }
  });

  $(document).on('change', 'body.contacts.edit #contact_first_name', function(event) {
    return $('.contact_first_name p.help-block').show();
  });

  $(document).on('change', 'body.contacts.edit #contact_last_name', function(event) {
    return $('.contact_last_name p.help-block').show();
  });

  document.addEventListener('turbo:load', function() {
    var $body;
    $body = $('body');
    if ($body.hasClass('contacts') && $body.hasClass('edit')) {
      return $('#contact_account_id').select2({
        ajax: {
          url: '/accounts-search.json',
          dataType: 'json',
          delay: 250,
          cache: true,
          data: function(params) {
            return {
              query: params.term
            };
          },
          processResults: function(data) {
            return {
              results: data
            };
          }
        },
        minimumInputLength: 2,
        escapeMarkup: function(markup) {
          return markup;
        },
        templateResult: function(account) {
          if (account.name) {
            return `<p><strong>${account.name}</strong><br/>${account.city_state}</p>`;
          }
        }
      });
    }
  });

}).call(this);
