import React from 'react'
import BookingTableRow from './BookingTableRow'

const BookingTable = (props) => {
  if (props.data.length > 0) {
    const tableRows = props.data.map(function(row) {
      return (
        <BookingTableRow key={row.id} 
                         bookingId={row.id} 
                         name={row.name} 
                         groupType={row.group_type}
                         startDate={row.start_date}
                         endDate={row.end_date}
                         propertyName={row.property_name}
                         displayDates={row.display_dates}
                         salesPerson={row.sales_person} 
                         companyName={row.company_name} 
                         contactName={row.contact_name} 
                         email={row.email} 
                         status={row.status} 
                         lastUpdated={row.updated_at} 
                         contacts={row.contacts}
                         ownership={props.ownership}
                         multiprop={props.multiprop}
                         pmsIntegrated={props.pmsIntegrated}
                         sentToPms={row.sent_to_pms} />
      )
    }.bind(this))
    return (
      <table className="table table-list">
        <thead>
          <tr>
            <th>Actions</th>
            {props.multiprop &&
              <th>Property</th>
            }
            <th>Type</th>
            <th>Account</th>
            <th><a onClick={props.onNameClicked}>Name</a></th>
            <th><a onClick={props.onDatesClicked}>Dates</a></th>
            <th>Salesperson</th>
            <th>Status</th>
            {props.pmsIntegrated &&
              <th>PMS</th>
            }
            <th><a onClick={props.onLastUpdatedClicked}>Last Updated</a></th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </table>
    )
  } else {
    return (
      <div className="well">No bookings found for selected criteria.</div>
    )
  }
}

export default BookingTable