import moment from 'moment'
import { Controller } from '@hotwired/stimulus'
import { Calendar } from '@fullcalendar/core'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction'

export default class extends Controller {
  static targets = ['timeline']
  static values = {
    propertyId: Number
  }

  connect() {
    // state
    this.drawingHold = false
    this.drawingBooking = false

    // allows access to this controller from outside of it (e.g. in update.coffee)
    // makes something like document.querySelector('#function-diary').functionDiary.reRenderCalendar() possible
    // https://dev.to/leastbad/the-best-one-line-stimulus-power-move-2o90
    this.element.functionDiary = this
    this.buildCalendar()
    this.buildSelect2ForExistingBooking()
  }

  csrfToken() {
    return document.querySelector("[name='csrf-token']").content
  }

  updateDateHash() {
    const date = this.calendar.getDate().toISOString().split('T')[0]
    const view = this.calendar.view.type
    return window.location.hash = `#${date}.${view}`
  }

  buildSelect2ForExistingBooking() {
    // select2 needs jQuery
    $('#fd_existing_booking_id').select2({
      width: '100%',
      minimumInputLength: 3,
      allowClear: false,
      placeholder: 'Search for an existing booking',
      ajax: {
        url: '/bookings.json?ownership=search&sort_by=start_date&order=desc',
        dataType: 'json',
        delay: 250,
        cache: true,
        data: (params) => {
          return { query: params.term }
        },
        processResults: (data) => {
          return { results: data }
        }
      },
      escapeMarkup: (markup) => {
        return markup
      },
      templateResult: (booking) => {
        if (booking.name) {
          return `<p><strong>${booking.name}</strong><br>${booking.company_name}<br><em>${booking.display_dates}</em></p>`
        }
      },
      templateSelection: (booking) => {
        if (booking.id) {
          $('#fd-new-booking-modal').modal('hide')
          this.setBooking(booking.id, booking.name)
          if (booking.start_date) {
            const bookingStartDate = booking.start_date
            this.calendar.gotoDate(bookingStartDate)
          }
        }
      }
    })
  }

  showBookingMeetingModal(href) {
    // still have to use jQuery here for Bootstrap's modal
    $('#fd-booking-meeting-modal .modal-body').load(href)
    $('#fd-booking-meeting-modal').modal('show')
  }

  handleDatePicker() {
    const $dp = $('<input type="text" class="hide fd-datepicker">')
    $('.fc-datePickerButton-button').append($dp)
    const dateOptions = {
      buttonText: "<i class='fa fa-calendar'></i>",
      changeMonth: true,
      changeYear: true,
      dateFormat: 'yy-mm-dd',
      showOn: 'both'
    }
    this.datePicker = $dp.datepicker($.extend({}, dateOptions, {
      onSelect: (selectedDate) => {
        return this.calendar.gotoDate(selectedDate)
      }
    }))
  }

  toggleDrawingHold(element) {
    this.drawingHold = !this.drawingHold
    this.drawingBooking = false
    this.drawBookingButton().classList.remove('active')
    this.drawBookingButton().innerHTML = 'Draw Booking'
    if (this.drawingHold) {
      element.classList.add('active')
    } else {
      element.classList.remove('active')
    }
  }

  toggleDrawingBooking(element) {
    if (this.drawingBooking) {
      element.classList.remove('active')
      this.drawBookingButton().innerHTML = 'Draw Booking'
      this.drawingBooking = false
    } else {
      $('#fd-new-booking-modal').modal('show')
    }
  }

  // since we're using a jQuery modal, we need to be able to send info from its result
  // see bookings/create.js.erb
  setBooking(id, name) {
    this.bookingId = id
    this.bookingName = name
    this.drawingBooking = true
    this.drawingHold = false
    document.querySelector('.fc-drawHoldButton-button').classList.remove('active')
    this.drawBookingButton().classList.add('active')
    this.drawBookingButton().innerHTML = `Draw Booking: ${name}`
  }

  drawBookingButton() {
    return document.querySelector('.fc-drawBookingButton-button')
  }

  async eventChanged(info, dropped) {
    if (!info.event.extendedProps.setupBreakdown) {
      const event = info.event
      let params = {
        format: 'js',
        hold: event.extendedProps.hold,
        start: event.startStr,
        end: event.endStr,
        resized: !dropped,
        dropped: dropped
      }
      if (dropped && info.newResource) {
        params.meeting_room_id = info.newResource.id
      }
      await fetch(`/booking_meetings/${event.id}`, {
        method: 'PATCH',
        headers: {
          "X-CSRF-Token": this.csrfToken(),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
      })
    }
    this.reRenderCalendar()
  }

  async createHold(start, end, meetingRoomId) {
    const params = {
      format: 'js',
      booking_id: '0', // tells the controller that this is a hold space
      start: start,
      end: end,
      meeting_room_id: meetingRoomId
    }
    this.postBookingMeeting(params)
  }

  async createBookingMeeting(start, end, meetingRoomId) {
    const params = {
      format: 'js',
      booking_id: this.bookingId,
      start: start,
      end: end,
      meeting_room_id: meetingRoomId
    }
    this.postBookingMeeting(params)
  }

  async postBookingMeeting(params) {
    await fetch(`/booking_meetings`, {
        method: 'POST',
        headers: {
          "X-CSRF-Token": this.csrfToken(),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
      })
    this.reRenderCalendar()
  }

  reRenderCalendar() {
    this.calendar.refetchEvents()
  }

  buildCalendar() {
    let initialDate, initialView
    if (window.location.hash) {
      const dateAndView = window.location.hash.replace('#','').split('.')
      const date = dateAndView[0]
      const view = dateAndView[1] || 'resourceTimelineDay'
      initialDate = moment(date).format('YYYY-MM-DD')
      initialView = view
    } else {
      initialDate = new Date()
      initialView = 'resourceTimelineDay'
    }

    this.calendar = new Calendar(this.timelineTarget, {
      schedulerLicenseKey: '0400913918-fcs-1689610790',
      plugins: [resourceTimelinePlugin, interactionPlugin],
      initialDate,
      initialView,
      timeZone: window.timeZone,
      height: window.innerHeight - document.querySelector('.navbar-top').offsetHeight - 70,
      editable: true,
      selectable: true,
      eventTimeFormat: calendarTimeFormatEvent,
      views: {
        resourceTimelineDay: {
          titleFormat: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' },
        }
      },
      resourceAreaWidth: '200px',
      slotDuration: '00:15:00',
      slotMinWidth: 20, // makes the slots smaller
      scrollTime: '07:00:00', // scroll to 7am start
      buttonText: {
        today:    'Today',
        month:    'Month',
        week:     'Week',
        day:      'Day'
      },
      headerToolbar: {
        start: 'prev,next today datePickerButton drawHoldButton drawBookingButton',
        center: 'title',
        end: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth'
      },
      customButtons: {
        datePickerButton: {
          text: '',
          click: () => {}
        },
        drawHoldButton: {
          text: 'Draw Hold',
          click: (_ev, element) => {
            this.toggleDrawingHold(element)
          }
        },
        drawBookingButton: {
          text: '', // blank, to be set dynamically
          click: (_ev, element) => {
            this.toggleDrawingBooking(element)
          }
        }
      },
      viewDidMount: () => {
        if (window.switchPropertyForm) {
          const leftToolbar = document.getElementsByClassName('fc-toolbar-chunk')[0]
          const navButtons = leftToolbar.querySelectorAll('.fc-button-group')[0]
          navButtons.insertAdjacentHTML('beforebegin', window.switchPropertyForm)
        }
        this.drawBookingButton().innerHTML = 'Draw Booking'
      },
      datesSet: () => {
        return this.updateDateHash()
      },
      select: (info) => {
        const start = info.startStr
        const end = info.endStr
        const meetingRoomId = info.resource.id
        if (this.drawingHold) {
          this.createHold(start, end, meetingRoomId)
        } else if (this.drawingBooking && this.bookingId) {
          this.createBookingMeeting(start, end, meetingRoomId)
        }
      },
      resourceAreaColumns: [
        {
          field: 'title',
          headerContent: 'Meeting Room'
        }
      ],
      resources: {
        url: `/meeting_rooms.json?individual=true&property=${this.propertyIdValue}`
      },
      resourceOrder: 'position',
      resourceLaneDidMount: (info) => {
        if (info.resource.id == '0') {
          info.el.style.color = '#bbb'
          info.el.style.background = '#eee'
        }
      },
      resourceLabelDidMount: (info) => {
        if (info.resource.id == '0') {
          info.el.style.color = '#bbb'
          info.el.style.background = '#eee'
        } else {
          if (info.resource.extendedProps && info.resource.extendedProps.content) {
            let questionMark = document.createElement('i')
            questionMark.className = 'fa fa-question-circle left-10 muter'

            info.el.querySelector('.fc-datagrid-cell-main')
              .appendChild(questionMark)

            $(questionMark).tooltip({
              title: info.resource.extendedProps.content,
              html: true,
              placement: 'right',
              trigger: 'hover',
              container: 'body'
            })
          }
        }
      },
      events: {
        url: `/functions.json?property=${window.currentProperty}`
      },
      eventDrop: (info) => {
        this.eventChanged(info, true)
      },
      eventResize: (info) => {
        this.eventChanged(info, false)
      },
      eventClick: (info) => {
        if (!info.event.extendedProps.setupBreakdown) {
          if (info.event.extendedProps.hold) {
            this.showBookingMeetingModal(`/hold_spaces/${info.event.id}/edit`)
          } else {
            this.showBookingMeetingModal(`/booking_meetings/${info.event.id}/edit?fd=true`)
          }
        }
      }
    })
    this.calendar.render()
    this.handleDatePicker()
  }
}