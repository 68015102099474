import './account_and_contact.coffee'
import './controllers/reporting/dashboards.coffee'
import './controllers/reporting/sales.coffee'
import './controllers/reporting/forms.coffee'
import './controllers/grc_days.js'
import './controllers/booking_audits.coffee'
import './controllers/menus.coffee'
import './controllers/rfp_forms.coffee'
import './controllers/tags.coffee'
import './controllers/activity_goals.coffee'
import './controllers/signatures.coffee'
import './controllers/menu_sections.coffee'
import './controllers/menu_categories.coffee'
import './controllers/activities.coffee'
import './controllers/document_statuses.coffee'
import './controllers/preview.js'
import './controllers/volume_contracts.coffee'
import './controllers/availability_charts.coffee'
import './controllers/contacts.coffee'
import './controllers/booking_document_templates.coffee'
import './controllers/site_tour_medias.coffee'
import './controllers/accounts.coffee'
import './controllers/menu_items.coffee'
import './controllers/permissions.coffee'
import './controllers/trackings.coffee'
import './controllers/opportunities.coffee'
import './controllers/booking_documents.coffee'
import './controllers/users.coffee'
import './controllers/menu_templates.coffee'
import './controllers/document_copies.coffee'
import './controllers/function_types.coffee'
import './controllers/user_emails.coffee'
import './controllers/pickups.coffee'
import './controllers/booking_meetings.coffee'
import './controllers/leads.coffee'
import './controllers/bookings.coffee'
import './controllers/documents.coffee'
import './controllers/signers.coffee'
import './controllers/rooms_budgets.coffee'
import './controllers/booking_copies.coffee'
import './controllers/volume_contract_templates.coffee'
import './controllers/send_options.coffee'
import './controllers/intro_videos.coffee'
import './controllers/meeting_rooms.coffee'
import './controllers/floor_plans.js'
import './controllers/events.js'
import './controllers/esignature_fields.coffee'
import './controllers/room_types.coffee'
import './controllers/nest/conversions.coffee'
import './controllers/nest/rfp_form_fields.coffee'
import './controllers/nest/tickets.coffee'
import './controllers/nest/booking_document_templates.coffee'
import './controllers/nest/menu_items.coffee'
import './controllers/nest/clients.coffee'
import './controllers/nest/polygons.coffee'
import './controllers/nest/ticket_messages.coffee'
import './controllers/nest/diagram_meeting_rooms.js'
import './controllers/nest/setups.js'
import './controllers/assets.js'
import './controllers/welcomes.js'
import './controllers/business_insight_report.coffee'
import './global'