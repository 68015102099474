import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = ["onlyShowDefaultProperty", "onlyDefaultProperty", "multilingualSupportForMenus", "localesForMenus"]

  toggle_only_show_default_property() {
    if (this.onlyShowDefaultPropertyTarget.classList.contains("hidden")) {
      this.onlyShowDefaultPropertyTarget.classList.remove("hidden")
    } else {
      this.onlyShowDefaultPropertyTarget.classList.add("hidden")
      this.onlyDefaultPropertyTarget.checked = false
    }
  }

  toggle_multilingual_support_for_menus() {
    if (this.multilingualSupportForMenusTarget.classList.contains("hidden")) {
      this.multilingualSupportForMenusTarget.classList.remove("hidden")
    } else {
      this.multilingualSupportForMenusTarget.classList.add("hidden")
      this.localesForMenusTargets.forEach((checkbox) => {
        checkbox.checked = false;
      })
    }
  }
}
