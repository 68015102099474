(function() {
  var plupload;

  plupload = require('plupload');

  document.addEventListener('turbo:load', function() {
    var handleUploadAttachments;
    // handleTypeahead = ->
    //   venues = new Bloodhound
    //     datumTokenizer: Bloodhound.tokenizers.obj.whitespace('venue_name')
    //     queryTokenizer: Bloodhound.tokenizers.whitespace
    //     limit: 10
    //     remote: '/venues.json?query=%QUERY'
    //   venues.initialize()
    //   template = Hogan.compile('<p><strong>{{value}}</strong><br/><span style="font-size:10px">{{address1}}<br/>{{city}}, {{state}} {{zip}}</span></p>')

    //   options =
    //     minLength: 3

    //   datasets = 
    //     name: 'venue_name'
    //     source: venues.ttAdapter()
    //     displayKey: 'name'
    //     templates:
    //       suggestion: template.render.bind(template)

    //   $('#proposal_venue_name').typeahead options, datasets

    //   $('#proposal_venue_name').on 'typeahead:selected', (event, datum) ->
    //     $('#proposal_address1').val(datum.address1)
    //     $('#proposal_address2').val(datum.address2)
    //     $('#proposal_city').val(datum.city)
    //     $('#proposal_state').val(datum.state)
    //     $('#proposal_zip').val(datum.zip)
    //     $('#proposal_country').val(datum.country)
    //     $('#proposal_latitude').val(datum.latitude)
    //     $('#proposal_longitude').val(datum.longitude)
    //     $('div.venue-lookup').hide()
    handleUploadAttachments = function() {
      var attachmentUploadUrl, attachmentUploader;
      attachmentUploadUrl = `/documents/${window.documentId}/proposal_attachments?proposal_id=${window.proposalId}`;
      attachmentUploader = new plupload.Uploader({
        browse_button: 'proposal-upload-attachments',
        url: attachmentUploadUrl
      });
      attachmentUploader.init();
      attachmentUploader.bind('FilesAdded', function() {
        $('#proposal-upload-attachments-button').hide();
        $('#proposal-upload-attachments-progress').show();
        return attachmentUploader.start();
      });
      return attachmentUploader.bind('UploadComplete', function() {
        $('#proposal-upload-attachments-button').show();
        $('#proposal-upload-attachments-progress').hide();
        return $.get(attachmentUploadUrl, null, 'script');
      });
    };
    // handleTypeahead()
    return handleUploadAttachments();
  });

  $(document).on('input propertychange', 'body.availability_charts #proposal_entered_url', function(event) {
    var code, parts, url;
    url = $(this).val();
    if (url.length > 4) {
      $('#proposal_url').val(url);
      parts = url.split('/');
      code = parts[parts.length - 1];
      if (code.length > 0) {
        return $.getJSON('/documents/data?permalink=' + escape(code), function(data) {
          if (data) {
            $('#proposal_venue_name').val(data.client_name);
            $('#proposal_contact_name').val(data.user.full_name);
            $('#proposal_contact_email').val(data.user.email);
            $('#proposal_contact_phone').val(data.user.phone);
            $('#proposal_dates').val(data.available_dates);
            $('#proposal_rates').val(data.guestroom_rates);
            $('#proposal_rental').val(data.total_rental_revenue_display);
            $('#proposal_fbmin').val(data.total_fb_revenue_display);
            $('#proposal_linked_id').val(data.id);
            $('#proposal_linked_class_name').val(data.class_name);
            $('.proposal_permalink').hide();
            $('.proposal_file').hide();
            $('.proposal_save_venue').hide();
            $('.proposal_save_contact').hide();
            return $('.venue-lookup').hide();
          }
        });
      }
    }
  });

  $(document).on('change', 'body.availability_charts #proposal_client_venue_id', function(event) {
    var attachment, attachments, contact, contacts, i, j, len, len1, results, selected;
    selected = $(this).find(':selected');
    $('#proposal_venue_name').val(selected.data('name'));
    $('#proposal_address1').val(selected.data('address1'));
    $('#proposal_address2').val(selected.data('address2'));
    $('#proposal_city').val(selected.data('city'));
    $('#proposal_state').val(selected.data('state'));
    $('#proposal_zip').val(selected.data('zip'));
    $('#proposal_country').val(selected.data('country'));
    $('#proposal_latitude').val(selected.data('latitude'));
    $('#proposal_longitude').val(selected.data('longitude'));
    $('#proposal_amenities').val(selected.data('amenities'));
    $('#proposal_google_place_id').val(selected.data('google-place-id'));
    $('.proposal_save_venue').hide();
    $('.venue-lookup').hide();
    $('.existing-contact .form-inline').empty();
    contacts = selected.data('contacts');
    if (contacts.length > 0) {
      $('.existing-contact').show();
      for (i = 0, len = contacts.length; i < len; i++) {
        contact = contacts[i];
        $('.existing-contact .form-inline').append(`<a href='javascript:;' class='btn btn-xs btn-info existing-contact-selected' data-name='${contact.name}' data-email='${contact.email}' data-phone='${contact.phone}'>${contact.name}</a> `);
      }
    }
    $('.existing-attachment .form-inline').empty();
    attachments = selected.data('attachments');
    if (attachments.length > 0) {
      $('.existing-attachment').show();
      results = [];
      for (j = 0, len1 = attachments.length; j < len1; j++) {
        attachment = attachments[j];
        results.push($('.existing-attachment .form-inline').append(`<a class='btn btn-xs btn-info existing-attachment-selected' data-remote='true' rel='nofollow' data-method='post' href='/documents/${documentId}/proposal_attachments?attachment=${attachment.id}&proposal=${proposalId}'>${attachment.title}</a> `));
      }
      return results;
    }
  });

  $(document).on('click', 'body.availability_charts .existing-contact-selected', function(event) {
    var $this;
    event.preventDefault();
    $this = $(this);
    $('#proposal_contact_name').val($this.data('name'));
    $('#proposal_contact_email').val($this.data('email'));
    $('#proposal_contact_phone').val($this.data('phone'));
    return $('.proposal_save_contact').hide();
  });

}).call(this);
