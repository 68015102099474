import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ['deletedUsersToggle', 
                    'userCheckbox',
                    'propertyCheckbox',
                    'leadStatusCheckbox',
                    'leadStatusesPanel',
                    'selectedUserCount',
                    'selectedPropertyCount',
                    'selectedLeadStatusCount',
                    'selectViewActive',
                    'selectViewAll',
                    'selectViewSearch',
                    'startDate',
                    'endDate',
                    'dateType',
                    'filters',
                    'search',
                    'searchQuery',
                    'leadsList']

  connect() {
    this.view = 'active' // or 'all' or 'search'
    this.currentUser = this.data.get('currentUser')
    this.ownership = [this.currentUser] // array of user ids
    this.properties = [] // array of property ids
    this.leadStatuses = [] // array of statuses
    this.configureFlatpickr()
    window.handleRowWithSidebarHeight()
  }

  handleEnterInSearch(event) {
    if (event.keyCode === 13) {
      event.preventDefault()
      this.doSearch()
    }
  }

  doSearch() {
    if (this.searchQueryTarget.value) {
      fetch(`/leads?partial=true&q=${this.searchQueryTarget.value}`)
        .then(response => response.text())
        .then(text => this.leadsListTarget.innerHTML = text)
    }
  }

  configureFlatpickr() {
    const endDatePicker = flatpickr(this.endDateTarget, {
      altInput: true,
      altFormat: 'l, F j, Y',
      dateFormat: 'Y-m-d',
      minDate: this.startDateTarget.value,
      onChange: () => {
        this.update()
      }
    })
    flatpickr(this.startDateTarget, {
      altInput: true,
      altFormat: 'l, F j, Y',
      dateFormat: 'Y-m-d',
      onChange: (_selectedDates, selectedDate) => {
        endDatePicker.setDate(selectedDate)
        endDatePicker.set('minDate', selectedDate)
        this.update()
      }
    })
  }

  selectViewActive(event) {
    event.preventDefault()
    this.view = 'active'
    this.selectViewActiveTarget.classList.add('active')
    this.selectViewAllTarget.classList.remove('active')
    this.selectViewSearchTarget.classList.remove('active')
    this.searchQueryTarget.value = ''
    this.leadStatusesPanelTarget.classList.add('hidden')
    this.update()
  }

  selectViewAll(event) {
    event.preventDefault()
    this.view = 'all'
    this.selectViewActiveTarget.classList.remove('active')
    this.selectViewAllTarget.classList.add('active')
    this.selectViewSearchTarget.classList.remove('active')
    this.searchQueryTarget.value = ''
    this.leadStatusesPanelTarget.classList.remove('hidden')
    this.update()
  }

  selectViewSearch(event) {
    event.preventDefault()
    this.view = 'search'
    this.selectViewActiveTarget.classList.remove('active')
    this.selectViewAllTarget.classList.remove('active')
    this.selectViewSearchTarget.classList.add('active')
    this.update()
  }

  selectUserAll() {
    this.userCheckboxTargets.forEach((element) => element.checked = true)
    this.update()
  }

  selectUserMe() {
    this.userCheckboxTargets.forEach((element) => element.checked = false)
    document.querySelectorAll('input.user-me').forEach((element) => element.checked = true)
    this.update()
  }

  selectUserActive() {
    this.userCheckboxTargets.forEach((element) => element.checked = false)
    this.userCheckboxTargets.filter(checkbox => !checkbox.classList.contains('user-deleted'))
                            .forEach((element) => element.checked = true)
    this.update()
  }

  selectPropertyAll() {
    this.propertyCheckboxTargets.forEach((element) => element.checked = true)
    this.update()
  }

  selectPropertyNone() {
    this.propertyCheckboxTargets.forEach((element) => element.checked = false)
    this.update()
  }

  selectLeadStatusAll() {
    this.leadStatusCheckboxTargets.forEach((element) => element.checked = true)
    this.update()
  }

  selectLeadStatusNone() {
    this.leadStatusCheckboxTargets.forEach((element) => element.checked = false)
    this.update()
  }

  toggleDeletedUsers() {
    document.querySelectorAll('.checkbox.deleted').forEach((element) => {
      if (element.classList.contains('hidden')) {
        element.classList.remove('hidden')
        this.deletedUsersToggleTarget.innerHTML = '- Deleted'
      } else {
        element.classList.add('hidden')
        this.deletedUsersToggleTarget.innerHTML = '+ Deleted'
      }
    })
  }

  leadClicked(event) {
    if (event.target.nodeName.toUpperCase() !== 'A') {
      const href = event.currentTarget.dataset.href
      if (href) {
        Turbo.visit(href)
      }
    }
  }

  markStatus(event) {
    const toggle = document.querySelector(`.dropdown-toggle-${event.currentTarget.dataset.leadId}`)
    toggle.innerHTML = `${event.currentTarget.innerHTML} <span class="caret"></span>`
  }

  update() {
    if (this.view === 'search') {
      this.filtersTarget.classList.add('hidden')
      this.searchTarget.classList.remove('hidden')
    } else {
      this.filtersTarget.classList.remove('hidden')
      this.searchTarget.classList.add('hidden')

      // ownership
      const selectedUsers = this.userCheckboxTargets.filter((element) => element.checked)
      this.ownership = [...selectedUsers].map((element) => element.value)
      this.selectedUserCountTarget.innerHTML = selectedUsers.length

      // properties
      if (this.hasPropertyCheckboxTarget) {
        const selectedProperties = this.propertyCheckboxTargets.filter((element) => element.checked)
        this.properties = [...selectedProperties].map((element) => element.value)
        this.selectedPropertyCountTarget.innerHTML = selectedProperties.length
      }
      
      // lead statuses
      const selectedLeadStatuses = this.leadStatusCheckboxTargets.filter((element) => element.checked)
      this.leadStatuses = [...selectedLeadStatuses].map((element) => element.value)
      this.selectedLeadStatusCountTarget.innerHTML = selectedLeadStatuses.length

      const p = {
        partial: 'true',
        view: this.view,
        ownership: this.ownership,
        leadStatuses: this.leadStatuses,
        properties: this.properties,
        startDate: this.startDateTarget.value,
        endDate: this.endDateTarget.value,
        dateType: this.dateTypeTargets.filter(radio => radio.checked)[0].value
      }

      const url = `/leads?${new URLSearchParams(p).toString()}`
      fetch(url).then(response => response.text())
                .then(text => this.leadsListTarget.innerHTML = text)
    }
  }
}