import { Controller } from '@hotwired/stimulus'
import { Calendar } from '@fullcalendar/core'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'

export default class extends Controller {
  static targets = ['timeline', 'currentDateRange']
  static values = {
    propertyId: Number,
    defaultStart: String,
    defaultView: String
  }

  connect() {
    this.buildCalendar()
  }

  csrfToken() {
    return document.querySelector("[name='csrf-token']").content
  }

  roomsFrameLoaded() {
    const columns = Array.from(document.querySelectorAll('th.info'))
    const infoColumnsWidth = columns.reduce(
      (total, column) => column.getBoundingClientRect().width + total,
      0
    )
    if (infoColumnsWidth > 0) {
      this.calendar.setOption('resourceAreaWidth', `${infoColumnsWidth}px`)
    }
    window.unblockUI()
    $('a.with-tooltip').tooltip()
  }

  showInformationModal(event) {
    event.preventDefault()
    let target = '/booking_informations/'
    if (event.params.bookingId) {
      target += `${event.params.bookingId}`
    } else {
      target += `${event.params.holdId}?hold=true`
    }
    Turbo.visit(
      target, 
      { frame: 'booking_information_frame' }
    )
    $('.availability-modal').show()
  }

  hideInformationModal() {
    $('.availability-modal').hide()
  }

  updateDateHash() {
    const date = this.calendar.getDate().toISOString().split('T')[0]
    const view = this.calendar.view.type
    return window.location.hash = `#${date}.${view}`
  }

  getCurrentDateRange() {
    const view = this.calendar.view
    const currentStart = view.currentStart
    const timeZone = this.calendar.getOption('timeZone')

    switch(view.type) {
      case 'resourceTimelineDay':
        return this.calendar.formatDate(currentStart, {
          timeZone: timeZone,
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long'
        })
      case 'resourceTimelineSevenDays':
        const formattedDate = this.calendar.formatDate(currentStart, {
          timeZone: timeZone,
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
        return `Week of ${formattedDate}`
      case 'resourceTimelineMonth':
        return this.calendar.formatDate(currentStart, {
          timeZone: timeZone,
          year: 'numeric',
          month: 'long'
        })
    }
  }

  prev(event) {
    event.preventDefault()
    this.calendar.prev()
  }

  next(event) {
    event.preventDefault()
    this.calendar.next()
  }

  day(event) {
    event.preventDefault()
    this.calendar.changeView('resourceTimelineDay')
  }

  week(event) {
    event.preventDefault()
    this.calendar.changeView('resourceTimelineSevenDays')
  }

  month(event) {
    event.preventDefault()
    this.calendar.changeView('resourceTimelineMonth')
  }

  today(event) {
    event.preventDefault()
    this.calendar.today()
  }

  handleDatePicker() {
    const $dp = $('<input type="text" class="hide fd-datepicker">')
    $('.go-to-date').append($dp)
    const dateOptions = {
      buttonText: "<i class='fa fa-calendar'></i>",
      changeMonth: true,
      changeYear: true,
      dateFormat: 'yy-mm-dd',
      showOn: 'both'
    }
    this.datePicker = $dp.datepicker($.extend({}, dateOptions, {
      onSelect: (selectedDate) => {
        return this.calendar.gotoDate(selectedDate)
      }
    }))
  }

  reRenderCalendar() {
    this.calendar.refetchEvents()
  }

  // TODO unused
  setCalendarHeight() {
    const h = window.innerHeight - 
      document.querySelector('.navbar-top').offsetHeight - 
      document.querySelector('.availability-nav').offsetHeight - 
      document.querySelector('.guest-rooms-panel').offsetHeight - 
      70
    this.calendar.setOption('height', h)
  }

  buildCalendar() {
    let initialDate, initialView
    if (window.location.hash) {
      const dateAndView = window.location.hash.replace('#','').split('.')
      const date = dateAndView[0]
      const view = dateAndView[1] || 'resourceTimelineDay'
      initialDate = moment(date).format('YYYY-MM-DD')
      initialView = view
    } else {
      initialDate = this.defaultStartValue
      initialView = this.defaultViewValue
    }

    this.calendar = new Calendar(this.timelineTarget, {
      schedulerLicenseKey: '0400913918-fcs-1689610790',
      plugins: [resourceTimelinePlugin],
      initialDate,
      initialView,
      timeZone: window.timeZone,
      eventTimeFormat: calendarTimeFormatEvent,
      resourceAreaWidth: '200px',
      slotDuration: '00:15:00',
      slotMinWidth: 20, // makes the slots smaller
      scrollTime: '07:00:00', // scroll to 7am start
      headerToolbar: false,
      datesSet: (dateInfo) => {
        this.currentDateRangeTarget.innerHTML = this.getCurrentDateRange()
        Turbo.visit(
          `/room_availabilities?view_date=${this.calendar.getDate().toISOString().split('T')[0]}&view_type=${this.calendar.view.type}&from_date=${dateInfo.start.toISOString()}&to_date=${dateInfo.end.toISOString()}`, 
          { frame: 'rooms' }
        )
        return this.updateDateHash()
      },
      resourceAreaColumns: [
        {
          field: 'title',
          headerContent: 'Meeting Room'
        }
      ],
      resources: {
        url: `/meeting_rooms.json?individual=true&property=${this.propertyIdValue}`
      },
      resourceOrder: 'position',
      resourceLaneDidMount: (info) => {
        if (info.resource.id == '0') {
          info.el.style.color = '#bbb'
          info.el.style.background = '#eee'
        }
      },
      resourceLabelDidMount: (info) => {
        if (info.resource.id == '0') {
          info.el.style.color = '#bbb'
          info.el.style.background = '#eee'
        } else {
          if (info.resource.extendedProps && info.resource.extendedProps.content) {
            let questionMark = document.createElement('i')
            questionMark.className = 'fa fa-question-circle left-10 muter'

            info.el.querySelector('.fc-datagrid-cell-main')
              .appendChild(questionMark)

            $(questionMark).tooltip({
              title: info.resource.extendedProps.content,
              html: true,
              placement: 'right',
              trigger: 'hover',
              container: 'body'
            })
          }
        }
      },
      events: {
        url: `/functions.json?property=${window.currentProperty}`
      },
      eventClick: (eventArg) => {
        let e = new Event('eventClick')
        e.params = { 
          bookingId: eventArg.event.extendedProps.hold ? null : eventArg.event.extendedProps.bookingId,
          holdId: eventArg.event.extendedProps.hold ? eventArg.event.id : null
        }
        this.showInformationModal(e)
      },
      eventDidMount: function(info) {
        // don't show the setup/breakdown
        if (info.event.extendedProps.setupBreakdown) {
          info.el.style.display = 'none'
        }
      },
      eventContent: (info) => {
        const start = this.calendar.formatDate(info.event.start, {
          timeZone: this.calendar.timeZone,
          hour: '2-digit',
          minute: '2-digit'
        })
        return { html: info.event.extendedProps.setupBreakdown ? '&nbsp;' : `<strong>${start}</strong> ${info.event.extendedProps.shortTitle}` }
      },
      loading: function(isLoading) {
        if (isLoading) {
          window.pleaseWait()
        }
      },
      views: {
        resourceTimelineSevenDays: {
          type: 'resourceTimeline',
          duration: { days: 7 },
          slotDuration: '24:00:00',
          slotLabelInterval: { days: 1 },
          slotLabelFormat: (args) => {
            const date = args.date
            const dayOfWeek = this.calendar.formatDate(date.marker, {
              timeZone: this.calendar.timeZone,
              weekday: 'narrow'
            })
            return `${date.day}${dayOfWeek}`
          }
        },
        resourceTimelineMonth: {
          slotLabelFormat: (args) => {
            const date = args.date
            const dayOfWeek = this.calendar.formatDate(date.marker, {
              timeZone: this.calendar.timeZone,
              weekday: 'narrow'
            })
            return `${date.day}${dayOfWeek}`
          }
        }
      }
    })
    this.calendar.render()
    this.handleDatePicker()
  }
}