import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['checkbox']
  static values = {
    bookingId: Number
  }

  updateBookingDocument(event) {
    patch(`/bookings/${this.bookingIdValue}/booking_documents/${event.params.bookingDocumentId}.js`,
      { body: JSON.stringify({ booking_document: { on_event_dashboard: event.target.checked } }) })
  }

  updateDocument(event) {
    patch(`/documents/${event.params.documentId}.js`,
      { body: JSON.stringify({ document: { on_event_dashboard: event.target.checked } }) })
  }

  updateBanquetCheck(event) {
    patch(`/bookings/${this.bookingIdValue}.js`,
      { body: JSON.stringify({ booking: { banquet_check_on_event_dashboard: event.target.checked }, on_event_dashboard: true }) })
  }
}