import ClipboardJS from 'clipboard';

document.addEventListener('turbo:load', function() {
  var $body, clipboard;
  $('.reveal-cc-auth-form-data').popover({
    html: true
  });
  $(document).on('click', '.copy-signable-link-to-clipboard', function(event) {
    return event.preventDefault();
  });
  $body = $('body');
  if ($body.hasClass('signatures') && $body.hasClass('show')) {
    clipboard = new ClipboardJS('.copy-signable-link-to-clipboard');
    return clipboard.on('success', function(event) {
      var $clipButton, cb;
      $clipButton = $(event.trigger);
      $clipButton.tooltip({
        title: 'Copied!'
      });
      $clipButton.tooltip('show');
      cb = function(elem) {
        return elem.tooltip('destroy');
      };
      return setTimeout((function() {
        return cb($clipButton);
      }), 1500);
    });
  }
});
