(function() {
  document.addEventListener('turbo:load', function() {
    var $body, timepickerOptions;
    $body = $('body');
    if ($body.hasClass('function_types') || $body.hasClass('nest-function_types')) {
      timepickerOptions = {
        timeFormat: timepickerFormat
      };
      $('#function_type_start').timepicker(timepickerOptions);
      return $('#function_type_end').timepicker(timepickerOptions);
    }
  });

}).call(this);
