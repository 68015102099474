import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['startButton', 'fields', 'saveBookmarkField', 'submitButton']

  connect() {
    console.log('Bookmarkable controller connected')
  }

  showBookmarkFields() {
    this.startButtonTarget.classList.add('disabled')
    this.fieldsTarget.classList.remove('d-none')
    this.saveBookmarkFieldTarget.value = true
  }

  hideBookmarkFields() {
    this.fieldsTarget.classList.add('d-none')
    this.saveBookmarkFieldTarget.value = false
  }

  enableBookmarking() {
    this.startButtonTarget.classList.remove('disabled')
    this.submitButtonTarget.classList.remove('disabled')
  }

  disableBookmarking() {
    this.hideBookmarkFields()
    this.startButtonTarget.classList.add('disabled')
    this.submitButtonTarget.classList.add('disabled')
  }

  cancel() {
    this.startButtonTarget.classList.remove('disabled')
    this.fieldsTarget.classList.add('d-none')
    this.saveBookmarkFieldTarget.value = false
  }

  toggleBookmarkable(e) {
    if (e.currentTarget.dataset.bookmarkable === 'true') {
      this.enableBookmarking()
    } else {
      this.disableBookmarking()
    }
  }

}