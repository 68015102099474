import Rails from '@rails/ujs'
import { Controller } from '@hotwired/stimulus'
import throttle from 'lodash/throttle'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ['startDate', 
                    'endDate', 
                    'searchForm', 
                    'sidebar',
                    'filterForm', 
                    'searchInput', 
                    'clearSearchButton']

  connect() {
    window.handleRowWithSidebarHeight()
    this.configureFlatpickr()
    this.searchInputTarget.addEventListener('keyup', throttle(this.listenForSearch, 1000).bind(this))
    this.searchInputTarget.addEventListener('keydown', event => {
      if (event.keyCode == 13) {
        event.preventDefault()
        return false
      } else if (event.keyCode == 27) {
        this.clearSearch()
      }
    })
  }

  listenForSearch(event) {
    this.query = event.target.value
    if (this.query.length == 0) {
      this.clearSearchButtonTarget.classList.add('hidden')
      Rails.fire(this.searchFormTarget, 'submit')
      this.sidebarTarget.classList.remove('hidden')
    } else if (this.query.length >= 3) {
      this.clearSearchButtonTarget.classList.remove('hidden')
      Rails.fire(this.searchFormTarget, 'submit')
      this.sidebarTarget.classList.add('hidden')
    } else {
      this.clearSearchButtonTarget.classList.remove('hidden')
      this.sidebarTarget.classList.remove('hidden')
    }
  }

  clearSearch() {
    this.searchInputTarget.value = ''
    this.clearSearchButtonTarget.classList.add('hidden')
    Rails.fire(this.searchFormTarget, 'submit')
    this.sidebarTarget.classList.remove('hidden')
  }

  configureFlatpickr() {
    this.endDatePicker = flatpickr(this.endDateTarget, {
      altInput: true,
      altFormat: 'l, F j, Y',
      dateFormat: 'Y-m-d',
      minDate: this.startDateTarget.value,
      onChange: () => {
        this.update()
      }
    })
    this.startDatePicker = flatpickr(this.startDateTarget, {
      altInput: true,
      altFormat: 'l, F j, Y',
      dateFormat: 'Y-m-d',
      onChange: (_selectedDates, selectedDate) => {
        this.endDatePicker.set('minDate', selectedDate)
        if (selectedDate > this.endDateTarget.value) {
          this.endDatePicker.setDate(selectedDate)
          this.update()
        }
        this.update()
      }
    })
  }

  fillDates(event) {
    event.preventDefault()
    this.endDatePicker.set('minDate', event.target.dataset.startDate)
    this.startDatePicker.setDate(event.target.dataset.startDate)
    this.endDatePicker.setDate(event.target.dataset.endDate)
    this.update()
  }

  update() {
    Rails.fire(this.filterFormTarget, 'submit')
  }
}

