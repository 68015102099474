import React from 'react'
import BookingActionButton from './BookingActionButton'
import moment from 'moment'

const renderGroupType = (groupType) => {
  switch (groupType) {
    case 'rooms':
      return (<i className="fa fa-bed"></i>)
    case 'catering':
      return (<i className="fa fa-cutlery"></i>)
    case 'both':
      return (
        <div>
          <i className="fa fa-bed"></i>
          &nbsp;
          <i className="fa fa-cutlery"></i>
        </div>
      )
  }
}

const BookingTableRow = (props) => {
  var datesFormatted = moment(props.startDate).format('MM-DD-YYYY') + " - " + moment(props.endDate).format('MM-DD-YYYY')
  var sentToPmsCheck = ''
  if (props.groupType == 'catering')
    sentToPmsCheck = ''
  else if (props.sentToPms) {
    sentToPmsCheck = <i className="fa fa-check-circle-fa fa-fixed fa-2x text-success"></i>
  }
  else if (props.status == 'tentative') {
    sentToPmsCheck = <i className="fa fa-minus-circle fa-fixed fa-2x text-warning"></i>
  }
  else if (props.status == 'definite') {
    sentToPmsCheck = <i className="fa fa-times-circle fa-fixed fa-2x text-danger"></i>
  }
  
  return (
    <tr>
      <td>
        <BookingActionButton bookingId={props.bookingId} startDate={props.startDate} ownership={props.ownership} />
      </td>
      {props.multiprop &&
        <td>{props.propertyName}</td>
      }
      <td>{renderGroupType(props.groupType)}</td>
      <td>
        <strong>{props.companyName}</strong><br/>
        {props.contactName}<br/>
        <a href={'mailto:' + props.email}>{props.email}</a>
      </td>
      <td>
        <strong><a href={'/bookings/' + props.bookingId + '/edit/general'}>{props.name}</a></strong><br />
        <span className="muter">#{props.bookingId}</span>
      </td>
      <td>{props.displayDates}</td>
      <td>{props.salesPerson}</td>
      <td>{props.status.titleCase()}</td>
      {props.pmsIntegrated &&
        <td>{sentToPmsCheck}</td>
      }
      <td>{window.formatDate(props.lastUpdated)}</td>
    </tr>
  )
}

export default BookingTableRow