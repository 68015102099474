import Bloodhound from 'bloodhound-js';

import Hogan from 'hogan.js';

import moment from 'moment';

$(document).on('click', 'a.lookup-by-email', function(event) {
  event.preventDefault();
  return $('div#lookup-by-email-modal').modal('show');
});

document.addEventListener('turbo:load', function() {
  var $body, accountIdParam, accountResults, accountSelected, doClearbitSearch, doEmailSearch, onAccountAndContactClearbitSuccess, setAccountBlockVisibility, setAccountStatusToActiveOption, template;
  accountSelected = function(datum) {
    var select;
    if (datum.id === 'lookup') {
      return doClearbitSearch(datum.email);
    } else {
      $(".search-box").hide();
      $(".result").show();
      $('fieldset.account-information legend').html(`Update account and contact information for ${datum.value}`);
      $(".account-name").html(datum.value);
      $(`.${model}_new_account_name .help-block`).html('Enter new account name (or email address) or lookup existing by name, contact name, or email address.');
      $(`#${model}_new_account_name`).val('');
      $(`#${model}_account_id`).val(datum.id);
      $(`#${model}_new_account_account_status`).val(datum.account_status).trigger('change');
      $(`#${model}_new_account_market_segments`).val(datum.market_segments).trigger('change');
      $(`#${model}_new_account_address`).val(datum.account_id);
      $(`#${model}_new_account_address1`).val(datum.address1);
      $(`#${model}_new_account_address2`).val(datum.address2);
      $(`#${model}_new_account_city`).val(datum.city);
      $(`#${model}_new_account_state`).val(datum.state);
      $(`#${model}_new_account_zip`).val(datum.zip);
      $(`#${model}_new_account_country`).val(datum.country);
      $(`#${model}_new_account_website`).val(datum.website);
      $(`#${model}_new_account_notes`).val(datum.notes);
      $(`#${model}_contact_id`).val(datum.first_contact_id);
      $(`#${model}_new_contact_first_name`).val(datum.first_contact_first_name);
      $(`#${model}_new_contact_last_name`).val(datum.first_contact_last_name);
      $(`#${model}_new_contact_title`).val(datum.first_contact_title);
      $(`#${model}_new_contact_phone`).val(datum.first_contact_phone);
      $(`#${model}_new_contact_email`).val(datum.first_contact_email);
      $(`#${model}_new_contact_address`).val(datum.first_contact_address);
      $(`#${model}_new_contact_address2`).val(datum.first_contact_address2);
      $(`#${model}_new_contact_city`).val(datum.first_contact_city);
      $(`#${model}_new_contact_state`).val(datum.first_contact_state);
      $(`#${model}_new_contact_zip`).val(datum.first_contact_zip);
      $(`#${model}_new_contact_country`).val(datum.first_contact_country);
      $(`#${model}_new_contact_twitter`).val(datum.first_contact_twitter);
      $(`#${model}_new_contact_linkedin_account`).val(datum.first_contact_linkedin_account);
      if (datum.contacts.length > 0) {
        select = "<select id=\"contacts\" style=\"width:100%\">";
        select += "<option value=\"0\">-- New Contact --</option>";
        datum.contacts.forEach(function(contact, index) {
          var attributes, selected;
          attributes = [];
          attributes.push(`data-first-name='${contact.first_name}'`);
          attributes.push(`data-last-name='${contact.last_name}'`);
          attributes.push(`data-title='${contact.title}'`);
          attributes.push(`data-phone='${contact.phone}'`);
          attributes.push(`data-email='${contact.email}'`);
          attributes.push(`data-address='${contact.address}'`);
          attributes.push(`data-address2='${contact.address2}'`);
          attributes.push(`data-city='${contact.city}'`);
          attributes.push(`data-state='${contact.state}'`);
          attributes.push(`data-zip='${contact.zip}'`);
          attributes.push(`data-twitter='${contact.twitter}'`);
          attributes.push(`data-linkedin-account='${contact.linkedin_account}'`);
          selected = index === 0 ? 'selected' : '';
          return select += `<option value=\"${contact.id}\" ${attributes.join(' ')} ${selected}>${contact.name}</option>`;
        });
        select += "</select><br>";
      }
      $(".form-group.contacts").prepend(select);
      $("select#contacts").select2(window.select2Options);
      // handle corporate account
      if (datum.client_id !== currentClientId) {
        return setAccountBlockVisibility(false);
      }
    }
  };
  setAccountStatusToActiveOption = function() {
    var activeOption;
    activeOption = $($(`select#${model}_new_account_account_status option`).filter(function() {
      return $(this).html() === 'Active';
    })).val();
    return $(`select#${model}_new_account_account_status`).val(activeOption).trigger('change');
  };
  setAccountBlockVisibility = function(visible) {
    $('fieldset.account-information.account').toggle(visible);
    $(`#${model}_new_account_account_status`).prop('disabled', !visible);
    $(`#${model}_new_account_market_segments`).prop('disabled', !visible);
    $(`#${model}_new_account_address`).prop('disabled', !visible);
    $(`#${model}_new_account_address1`).prop('disabled', !visible);
    $(`#${model}_new_account_address2`).prop('disabled', !visible);
    $(`#${model}_new_account_city`).prop('disabled', !visible);
    $(`#${model}_new_account_state`).prop('disabled', !visible);
    $(`#${model}_new_account_zip`).prop('disabled', !visible);
    $(`#${model}_new_account_country`).prop('disabled', !visible);
    $(`#${model}_new_account_website`).prop('disabled', !visible);
    return $(`#${model}_new_account_notes`).prop('disabled', !visible);
  };
  $body = $('body');
  if ($body.hasClass('bookings new') || $body.hasClass('activities new') || $body.hasClass('functions index') || $body.hasClass('booking_merges new') || $body.hasClass('opportunities new') || $body.hasClass('opportunity_merges new')) {
    setAccountStatusToActiveOption();
    console.log('listening');
    accountResults = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace("name"),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: "/accounts-search.json?query=%QUERY",
        wildcard: '%QUERY'
      }
    });
    accountResults.initialize();
    template = Hogan.compile("<span class='point'><p><strong>{{{value}}}</strong><br/>{{city_state}}</p></span>");
    $(`#${model}_new_account_name`).typeahead({
      minLength: 3,
      hint: false
    }, {
      name: "name",
      source: accountResults.ttAdapter(),
      limit: 100,
      displayKey: 'name',
      templates: {
        suggestion: template.render.bind(template)
      }
    });
    $(`#${model}_new_account_name`).on("typeahead:selected", function(event, datum) {
      return accountSelected(datum);
    });
    $(document).on("change", "#contacts", function(event) {
      var selected;
      selected = $(this).find("option:selected");
      if (event.target.options.selectedIndex === 0) {
        $(`#${model}_contact_id`).val(selected.val());
        $(`#${model}_new_contact_first_name`).val(window.bookingApp.newContactFirstName);
        $(`#${model}_new_contact_last_name`).val(window.bookingApp.newContactLastName);
        $(`#${model}_new_contact_title`).val(window.bookingApp.newContactTitle);
        $(`#${model}_new_contact_phone`).val(window.bookingApp.newContactPhone);
        $(`#${model}_new_contact_email`).val(window.bookingApp.newContactEmail);
        $(`#${model}_new_contact_address`).val(window.bookingApp.newAccountAddress1);
        $(`#${model}_new_contact_address2`).val(window.bookingApp.newAccountAddress2);
        $(`#${model}_new_contact_city`).val(window.bookingApp.newAccountCity);
        $(`#${model}_new_contact_state`).val(window.bookingApp.newAccountState);
        $(`#${model}_new_contact_zip`).val(window.bookingApp.newAccountZip);
        $(`#${model}_new_contact_twitter`).val(window.bookingApp.newAccountTwitter);
        return $(`#${model}_new_contact_linkedin_account`).val(window.bookingApp.newAccountLinkedin);
      } else {
        $(`#${model}_contact_id`).val(selected.val());
        $(`#${model}_new_contact_first_name`).val(selected.data("first-name"));
        $(`#${model}_new_contact_last_name`).val(selected.data("last-name"));
        $(`#${model}_new_contact_title`).val(selected.data("title"));
        $(`#${model}_new_contact_phone`).val(selected.data("phone"));
        $(`#${model}_new_contact_email`).val(selected.data("email"));
        $(`#${model}_new_contact_address`).val(selected.data("address"));
        $(`#${model}_new_contact_address2`).val(selected.data("address2"));
        $(`#${model}_new_contact_city`).val(selected.data("city"));
        $(`#${model}_new_contact_state`).val(selected.data("state"));
        $(`#${model}_new_contact_zip`).val(selected.data("zip"));
        $(`#${model}_new_contact_twitter`).val(selected.data("twitter"));
        return $(`#${model}_new_contact_linkedin_account`).val(selected.data("linkedin_account"));
      }
    });
    $(".different-account a").on("click", function(event) {
      event.preventDefault();
      $(".result").hide();
      $(".search-box").show();
      setAccountBlockVisibility(true);
      $('fieldset.account-information legend').html('New account and contact information');
      $(`#${model}_account_id`).val("");
      $(`#${model}_contact_id`).val("");
      setAccountStatusToActiveOption();
      $(`.${model}_new_account_name .help-block`).html('Enter new account name (or email address) or lookup existing by name, contact name, or email address.');
      $(`#${model}_new_account_market_segments`).val("").trigger('change');
      $(`#${model}_new_account_name`).val("");
      $(`#${model}_new_account_address`).val("");
      $(`#${model}_new_account_address1`).val("");
      $(`#${model}_new_account_address2`).val("");
      $(`#${model}_new_account_city`).val("");
      $(`#${model}_new_account_state`).val("");
      $(`#${model}_new_account_zip`).val("");
      $(`#${model}_new_contact_first_name`).val("");
      $(`#${model}_new_contact_last_name`).val("");
      $(`#${model}_new_contact_title`).val("");
      $(`#${model}_new_contact_phone`).val("");
      $(`#${model}_new_contact_email`).val("");
      $(`#${model}_new_contact_address`).val("");
      $(`#${model}_new_contact_address2`).val("");
      $(`#${model}_new_contact_city`).val("");
      $(`#${model}_new_contact_state`).val("");
      $(`#${model}_new_contact_zip`).val("");
      $("select#contacts").remove();
      return $(".contacts .select2").remove();
    });
    // clearbit search
    onAccountAndContactClearbitSuccess = function(data) {
      var addr_data;
      $.unblockUI();
      if (data.company) {
        // $("##{model}_new_account_name").val data.company.name
        $(`.${model}_new_account_name .help-block`).html("A new account will be created.");
        $(`#${model}_new_account_name`).typeahead('val', data.company.name);
        $(`#${model}_new_account_website`).val(data.company.url);
        $(`#${model}_new_account_notes`).val(data.company.description);
        $(`#${model}_new_account_logo_url`).val(data.company.logo);
        if (data.company.location) {
          addr_data = data.company.location.split(', ');
          if (addr_data.length === 4) {
            $(`#${model}_new_account_address1`).val(addr_data[0]);
            $(`#${model}_new_account_city`).val(addr_data[1]);
            $(`#${model}_new_account_state`).val(addr_data[2].split(' ')[0]);
            $(`#${model}_new_account_zip`).val(addr_data[2].split(' ')[1]);
            $(`#${model}_new_account_country`).val(addr_data[3]);
          } else if (addr_data.length === 5) {
            $(`#${model}_new_account_address1`).val(addr_data[0]);
            $(`#${model}_new_account_address2`).val(addr_data[1]);
            $(`#${model}_new_account_city`).val(addr_data[3]);
            $(`#${model}_new_account_state`).val(addr_data[4].split(' ')[0]);
            $(`#${model}_new_account_zip`).val(addr_data[4].split(' ')[1]);
            $(`#${model}_new_account_country`).val(addr_data[4].split(' ')[2]);
          }
        }
        if (data.person) {
          $(`#${model}_new_contact_first_name`).val(data.person.name.givenName);
          $(`#${model}_new_contact_last_name`).val(data.person.name.familyName);
          $(`#${model}_new_contact_avatar_url`).val(data.person.avatar);
          $(`#${model}_new_contact_twitter`).val(data.person.twitter.handle);
          if (data.person.employment && data.person.employment.title) {
            $(`#${model}_new_contact_title`).val(data.person.employment.title);
          }
          if (data.person.linkedin.handle) {
            return $(`#${model}_new_contact_linkedin_account`).val(`http://linkedin.com/${data.person.linkedin.handle}`);
          }
        }
      }
    };
    doClearbitSearch = function(val) {
      clearbitBlockUI();
      $.post('/clearbit_calls', {
        q: val,
        format: 'json'
      }, onAccountAndContactClearbitSuccess);
      $('div#lookup-by-email-modal').modal('hide');
      return $(`#${model}_new_contact_email`).val(val);
    };
    doEmailSearch = function(val) {
      return $.post(`/accounts_by_email.json?email=${val}`, function(data) {
        var $el, account, i, len;
        if (data.length > 0) {
          $('.existing-accounts').empty();
          $('.continue-new-account').data('email', val);
          for (i = 0, len = data.length; i < len; i++) {
            account = data[i];
            $el = $(`<li><a href=\"#\" class=\"btn btn-info existing-account-selected\" data-id=\"${account.id}\"><i class=\"fa fa-folder\"></i> ${account.name}</a></li>`);
            $('.existing-accounts').append($el);
          }
          return $('.existing-accounts-found').show();
        } else {
          return doClearbitSearch(val);
        }
      });
    };
    $(document).on('click', '.existing-account-selected', function(event) {
      event.preventDefault();
      return $.get(`/accounts/${$(this).data('id')}.json`, function(data) {
        accountSelected(data);
        return $('div#lookup-by-email-modal').modal('hide');
      });
    });
    $(document).on('click', '.continue-new-account', function(event) {
      event.preventDefault();
      return doClearbitSearch($(this).data('email'));
    });
    $(document).on('paste', '#email_to_lookup', function(event) {
      var valByPaste;
      valByPaste = event.originalEvent.clipboardData.getData('text');
      if (valByPaste.length > 0) {
        return doEmailSearch(valByPaste);
      }
    });
    $(document).on('click', '.lookup-by-email-button', function(event) {
      var val;
      event.preventDefault();
      val = $('#email_to_lookup').val().trim();
      return doEmailSearch(val);
    });
    $(document).on('blur', `#${model}_new_account_name`, function(event) {
      if ($(this).val().length > 0) {
        return $.getJSON(`/accounts/exact_match?name=${$(this).val()}`, function(data) {
          var text;
          text = "A new account will be created.";
          if (data.count === 1) {
            text += " <span class='highlighted-on-white'>There is an existing account with this same name.</span>";
          } else if (data.count > 0) {
            text += ` <span class='highlighted-on-white'>There are ${data.count} existing accounts with this same name.</span>`;
          }
          return $(`.${model}_new_account_name .help-block`).html(text);
        });
      }
    });
    $(document).on('keyup keypress', '#email_to_lookup', function(e) {
      var code, val;
      code = e.keyCode || e.which;
      if (code === 13) {
        e.preventDefault();
        val = $('#email_to_lookup').val().trim();
        return doEmailSearch(val);
      }
    });
    // Account ID is passed from account form
    accountIdParam = window.getParameterByName('account_id');
    if (accountIdParam.length > 0) {
      return $.getJSON(`/accounts-search.json?account_id=${accountIdParam}`, function(data) {
        return accountSelected(data[0]);
      });
    }
  }
});
