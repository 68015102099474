import React from 'react'
import BookingDateRangePicker from './BookingDateRangePicker'
import BookingsFilterPanel from './BookingsFilterPanel'
class BookingsSidebar extends React.Component {
  constructor() {
    super()
    this.onSearchFormSubmitted = this.onSearchFormSubmitted.bind(this)
  }

  onSearchFormSubmitted(event) {
    event.preventDefault()
    this.props.onSearchFormSubmitted(this.refs.searchQuery.value)
  }

  render() {
    const propertiesCheckboxes = this.props.allProperties.map(function(property) {
      var checked = $.inArray(property.id, this.props.properties) > -1
      return (
        <div key={property.id} className="checkbox">
          <label>
            <input ref={property.id} type="checkbox" value={property.id} checked={checked} onChange={this.props.onPropertySelected} />
            {property.name}
          </label>
        </div>
      )
    }.bind(this))
    const statusesCheckboxes = this.props.allStatuses.map(function(status) {
      var checked = $.inArray(status, this.props.statuses) > -1
      return (
        <div key={status} className="checkbox">
          <label>
            <input ref={status} type="checkbox" value={status} checked={checked} onChange={this.props.onStatusSelected} />
            {status.titleCase()}
          </label>
        </div>
      )
    }.bind(this))
    let users
    if (this.props.showDeletedUsers) {
      users = this.props.allUsers
    } else {
      users = this.props.allUsers.filter(user => !user.deleted)
    }
    const usersCheckboxes = users.map(function(user) {
      var checked = $.inArray(user.id, this.props.users) > -1
      return (
        <div key={user.id} className="checkbox">
          <label>
            <input ref={user.id} type="checkbox" value={user.id} checked={checked} onChange={this.props.onUserSelected} />
            {user.first_name} {user.last_name}
            {user.deleted &&
              <span className="label label-default" style={{ marginLeft: '5px' }}>Deleted</span>
            }
          </label>
        </div>
      )
    }.bind(this))
    const usersCheckboxesMarkup = (
      <div>
        <p>
          <a onClick={this.props.onSelectSalesManagerAll} className="btn btn-xs btn-info">All</a>&nbsp;&nbsp;
          <a onClick={this.props.onSelectSalesManagerMe} className="btn btn-xs btn-info">Just Me</a>&nbsp;&nbsp;
          <a onClick={this.props.onSelectSalesManagerActive} className="btn btn-xs btn-info">Active</a>&nbsp;&nbsp;
          <a onClick={this.props.toggleShowDeletedUsers} className="btn btn-xs btn-warning">
            {this.props.showDeletedUsers ? 'Hide Deleted' : 'Show Deleted'}
          </a>
        </p>
        {usersCheckboxes}
      </div>
    );
    return (
      <div className="col-md-2 sidebar">
        <h6 className="text-uppercase">Filter Bookings</h6>
        <form onSubmit={this.onSearchFormSubmitted} style={{ display: this.props.ownership == 'search' ? 'inline' : 'none' }}>
          <div className="form-group string required search_query">
            <i className="fa fa-search"></i>
            &nbsp;
            <input ref="searchQuery" 
                   className="string required input-small" 
                   placeholder="Search&hellip;" 
                   type="text"
                   defaultValue={this.props.searchText} />
          </div>
        </form>
        <div style={{ display: this.props.ownership == 'search' || this.props.ownership == 'drafts' ? 'none' : 'inline' }}>
          <BookingDateRangePicker searchType={this.props.searchType} startDate={this.props.startDate} endDate={this.props.endDate} onDateRangeChanged={this.props.onDateRangeChanged} />
          <br/>
          <div className="panel-group" id="accordion" role="tablist">
            {this.props.multiprop && <BookingsFilterPanel collection={this.props.properties} checkboxes={propertiesCheckboxes} title="Property" />}
            <BookingsFilterPanel collection={this.props.statuses} checkboxes={statusesCheckboxes} title="Booking Status" />
            <BookingsFilterPanel collection={this.props.users} checkboxes={usersCheckboxesMarkup} title="Sales Manager" />
          </div>
        </div>
      </div>
    )
  }
}

export default BookingsSidebar