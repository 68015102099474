(function() {
  document.addEventListener('turbo:load', function() {
    return $('body.accounts #account_new_tag_names, body.contacts #contact_new_tag_names').select2({
      tags: true,
      tokenSeparators: [','],
      placeholder: 'Add a tag',
      createTag: function(tag) {
        return {
          id: tag.term,
          text: tag.term,
          tag: true
        };
      }
    });
  });

}).call(this);
