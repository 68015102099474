(function() {
  $(document).on('click', 'body.opportunities.index table.opportunities-table tbody tr a', function(event) {
    return event.stopPropagation();
  });

  $(document).on('click', 'body.opportunities.index table.opportunities-table tbody tr', function(event) {
    event.preventDefault();
    if ($(this).data('href') !== void 0) {
      return window.top.location.href = $(this).data('href');
    }
  });

  document.addEventListener('turbo:load', function() {
    var $body;
    $body = $('body');
    if ($body.hasClass('opportunities')) {
      if ($body.hasClass('edit')) {
        // account select2
        $('#opportunity_account_id').select2({
          ajax: {
            url: '/accounts-search.json',
            dataType: 'json',
            delay: 250,
            data: function(params) {
              return {
                query: params.term
              };
            },
            processResults: function(data) {
              return {
                results: data
              };
            }
          },
          minimumInputLength: 2,
          escapeMarkup: function(markup) {
            return markup;
          },
          templateResult: function(account) {
            if (account.name) {
              return `<p><strong>${account.name}</strong><br/>${account.city_state}</p>`;
            }
          }
        });
        // contact select2
        $('#opportunity_account_id').on('select2:select', function(e) {
          var contact, contacts, i, len;
          contacts = e.params.data.contacts;
          if (contacts) {
            $('#opportunity_contact_id').empty();
            for (i = 0, len = contacts.length; i < len; i++) {
              contact = contacts[i];
              $('#opportunity_contact_id').append(`<option value='${contact.id}'>${contact.name}</option>`);
            }
            return $('#opportunity_contact_id').trigger('change');
          }
        });
      }
      if ($body.hasClass('new') || $body.hasClass('edit')) {
        return flatpickr('#opportunity_estimated_closed_at', {
          altInput: true,
          altFormat: 'l, F j, Y',
          dateFormat: 'Y-m-d'
        });
      }
    }
  });

}).call(this);
