var onAccountClearbitSuccess;

import moment from 'moment';

import flatpickr from 'flatpickr';

$(document).on('click', '.filtered-by-contact', function(event) {
  var contactId;
  event.preventDefault();
  contactId = $(this).data('contact-id');
  if (contactId === 'all') {
    $('.row.activity').show();
  } else {
    $('.row.activity').hide();
    $('.contact_' + contactId).show();
  }
  return $('.filtered-by').html($(this).html());
});

$(document).on('change', 'body.accounts.show #activity_contact_id', function(event) {
  if ($(this).val() === "0") {
    return Turbo.visit(`/accounts/${accountId}/contacts/new`);
  }
});

$(document).on('click', 'body.accounts .quick-action', function(event) {
  var $textarea, cursorPos, textAfter, textBefore, v;
  event.preventDefault();
  $textarea = $('#activity_notes');
  cursorPos = $textarea.prop('selectionStart');
  v = $textarea.val();
  textBefore = v.substring(0, cursorPos);
  textAfter = v.substring(cursorPos, v.length);
  return $textarea.val(textBefore + $(this).html() + textAfter);
});

$(document).on('click', 'body.accounts.show tr', function(event) {
  var href;
  href = $(this).data('href');
  if (href) {
    return Turbo.visit(href);
  }
});

$(document).on('click', 'body.accounts .combine-accounts-link', function(event) {
  event.preventDefault();
  $('div.combine-accounts-modal div.modal-body iframe').attr('src', this.href);
  return $('div.combine-accounts-modal').modal('show');
});

$(document).on('keyup keypress', 'body.accounts #q', function(event) {
  var code, valByEntry;
  code = event.keyCode || event.which;
  if (code === 13) {
    event.preventDefault();
    valByEntry = $(this).val();
    if (valByEntry.length > 0) {
      clearbitBlockUI();
      $.post('/clearbit_calls', {
        q: valByEntry,
        format: 'json'
      }, onAccountClearbitSuccess);
      $('.quick-lookup').hide();
      return $('#account_contact_email').val(valByEntry);
    }
  }
});

// clearbit search
onAccountClearbitSuccess = function(data) {
  var $list, account, addr_data, i, len, linkedin_address, ref;
  $.unblockUI();
  if (data) {
    if (data.company) {
      $('#account_name').val(data.company.name);
      $('#account_website').val(data.company.url);
      $('#account_notes').val(data.company.description);
      if (data.company.logo) {
        $('#account_logo_url').val(data.company.logo);
        $('span.logo-image').html(`<img src=\"${data.company.logo}\" style=\"height: 75px\">`);
      }
      if (data.company.location) {
        addr_data = data.company.location.split(', ');
        if (addr_data.length === 4) {
          $('#account_address1').val(addr_data[0]);
          $('#account_city').val(addr_data[1]);
          $('#account_state').val(addr_data[2].split(' ')[0]);
          $('#account_zip').val(addr_data[2].split(' ')[1]);
        } else if (addr_data.length === 5) {
          $('#account_address1').val(addr_data[0]);
          $('#account_address2').val(addr_data[1]);
          $('#account_city').val(addr_data[3]);
          $('#account_state').val(addr_data[4].split(' ')[0]);
          $('#account_zip').val(addr_data[4].split(' ')[1]);
        }
      }
    }
    if (data.person) {
      $('#account_contact_first_name').val(data.person.name.givenName);
      $('#account_contact_last_name').val(data.person.name.familyName);
      if (data.person.employment) {
        $('#account_contact_title').val(data.person.employment.title);
      }
      if (data.person.avatar) {
        $('#account_contact_avatar_url').val(data.person.avatar);
        $('span.avatar-image').html(`<img src=\"${data.person.avatar}\" style=\"height: 75px\">`);
      }
      $('#account_contact_twitter').val(data.person.twitter.handle);
      if (data.person.linkedin.handle) {
        linkedin_address = `http://linkedin.com/${data.person.linkedin.handle}`;
        $('#account_contact_linkedin_account').val(linkedin_address);
      }
    }
  }
  if (data.existing) {
    $list = $('.existing-account-found-modal .modal-body ul');
    ref = data.existing;
    for (i = 0, len = ref.length; i < len; i++) {
      account = ref[i];
      $list.append(`<li><a href=\"/accounts/${account.id}\" class=\"btn btn-info\"><i class=\"fa fa-folder\"></i> ${account.name}</a></li>`);
    }
    return $('.existing-account-found-modal').modal('show');
  }
};

$(document).on('blur paste', '#q', function(event) {
  var valByEntry, valByPaste;
  if (!$('.quick-lookup').is(':visible')) {
    return false;
  }
  if (event.type === 'focusout') {
    valByEntry = $(this).val();
    if (valByEntry.length > 0) {
      clearbitBlockUI();
      $.post('/clearbit_calls', {
        q: valByEntry,
        format: 'json'
      }, onAccountClearbitSuccess);
      $('.quick-lookup').hide();
      return $('#account_contact_email').val(valByEntry);
    }
  } else if (event.type === 'paste') {
    valByPaste = event.originalEvent.clipboardData.getData('text');
    if (valByPaste.length > 0) {
      clearbitBlockUI();
      $.post('/clearbit_calls', {
        q: valByPaste,
        format: 'json'
      }, onAccountClearbitSuccess);
      $('.quick-lookup').hide();
      return $('#account_contact_email').val(valByPaste);
    }
  }
});

// documents: send for e-signature
$(document).on('click', 'body.accounts .send-for-esignature', function(event) {
  var create_href, volumeContractId;
  event.preventDefault();
  clearbitBlockUI();
  volumeContractId = $(this).data('volume-contract-id');
  create_href = `/volume_contracts/${volumeContractId}/signatures`;
  return $.post(create_href, {
    format: 'json'
  }, function(data) {
    return Turbo.visit(`/signatures/${data.id}/edit`);
  });
});

$(document).on('click', 'body.accounts .activities_file', function(event) {
  return event.stopPropagation();
});

$(document).on('draw.dt', 'table.activities', function() {
  return $('div.dataTables_length select').addClass('nochosen');
});

document.addEventListener('turbo:load', function() {
  var $body, $clear_date_link, $follow_up_on_clear_date_link, activity_follow_up_on_datepicker, activity_when_datepicker, dateOptions, timepickerOptions;
  $body = $('body');
  dateOptions = {
    buttonText: '',
    buttonImageOnly: true,
    changeMonth: true,
    changeYear: true,
    dateFormat: 'yy-mm-dd',
    showOn: 'both'
  };
  if ($body.hasClass('accounts') && $body.hasClass('edit')) {
    $('#account_parent_account_id').select2({
      ajax: {
        url: '/accounts-search.json',
        dataType: 'json',
        delay: 250,
        data: function(params) {
          return {
            query: params.term
          };
        },
        processResults: function(data) {
          return {
            results: data
          };
        }
      },
      minimumInputLength: 2,
      allowClear: true,
      placeholder: "Please select",
      escapeMarkup: function(markup) {
        return markup;
      },
      templateResult: function(account) {
        if (account.name) {
          return `<p><strong>${account.name}</strong><br/>${account.city_state}</p>`;
        }
      }
    });
  }
  $('.account-only-search').select2({
    theme: "classic",
    ajax: {
      url: '/accounts-search.json',
      dataType: 'json',
      cache: true,
      data: function(params) {
        return {
          query: params.term,
          accounts_only: true
        };
      },
      processResults: function(data) {
        return {
          results: data
        };
      }
    },
    minimumInputLength: 3,
    allowClear: true,
    placeholder: "Please select here",
    escapeMarkup: function(markup) {
      return markup;
    },
    templateResult: function(account) {
      if (account.name) {
        return `<p><strong>${account.name}</strong><br/>${account.city_state}</p>`;
      }
    }
  });
  if (($body.hasClass('accounts') && $body.hasClass('show')) || ($body.hasClass('activities') && $body.hasClass('new')) || ($body.hasClass('activities') && $body.hasClass('show')) || ($body.hasClass('bookings') && $body.hasClass('edit'))) {
    $clear_date_link = $('a.clear-date');
    $follow_up_on_clear_date_link = $('a.follow-up-clear-date');
    activity_when_datepicker = flatpickr('#activity_when', {
      altInput: true,
      altFormat: 'l, F j, Y',
      dateFormat: 'Y-m-d',
      onChange: function(selectedDates, selectedDate) {
        var isToDo;
        $('.when-time').show();
        $clear_date_link.show();
        isToDo = moment(selectedDate).isSameOrAfter(moment(), 'day');
        if (isToDo) {
          $('.follow-up').hide();
          return $('#new_activity input[type="submit"]').val('Create To-Do');
        } else {
          $('.follow-up').show();
          return $('#new_activity input[type="submit"]').val('Create Activity');
        }
      }
    });
    $clear_date_link.on('click', function(event) {
      event.preventDefault();
      activity_when_datepicker.clear();
      $('.follow-up').show();
      $('.when-time').hide();
      $clear_date_link.hide();
      return $('#new_activity input[type="submit"]').val('Create Activity');
    });
    activity_follow_up_on_datepicker = flatpickr('#activity_follow_up_on', {
      altInput: true,
      altFormat: 'l, F j, Y',
      dateFormat: 'Y-m-d',
      minDate: moment().toDate(),
      onChange: function(selectedDates, selectedDate) {
        $('.follow-up-time').show();
        $('.follow-up-details').slideDown();
        return $follow_up_on_clear_date_link.show();
      }
    });
    $follow_up_on_clear_date_link.on('click', function(event) {
      event.preventDefault();
      activity_follow_up_on_datepicker.clear();
      $('.follow-up-time').hide();
      $('.follow-up-details').hide();
      return $follow_up_on_clear_date_link.hide();
    });
    $(document).on('click', 'body.activities.show .push-it-back', function(event) {
      var currentDate, days, newDate;
      event.preventDefault();
      currentDate = moment($('#activity_when').val());
      days = $(this).data('days');
      newDate = currentDate.add(days, 'days').toDate();
      return activity_when_datepicker.setDate(newDate);
    });
    timepickerOptions = {
      timeFormat: timepickerFormat
    };
    $('.when-time').timepicker(timepickerOptions);
    return $('.follow-up-time').timepicker(timepickerOptions);
  }
});
