import React from 'react'

const BookingActionButton = (props) => {
  let options
  if (props.ownership == 'drafts') {
    options = (
      <li>
        <a data-confirm="This draft will be permanently deleted; there is no undo. Are you sure?" 
           data-method="delete" 
           href={'/bookings/' + props.bookingId} rel="nofollow">
           <i className="fa fa-trash fa-fw"></i>&nbsp;&nbsp;Delete
        </a>
      </li>
    )
  }
  return (
    <div className="btn-group">
      <a className="btn btn-default dropdown-toggle" data-toggle='dropdown' href='#'>
        <i className="fa fa-cog fa-fw"></i> <b className="caret"></b>
      </a>
      <ul className="dropdown-menu">
        <li><a href={'/bookings/' + props.bookingId + '/edit/general'}><i className="fa fa-edit fa-fw"></i>&nbsp;&nbsp;Edit</a></li>
        <li><a href={'/functions/#' + props.startDate}><i className="fa fa-calendar fa-fw"></i>&nbsp;&nbsp;View in Function Diary</a></li>
        <li><a className="copy-booking" href={'/bookings/' + props.bookingId + '/booking_copy/new'}><i className="fa fa-copy fa-fw"></i>&nbsp;&nbsp;Make a Copy</a></li>
        {options}
      </ul>
    </div>
  )
}

export default BookingActionButton