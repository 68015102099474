import Chart from 'chart.js/auto';

document.addEventListener('turbo:load', function() {
  var clicksChart, clicksCtx, opensChart, opensCtx, timingsChart, timingsCtx;
  if ($('body').hasClass('trackings')) {
    opensCtx = $('#opens-chart');
    opensChart = new Chart(opensCtx, {
      type: 'bar',
      data: {
        labels: openDates,
        datasets: [
          {
            label: 'Opens',
            data: openQuantities,
            backgroundColor: '#C9F1F0',
            borderColor: '#A0C1C0',
            borderWidth: 1
          }
        ]
      }
    });
    clicksCtx = $('#clicks-chart');
    clicksChart = new Chart(clicksCtx, {
      type: 'bar',
      data: {
        labels: linkClickDates,
        datasets: [
          {
            label: 'Clicks',
            data: linkClickQuantities,
            backgroundColor: '#C9E9FC',
            borderColor: '#9FB8C9',
            borderWidth: 1
          }
        ]
      }
    });
    timingsCtx = $('#timings-chart');
    return timingsChart = new Chart(timingsCtx, {
      type: 'bar',
      data: {
        labels: timingDates,
        datasets: [
          {
            label: 'Time Spent Reading (Seconds)',
            data: timingQuantities,
            backgroundColor: '#E9D8FF',
            borderColor: '#A99CB9',
            borderWidth: 1
          }
        ]
      }
    });
  }
});
