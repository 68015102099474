import React from 'react'

class GrcGrossNetSwitch extends React.Component {
  constructor() {
    super()
    this.grossNetSwitched = this.grossNetSwitched.bind(this)
  }
  grossNetSwitched(event, view) {
    event.preventDefault()
    this.props.grossNetSwitched(view)
  }
  render() {
    return (
      <div className="view-switch btn-group btn-group-sm">
        <button onClick={(e) => this.grossNetSwitched(e, 'gross')} type="button" className={"btn " + (this.props.view.indexOf("gross") > -1 ? "btn-info" : "btn-default")}>Gross</button>
        <button onClick={(e) => this.grossNetSwitched(e, 'net')} type="button" className={"btn " + (this.props.view.indexOf("net") > -1 ? "btn-info" : "btn-default")}>Net</button>
        <button onClick={(e) => this.grossNetSwitched(e, 'actual')} type="button" className={"btn " + (this.props.view.indexOf("actual") > -1 ? "btn-info" : "btn-default")}>Actual</button>
      </div>
    )
  }
}

export default GrcGrossNetSwitch