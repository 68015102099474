(function() {
  document.addEventListener('turbo:load', function() {
    var $body, dateOptions;
    $body = $('body');
    if ($body.hasClass('leads')) {
      $('#leads-generate-proposal-button').tooltip();
      dateOptions = {
        changeMonth: true,
        changeYear: true
      };
      $('#lead_wedding_date').datepicker($.extend({}, dateOptions, {
        onSelect: function(selectedDate) {
          $('#lead_start_date').val(selectedDate);
          $('#lead_end_date').val(selectedDate);
          return $('#lead_end_date').datepicker('option', 'minDate', selectedDate);
        }
      }));
      $('#lead_start_date').datepicker($.extend({}, dateOptions, {
        onSelect: function(selectedDate) {
          $('#lead_end_date').val(selectedDate);
          return $('#lead_end_date').datepicker('option', 'minDate', selectedDate);
        }
      }));
      $('#lead_end_date').datepicker($.extend({}, dateOptions));
      $('.lead_start_date i.fa-calendar').on('click', function(event) {
        return $('#lead_start_date').datepicker('show');
      });
      $('.lead_end_date i.fa-calendar').on('click', function(event) {
        return $('#lead_end_date').datepicker('show');
      });
      $('.lead_wedding_date i.fa-calendar').on('click', function(event) {
        return $('#lead_wedding_date').datepicker('show');
      });
      $('.select-all').on('click', function(event) {
        event.preventDefault();
        return $('.select-property').prop('checked', true);
      });
      return $('#lead_lead_source_id').on('change', function(event) {
        var selectedOption;
        selectedOption = $(this).find("option:selected").text();
        if (selectedOption.toLowerCase() === 'other') {
          return $('.please-specify').show();
        } else {
          $('#lead_lead_source_notes').val('');
          return $('.please-specify').hide();
        }
      });
    }
  });

  $(document).on('change', 'body.leads #rfp_form', function(event) {
    return window.top.location.href = `/leads/new?rfp_form=${$(this).val()}&original_lead=${$('#lead_original_lead_id').val()}`;
  });

  $(document).on('click', '.open-turn-down-dialog', function(event) {
    var target;
    event.preventDefault();
    target = $(this).attr('href');
    $('.turn-down-lead-modal .modal-body').load(target);
    return $('.turn-down-lead-modal').modal('show');
  });

  $(document).on('click', 'body.leads a.turn-down-button', function(event) {
    event.preventDefault();
    return $('div.turn-down').slideDown('fast');
  });

  $(document).on('click', 'body.leads #leads-generate-proposal-button', function(event) {
    return event.preventDefault();
  });

  $(document).on('change', 'body.leads #lead_rooms, body.leads #lead_adr, body.leads #lead_rooms_revenue, body.leads #lead_fb_revenue, body.leads #lead_rental_revenue', function(event) {
    var adr, estimated_value, fb_revenue, rental_revenue, revenue, rooms, rooms_revenue;
    rooms = $('#lead_rooms').val();
    adr = $('#lead_adr').val();
    if ($.isNumeric(rooms) && $.isNumeric(adr)) {
      revenue = (rooms * adr).toFixed(2);
      $('#lead_rooms_revenue').val(revenue);
    }
    rooms_revenue = parseFloat($('#lead_rooms_revenue').val()) || 0.0;
    fb_revenue = parseFloat($('#lead_fb_revenue').val()) || 0.0;
    rental_revenue = parseFloat($('#lead_rental_revenue').val()) || 0.0;
    estimated_value = (rooms_revenue + fb_revenue + rental_revenue).toFixed(2);
    return $('#lead_estimated_value').val(estimated_value);
  });

}).call(this);
