import ClipboardJS from 'clipboard';

$(document).on('change', 'body.menus select#menu_menu_template_id', function(event) {
  var $this;
  $this = $(this);
  return window.top.location.href = `/menus/new?menu_template_id=${$this.val()}`;
});

// $.getJSON "/menu_templates/#{$this.val()}.json", (data) ->
//   $('#menu_title').val(data.name)
//   tinymce.get('menu_intro').setContent(data.intro)
//   tinymce.get('menu_closing').setContent(data.closing)
//   tinymce.get('menu_fine_print').setContent(data.fine_print)
$(document).on('change', 'body.menus select#menu_menu_section_ids, body.documents select#document_menu_section_ids', function(event) {
  var $this;
  $this = $(this);
  return $.getJSON(`/menu_categories.json?menu_section=${$this.val()}`, function(data) {
    $('select#menu_menu_category_ids').val(data);
    return $('select#document_menu_category_ids').val(data);
  });
});

$(document).on('click', 'a.select-all-menu-categories', function(event) {
  event.preventDefault();
  $('#menu_menu_section_ids > option').prop('selected', true);
  $('#menu_menu_section_ids').trigger('change');
  $('#menu_menu_category_ids > option').prop('selected', true);
  $('#menu_menu_category_ids').trigger('change');
  $('#document_menu_section_ids > option').prop('selected', true);
  $('#document_menu_section_ids').trigger('change');
  $('#document_menu_category_ids > option').prop('selected', true);
  return $('#document_menu_category_ids').trigger('change');
});

$(document).on('click', 'a.unselect-all-menu-categories', function(event) {
  event.preventDefault();
  $('#menu_menu_section_ids > option').prop('selected', false);
  $('#menu_menu_section_ids').trigger('change');
  $('#menu_menu_category_ids > option').prop('selected', false);
  $('#menu_menu_category_ids').trigger('change');
  $('#document_menu_section_ids > option').prop('selected', false);
  $('#document_menu_section_ids').trigger('change');
  $('#document_menu_category_ids > option').prop('selected', false);
  return $('#document_menu_category_ids').trigger('change');
});

$(document).on('change', 'select.menu-template-id', function(event) {
  return window.top.location.href = `/menu_items?menu_template_id=${$(this).val()}`;
});

$(document).on('change', 'select.nest-menu-template-id', function(event) {
  return window.top.location.href = `/nest/clients/${clientSlug}/menu_items?menu_template_id=${$(this).val()}`;
});

$(document).on('change', 'select.category-menu-template-id', function(event) {
  return window.top.location.href = `/menu_categories?menu_template_id=${$(this).val()}`;
});

$(document).on('change', 'select.nest-category-menu-template-id', function(event) {
  return window.top.location.href = `/nest/clients/${clientSlug}/menu_categories?menu_template_id=${$(this).val()}`;
});

$(document).on('click', '.copy-menu-to-clipboard', function(event) {
  return event.preventDefault();
});

document.addEventListener('turbo:load', function() {
  var $body, clipboard;
  $body = $('body');
  if ($body.hasClass('menus') && ($body.hasClass('index') || $body.hasClass('complete'))) {
    clipboard = new ClipboardJS('.copy-menu-to-clipboard');
    return clipboard.on('success', function(event) {
      var $clipButton, cb;
      $clipButton = $(event.trigger);
      $clipButton.tooltip({
        title: 'Copied!'
      });
      $clipButton.tooltip('show');
      cb = function(elem) {
        return elem.tooltip('destroy');
      };
      return setTimeout((function() {
        return cb($clipButton);
      }), 1500);
    });
  }
});
