import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkboxInput']

  toggleDisabled(e) {
    console.log(e.currentTarget.dataset, e.currentTarget.checked)
    if (e.currentTarget.dataset.dependent) {
      if (e.currentTarget.checked) {
        this.enableDependents(e.currentTarget)
      } else {
        this.disableDependents(e.currentTarget)
      }
    }
  }

  disableDependents(clickedCheckbox) {
    this.checkboxInputTargets.forEach(function(el) {
      console.log(clickedCheckbox.dataset.dependent, ' === ', el.dataset.name, clickedCheckbox.dataset.dependent === el.dataset.name)
      if (clickedCheckbox.dataset.dependent === el.dataset.name) {
        el.checked = false
        el.disabled = true
      }
    })
  }

  enableDependents(clickedCheckbox) {
    this.checkboxInputTargets.forEach(function(el) {
      if (clickedCheckbox.dataset.dependent === el.dataset.name) {
        el.disabled = false
      }
    })
  }

}
