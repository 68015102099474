import React from 'react'
import accounting from '../../../src/accounting.min'
import moment from 'moment'
import { Cell } from 'fixed-data-table-2'

export const GrcBookingNameCell = (props) => {
  const {rowIndex, bookings} = props
  const booking = bookings[rowIndex]
  if (booking.id === undefined) { // summary row
    return <Cell><strong>{booking.name}</strong></Cell>
  } else {
    return (
      <Cell>
        <span className={booking.id === undefined ? `${booking.status} strong` : `${booking.status}`}><a href={`/bookings/${booking.id}/edit/general`}>{booking.name.truncate(42).toString()}</a></span>
      </Cell>
    )
  }
}

export const GrcBookingPmsCell = (props) => {
  const { rowIndex, bookings } = props
  const booking = bookings[rowIndex]
  var sentToPmsCheck = ''
  if (booking.sent_to_pms) {
    sentToPmsCheck = <i className="fa fa-check-circle-fa fa-fixed text-success"></i>
  }
  else if (booking.status == 'Tentative' && booking.position < 2) {
    sentToPmsCheck = <i className="fa fa-minus-circle fa-fixed text-warning"></i>
  }
  else if (booking.status == 'Definite' && booking.position < 2) {
    sentToPmsCheck = <i className="fa fa-times-circle fa-fixed text-danger"></i>
  }
  return (
    <Cell>
      {sentToPmsCheck}
    </Cell>
  )
}

export const GrcBookingTextCell = (props) => {
  const {rowIndex, field, bookings} = props
  const booking = bookings[rowIndex]
  return (
    <Cell>
      <span className={booking.id === undefined ? `${booking.status} strong` : `${booking.status}`}>{booking[field]}</span>
    </Cell>
  )
}

export const GrcBookingQuantityCell = (props) => {
  const {rowIndex, field, bookings} = props
  const booking = bookings[rowIndex]
  const quantity = booking[field]
  let cName = booking.id === undefined ? `${booking.status} strong` : `${booking.status}`
  if (quantity < 0) {
    cName += " negative"
  }
  return (
    <Cell>
      <span className={cName}>{quantity}</span>
    </Cell>
  )
}


export const GrcBookingDateCell = (props) => {
  const {rowIndex, field, bookings} = props
  const booking = bookings[rowIndex]
  const date = (booking[field] === undefined || booking[field] === "") ? "" : moment(booking[field]).format('MM/DD/YYYY')
  return (
    <Cell>
      <span className={booking.id === undefined ? `${booking.status} strong` : `${booking.status}`}>{date}</span>
    </Cell>
  )
}

export const GrcBookingCurrencyCell = (props) => {
  const {rowIndex, field, bookings} = props
  const booking = bookings[rowIndex]
  if (booking[field] === undefined) {
    return <Cell></Cell>
  } else {
    return (
      <Cell>
        <span className={booking.id === undefined ? `${booking.status} strong` : `${booking.status}`}>{accounting.formatMoney(booking[field], currencySymbol)}</span>
      </Cell>
    )
  }
}
