import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ['quickPick', 'hiddenQuickPick', 'startDate', 'endDate']

  connect() {
    const options = {
      altInput: true,
      altFormat: 'F j, Y',
      dateFormat: 'Y-m-d',
    }
    const endDateOptions = Object.assign({}, options, {
      onChange: () => {
        this.quickPickTarget.innerHTML = "Custom"
        this.hiddenQuickPickTarget.value = "Custom"
      },
    })
    this.endDatePicker = flatpickr(this.endDateTarget, endDateOptions)
    const startDateOptions = Object.assign({}, options, {
      onChange: (selectedDates, selectedDate) => {
        this.quickPickTarget.innerHTML = 'Custom'
        this.endDatePicker.set('minDate', null)
        this.endDatePicker.setDate(selectedDate)
        this.endDatePicker.set('minDate', selectedDate)
      }
    })
    this.startDatePicker = flatpickr(this.startDateTarget, startDateOptions)
  }

  rangeSelected(event) {
    this.endDatePicker.set('minDate', null)
    this.startDatePicker.setDate(event.currentTarget.dataset.rangeStart)
    this.endDatePicker.setDate(event.currentTarget.dataset.rangeEnd)
    this.endDatePicker.set('minDate', event.currentTarget.dataset.rangeStart)
    this.quickPickTarget.innerHTML = event.currentTarget.innerHTML
    this.hiddenQuickPickTarget.value = event.currentTarget.innerHTML
  }

}